import { assign, forEach, forOwn, get, omit, pick, pickBy } from 'lodash';

import Core from '../Core.js';
import Address from './Address.js';
import Filter, { FILTER_USER_ALL } from './Filter';
import FilterStore from './FilterStore';
import NotificationStore from './NotificationStore';
import ReportStore from './ReportStore';
import TagStore from './TagStore';
import UserStateStore from './UserStateStore';

export const API_USER = {
  id: 'api',
  email: 'api@getoutlaw.com',
  info: { fullName: 'API' },
};

export const isAdmin = (user) => {
  return user && user.isAdmin === true;
};

export const isOutlaw = (user) => {
  if (!user) return false;
  return user.email != null && /@getoutlaw\.com$/.test(user.email);
};

/*
  Users can have a team only if they are not on a customer instance or if they have teams already
  The later allows customer instance people to be invited to a team, which then enables everything.
*/
export const canHaveTeam = (user) => {
  if (user.isAdmin) return true;
  return !Core.Config().INSTANCE || !!user.hasTeams;
};

// Use same pattern as Deal.BEHAVIORS to define features that are enabled/disabled by default,
// but can be overridden in user data (currently managed manually directly in Firebase db)
export const FEATURES = {
  BATCH: {
    key: 'batch',
    defaultValue: false,
    header: 'Batch',
    isAdminEditable: true,
  },
  BULK_SIGN: {
    key: 'bulkSign',
    defaultValue: false,
    header: 'Bulk Sign',
    isAdminEditable: false,
  },
  CONNECT: {
    key: 'connect',
    defaultValue: false,
    header: 'Connect',
    isAdminEditable: true,
  },
  VINE_IO: {
    key: 'vineIO',
    defaultValue: false,
    header: 'Export .vine',
    isAdminEditable: true,
  },
  WORD: {
    key: 'word',
    defaultValue: false,
    header: 'Word',
    isAdminEditable: true,
  },
};

export default class User {
  isAdmin = false;
  isPartner = false;
  email = '';
  id = ''; // uid
  info = null;
  photoURL = null;
  teams = [];

  tags = null;
  states = null;
  reports = null;
  filters = null;

  // These get populated in each Session; should eventually be moved to a Session model class or something
  ip = null;
  userAgent = null;
  location = null;

  // This gets populated by merging any custom values (from db) into the defaults defined above
  features = {};

  notificationStore = null;

  constructor(json) {
    assign(this, omit(json, ['team']));
    if (!json.info) this.info = {};

    if (
      this.info.addressProperties &&
      typeof this.info.addressProperties === 'object' &&
      !(this.info.addressProperties instanceof Address)
    ) {
      this.info.addressProperties = new Address(this.info.addressProperties);
      if (!this.info.address || !this.info.address.length) {
        this.info.address = this.info.addressProperties.fullAddress;
      }
    }

    this.isAdmin = json.isAdmin === true;
    this.isPartner = json.isPartner === true;

    this.tags = new TagStore(json.tags);
    this.states = new UserStateStore({ ...json.states, userID: json.id });
    this.reports = new ReportStore(json.reports);
    this.notificationStore = new NotificationStore(json.id, json.notifications);
    this.filters = new FilterStore({
      [FILTER_USER_ALL.filterID]: new Filter({
        ...FILTER_USER_ALL,
        userID: json.id,
      }),
      ...get(json, 'filters', {}),
    });

    // Override default feature-set with any provided features we find in db
    const features = {};
    forEach(FEATURES, (feature) => {
      features[feature.key] = get(json, `features[${feature.key}]`, feature.defaultValue);
    });
    this.features = features;
  }

  get anonymous() {
    return this.email == null || this.email === '';
  }

  get team() {
    if (this.teams) {
      return Object.keys(this.teams)[0];
    }

    return null;
  }

  get ipv6() {
    return this.ip;
  }

  get ipv4() {
    return this.ip.replace('::ffff:', '');
  }

  get hasTeams() {
    return !!Object.keys(this.teams).length;
  }

  get ownedTeams() {
    if (typeof this.teams !== 'object') return 0;
    let owned = 0;
    forOwn(this.teams, (role) => {
      if (role === 'owner') owned += 1;
    });
    return owned;
  }

  // whether all of user's info is filled out
  get complete() {
    const { org, title, fullName, email } = this.info;
    return org != null && title != null && fullName != null && email != null;
  }

  // Simple json object to pass to server for API to log activity (e.g. in API.signDeal)
  get userOrigin() {
    return pick(this, ['id', 'ip', 'location', 'userAgent']);
  }

  get observerTeamIDs() {
    let teamIDs = [];
    if (this.teamMemberships) {
      const observableTeams = pickBy(this.teamMemberships, (membership) => membership.observer === true);
      if (observableTeams && Object.keys(observableTeams).length) teamIDs = Object.keys(observableTeams);
    }

    return teamIDs;
  }

  canEditTeam(teamID) {
    if (typeof this.teams === 'object') {
      const role = this.teams[teamID];
      if (role != null && ['owner', 'editor'].indexOf(role) > -1) return true;
    }
    return false;
  }

  can(feature) {
    if (this.isAdmin) return true;
    return this.features[feature.key] === true;
  }

  //TODO: user.canCreateNewDoc(team) is wrong. We need to fix this at some point. This might allows legacy users to create new docs even if they don't have permission.
  //Also this enables our test suite to pass cases that should fail. search_global.cy.js has a few examples of a temp user who is on a team with no permissions creating a new doc.
  canCreateNewDoc(team) {
    if (team) {
      if (!team?.userMemberships) {
        return true;
      }
      return team?.userMemberships[this.id]?.canCreateNewDoc !== false;
    }
    return false;
  }

  getTeamsAllowingNewDocCreation(teams) {
    return teams?.filter((team) => {
      if (!team?.userMemberships) {
        return true;
      }
      return team?.userMemberships[this.id]?.canCreateNewDoc !== false;
    });
  }

  withoutRedline(teamID) {
    if (!teamID) return false;
    if (this.teamMemberships && this.teamMemberships[teamID]) {
      return this.teamMemberships[teamID].withoutRedline;
    }
    return false;
  }
}
