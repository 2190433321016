import { assign, filter, find, findIndex, forEach, get, keys, map, pick, size, sortBy, uniqBy } from 'lodash';

import SectionType from '@core/enums/SectionType';

import DealRole from '../enums/DealRole';
import DealStatus from '../enums/DealStatus';
import SERVICES from '../enums/IntegrationServices';
import DealConnection, { buildHydratedFields } from '../models/DealConnection';
import { META_FIELD_TYPES, convertMetaToES } from '../server/search/Meta';
import { DateFormatter } from '../utils/DateTime';
import { ADDRESS_META_FIELDS } from './Address';
import Deal, { DEAL_TYPE } from './Deal';
import { TEMPLATE_STEPS } from './Template';
import { EMPTY_SEARCH_VALUE, LEGACY_EMPTY_SEARCH_VALUE, ValueType, VariableType } from './Variable';

export const FACETS = [
  { key: 'parties', name: 'Party', type: 'facet', multi: true },
  { key: 'users', name: 'User', type: 'facet', multi: true },
  { key: 'status', name: 'Status', type: 'facet', multi: true },
  { key: 'tags', name: 'Tag', type: 'tags', multi: true },
  { key: 'sourceTemplateKey', name: 'Template', type: 'facet' },
  { key: 'teamID', name: 'teamID', type: 'facet' },
];

//enumeration of events that should be indexed synchronously for a smooth UX
export const REALTIME_EVENTS = {
  TAG: 'tag',
  UNTAG: 'untag',
  LEAVE: 'leave',
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE_STATUS: 'updateStatus',
  FORCE_UPDATE: 'forceUpdate',
  RESTORE: 'restore',
};

export const META_FIELDS = {
  attachments: {
    type: META_FIELD_TYPES.OBJECT,
    children: {
      attachmentType: META_FIELD_TYPES.TEXT,
      bucketPath: META_FIELD_TYPES.TEXT,
      extension: META_FIELD_TYPES.TEXT,
      filename: META_FIELD_TYPES.TEXT,
      key: META_FIELD_TYPES.TEXT,
    },
  },
  workflow: {
    type: META_FIELD_TYPES.OBJECT,
    children: {
      name: META_FIELD_TYPES.TEXT,
      collectionStepName: META_FIELD_TYPES.TEXT,
      serviceProviderName: META_FIELD_TYPES.TEXT,
      serviceProviders: META_FIELD_TYPES.BOOLEAN,
      steps: META_FIELD_TYPES.OBJECT,
      workflowKey: META_FIELD_TYPES.TEXT,
    },
  },
  connections: {
    type: META_FIELD_TYPES.OBJECT,
    children: {
      key: META_FIELD_TYPES.STRING,
      name: META_FIELD_TYPES.TEXT,
      type: META_FIELD_TYPES.STRING,
      id: META_FIELD_TYPES.STRING,
      objectType: META_FIELD_TYPES.STRING,
      source: META_FIELD_TYPES.TEXT,
      sourceType: META_FIELD_TYPES.STRING,
    },
  },
  created: { type: META_FIELD_TYPES.DATE },
  currentVersion: { type: META_FIELD_TYPES.STRING },
  dealID: { type: META_FIELD_TYPES.STRING },
  deleted: {
    type: META_FIELD_TYPES.OBJECT,
    children: {
      time: META_FIELD_TYPES.DATE,
      userID: META_FIELD_TYPES.STRING,
    },
  },
  lensChecks: {
    type: META_FIELD_TYPES.NESTED,
    children: {
      id: META_FIELD_TYPES.STRING,
      failedCheck: META_FIELD_TYPES.BOOLEAN,
      filters: {
        type: META_FIELD_TYPES.NESTED,
        children: {
          id: META_FIELD_TYPES.STRING,
          failedCheck: META_FIELD_TYPES.BOOLEAN,
        },
      },
    },
  },
  documentAI: { type: META_FIELD_TYPES.BOOLEAN },
  dealType: { type: META_FIELD_TYPES.STRING },
  type: { type: META_FIELD_TYPES.STRING },
  grade: { type: META_FIELD_TYPES.TEXT },
  needsReview: { type: META_FIELD_TYPES.BOOLEAN },
  name: { type: META_FIELD_TYPES.TEXT },
  parentDealID: { type: META_FIELD_TYPES.STRING },
  parties: {
    type: META_FIELD_TYPES.NESTED,
    children: {
      userNames: META_FIELD_TYPES.STRING,
      legalName: META_FIELD_TYPES.TEXT,
      partyID: META_FIELD_TYPES.STRING,
      partyName: META_FIELD_TYPES.TEXT,
      inviteStatus: META_FIELD_TYPES.TEXT,
      inviteStatusMessage: META_FIELD_TYPES.TEXT,
      isSigning: META_FIELD_TYPES.BOOLEAN,
    },
  },
  signedDate: { type: META_FIELD_TYPES.DATE },
  sourceTeam: { type: META_FIELD_TYPES.TEXT },
  sourceTemplate: { type: META_FIELD_TYPES.TEXT },
  sourceTemplateKey: { type: META_FIELD_TYPES.TEXT },
  status: { type: META_FIELD_TYPES.TEXT },
  statusColor: { type: META_FIELD_TYPES.TEXT },
  tags: { type: META_FIELD_TYPES.TEXT },
  template: {
    type: META_FIELD_TYPES.OBJECT,
    children: {
      autoGuest: META_FIELD_TYPES.BOOLEAN,
      batch: META_FIELD_TYPES.BOOLEAN,
      commenting: META_FIELD_TYPES.BOOLEAN,
      dealID: META_FIELD_TYPES.STRING,
      description: META_FIELD_TYPES.TEXT,
      documentAI: META_FIELD_TYPES.BOOLEAN,
      hasLenses: META_FIELD_TYPES.BOOLEAN,
      guestSigning: META_FIELD_TYPES.BOOLEAN,
      inbound: META_FIELD_TYPES.BOOLEAN,
      inboundMulti: META_FIELD_TYPES.BOOLEAN,
      inboundParty: META_FIELD_TYPES.STRING,
      integrations: META_FIELD_TYPES.BOOLEAN,
      key: META_FIELD_TYPES.STRING,
      public: META_FIELD_TYPES.BOOLEAN,
      readonly: META_FIELD_TYPES.BOOLEAN,
      showLetterhead: META_FIELD_TYPES.BOOLEAN,
      showTitle: META_FIELD_TYPES.BOOLEAN,
      team: META_FIELD_TYPES.STRING,
      title: META_FIELD_TYPES.TEXT,
      type: META_FIELD_TYPES.STRING,
      scoring: META_FIELD_TYPES.STRING,
      workflow: META_FIELD_TYPES.STRING,
    },
  },
  updated: { type: META_FIELD_TYPES.DATE },
  users: {
    type: META_FIELD_TYPES.NESTED,
    children: {
      address: META_FIELD_TYPES.TEXT,
      addressProperties: ADDRESS_META_FIELDS,
      email: META_FIELD_TYPES.TEXT,
      fullName: META_FIELD_TYPES.TEXT,
      org: META_FIELD_TYPES.TEXT,
      party: META_FIELD_TYPES.TEXT,
      phone: META_FIELD_TYPES.TEXT,
      role: META_FIELD_TYPES.STRING,
      tags: META_FIELD_TYPES.TEXT,
      title: META_FIELD_TYPES.TEXT,
      uid: META_FIELD_TYPES.STRING,
      dealUserID: META_FIELD_TYPES.STRING,
      inviteID: META_FIELD_TYPES.STRING,
      inviteStatus: META_FIELD_TYPES.TEXT,
      inviteStatusMessage: META_FIELD_TYPES.TEXT,
    },
  },
  variables: {
    type: META_FIELD_TYPES.NESTED,
    children: {
      name: META_FIELD_TYPES.STRING,
      value: META_FIELD_TYPES.STRING,
      dateValue: META_FIELD_TYPES.DATE,
      floatValue: META_FIELD_TYPES.FLOAT,
      arrayValue: META_FIELD_TYPES.STRING,
      valueType: META_FIELD_TYPES.STRING,
      displayValue: META_FIELD_TYPES.STRING,
      displayName: META_FIELD_TYPES.STRING,
      options: META_FIELD_TYPES.STRING,
      attachmentID: META_FIELD_TYPES.STRING,
    },
  },
  versions: {
    type: META_FIELD_TYPES.OBJECT,
    children: {
      dateCreated: META_FIELD_TYPES.DATE,
      description: META_FIELD_TYPES.TEXT,
      docxKey: META_FIELD_TYPES.STRING,
      id: META_FIELD_TYPES.STRING,
      origin: META_FIELD_TYPES.STRING,
      owner: META_FIELD_TYPES.STRING,
      pdfKey: META_FIELD_TYPES.STRING,
    },
  },
};

// Return the value to be returned by the CSV
// And format it if needed.
const getCSVValue = (variable) => {
  if (variable.valueType == ValueType.DATE) {
    const date = new Date(variable.value * 1000 + DateFormatter.getOffset(new Date(variable.value * 1000))); // Convert to millisecond timestamp
    return DateFormatter.ymd(date, '-');
  }

  return variable.value;
};

const shouldAddVaultColumn = (isOmit, value) => {
  if (!isOmit) {
    return true;
  } else if (value !== null && value !== '') {
    return true;
  }
  return false;
};

const shouldIncludeColumn = (colName, exportOption, vaultCols) => {
  if (exportOption === 'all-columns') {
    return true;
  }

  if (exportOption === 'vault-view') {
    return vaultCols.includes(`v.${colName}`);
  }

  return false;
};

const shouldIncludePartyColumn = (option, isParty) => {
  if (option === 'vault-view' && !isParty) return false;
  return true;
};

const getFormattedValue = (variable) => {
  if (variable.value === EMPTY_SEARCH_VALUE || variable.value === LEGACY_EMPTY_SEARCH_VALUE) {
    return '';
  } else {
    return getCSVValue(variable);
  }
};

// This gets a set of DealRecords ready for csv export (see CSV.downloadLink in Utils)
// Part 1 is "flattening" the data into individual properties (which would be natural as a json() getter on the class),
// Part 2 is collecting and sorting a list of field (column) names for CSV output
// But since we may have a diverse set of DealRecords (e.g., not all same template, so different parties and variables)
// It makes sense to keep them together because we're traversing all of these properties to get a unique field list
export const prepCSV = (records, exportOption, partiesColumns, vaultColumns) => {
  const defaultVaulCols = ['status', 'name', 'origin', 'updated', 'owners'];
  const getDefaultVaultCols = vaultColumns.filter((col) => defaultVaulCols.includes(col));
  const otherVaultCols = vaultColumns.filter((col) => !defaultVaulCols.includes(col));

  let baseFields;
  if (exportOption.option === 'vault-view') {
    baseFields = getDefaultVaultCols;
  } else {
    baseFields = ['dealID', 'status', 'name', 'signedDate'];
  }

  let varFields = [],
    connectionFields = [],
    partyFields = [],
    attachmentsFields = [],
    gradeFields = [];
  const jsonResults = [];

  // Loop through all records and flatten users and variables
  forEach(records, (dealRecord) => {
    const json = pick(dealRecord, baseFields);
    const vars = sortBy(dealRecord.variables, 'name');

    if (exportOption.option === 'vault-view') {
      if (getDefaultVaultCols.includes('origin')) {
        json['origin'] = dealRecord.dealType;
      }

      if (getDefaultVaultCols.includes('updated')) {
        json['updated'] = DateFormatter.fromMilliseconds(dealRecord.updated);
      }

      if (getDefaultVaultCols.includes('owners')) {
        const getOwners = dealRecord.users.filter((user) => {
          return user.role === 'owner';
        });
        json['owners'] = getOwners.map((owner) => (owner.fullName ? owner.fullName : owner.email)).join(', ');
      }

      if (otherVaultCols.includes('attachments')) {
        const colName = 'attachments';
        const attachments = dealRecord.attachments.map((attachment) => attachment.filename).join(', ');
        if (shouldAddVaultColumn(exportOption.omitColumns, attachments)) {
          json[colName] = attachments;
          if (attachmentsFields.indexOf(colName) < 0) attachmentsFields.push(colName);
        }
      }

      if (otherVaultCols.includes('grade')) {
        const colName = 'grade';
        const grade = dealRecord.grade;
        if (shouldAddVaultColumn(exportOption.omitColumns, grade)) {
          json[colName] = grade;
          if (gradeFields.indexOf(colName) < 0) gradeFields.push(colName);
        }
      }
    }

    forEach(vars, (variable) => {
      if (variable.name.indexOf('.') > -1) return;
      const colName = `#${variable.name}`;
      if (shouldIncludeColumn(variable.name, exportOption.option, otherVaultCols)) {
        const getVariableValue = getFormattedValue(variable);
        if (exportOption.omitColumns && getVariableValue === '') return;
        if (exportOption.omitColumns && getVariableValue !== '') {
          json[colName] = getVariableValue;
          if (varFields.indexOf(colName) < 0) varFields.push(colName);
        } else {
          json[colName] = getVariableValue;
          if (varFields.indexOf(colName) < 0) varFields.push(colName);
        }
      }
    });

    forEach(dealRecord.users, (du) => {
      if (!du.party) return;

      // We don't have true party IDs in the DealRecords because they're meant for search/display
      // TBD if this is a problem, as it could mean that exported data will not import properly
      const partyID = du.party.replace(/[^-\w\d]/g, '-');

      const partyProps = ['address', 'addressProperties', 'email', 'fullName', 'org', 'phone', 'title'];
      const partyColumn = otherVaultCols.includes('parties');

      forEach(partyProps, (prop) => {
        if (prop === 'addressProperties') {
          const addressProps = ['line1', 'line2', 'city', 'state', 'postalCode', 'country'];
          forEach(addressProps, (addressProp) => {
            const colName = `@${partyID}.${prop}.${addressProp}`;
            const duAddressProperties = du.addressProperties;
            const addressPropValue =
              duAddressProperties && typeof duAddressProperties === 'object' ? duAddressProperties[addressProp] : null;
            const getPropertyIdx = findIndex(partiesColumns, (column) => column.data === addressProp);
            if (getPropertyIdx == -1) return;
            if (shouldIncludePartyColumn(exportOption.option, partyColumn)) {
              if (exportOption.omitColumns && !addressPropValue) return;
              if (exportOption.omitColumns && addressPropValue) {
                json[colName] = addressPropValue;
                if (partyFields.indexOf(colName) < 0) partyFields.push(colName);
              } else {
                json[colName] = addressPropValue;
                if (partyFields.indexOf(colName) < 0) partyFields.push(colName);
              }
            }
          });
        } else {
          const colName = `@${partyID}.${prop}`;
          const partyPropValue = du[prop];
          const getPropertyIdx = findIndex(partiesColumns, (column) => column.data === prop);
          if (getPropertyIdx == -1) return;
          if (shouldIncludePartyColumn(exportOption.option, partyColumn)) {
            if (exportOption.omitColumns && !partyPropValue) return;
            if (exportOption.omitColumns && partyPropValue) {
              json[colName] = partyPropValue;
              if (partyFields.indexOf(colName) < 0) partyFields.push(colName);
            } else {
              json[colName] = partyPropValue;
              if (partyFields.indexOf(colName) < 0) partyFields.push(colName);
            }
          }
        }
      });
    });

    if (otherVaultCols.includes('connections') && dealRecord.connections) {
      forEach(dealRecord.connections, (connection) => {
        const colNameBase = `+${connection.type}`;

        //Find a matching service
        const service = SERVICES.find(({ key }) => key === connection.type);
        // In order to retrieve the properly mapped fields, we must pass it a DealConnection
        // but in the current context, we have a DealRecord vs a real Deal.
        // pass in a fake Deal (bad, I know) for now.
        const dealConnection = new DealConnection(connection, {});
        const idFields = buildHydratedFields(service, dealConnection.idFields);

        // Now loop all the idFields to generate colNameBase+idFieldLabel
        // then throw in value in it
        Object.entries(idFields).forEach(([idx, { key, value }]) => {
          const colName = `${colNameBase}.${key}`;
          // We must handle it this way becasue some DealConnection from specific services can
          // have duplicate DealConnections (e.g Vertafore)
          if (!json[colName]) {
            json[colName] = value;
          } else {
            json[colName] += `,${value}`;
          }

          if (!connectionFields.includes(colName)) connectionFields.push(colName);
        });
      });
    }

    const signedDateInt = parseInt(dealRecord.signedDate);
    if (json.signedDate && signedDateInt) {
      const signedDate = new Date(signedDateInt);
      let intl = Intl.DateTimeFormat().resolvedOptions();

      if (exportOption?.dateTimeFormat) {
        // We need to pass in the dateTimeFormat when this function is called in a backend context
        intl = exportOption.dateTimeFormat;
      }

      json.signedDate = signedDate.toLocaleString(intl.locale, {
        timeZoneName: 'short',
        timeZone: intl.timeZone,
      });
    }

    jsonResults.push(json);
  });

  return {
    json: jsonResults,
    fields: [
      ...baseFields,
      ...partyFields.sort(),
      ...varFields.sort(),
      ...attachmentsFields,
      ...connectionFields,
      ...gradeFields,
    ],
  };
};

const LABEL_PUBLIC = find(TEMPLATE_STEPS, { public: true }).name;
const LABEL_PRIVATE = find(TEMPLATE_STEPS, { public: false }).name;

export default class DealRecord {
  dealID;
  name;
  status;
  statusColor;
  updated;
  currentVersion;
  sourceTemplateKey;
  attachments = [];
  workflow;
  tags = [];
  sections = [];
  activities = [];
  parties = [];
  users = [];
  variables = [];
  versions = [];
  sourceTeam;
  sourceTemplate;
  template = null;
  dealType = DEAL_TYPE.NATIVE;
  signedDate = null;
  created = null;
  connections = [];
  parentDealID = null;
  grade = null;
  needsReview = false;
  deleted = null;
  documentAI = false;
  lensChecks = null;

  constructor(deal, me) {
    //we can reuse DealRecords from existing (untyped) search results
    if (!(deal instanceof Deal)) {
      assign(this, deal);

      //IMPORTANT: strip tag data of users other than current one
      //this way the current user never has an opportunity to see how others have classified the deal (e.g., a "Low Importance" tag)
      if (me) {
        let tags = [];
        map(this.tags, (tag) => {
          const pieces = tag.split('|'); //tags are indexed in the format {uid}|{tag}
          if (pieces.length == 2 && pieces[0] == me) tags.push(pieces[1]);
        });
        this.tags = tags;
      }

      return;
    }

    const { info } = deal;
    this.dealID = info.dealID;
    this.name = info.name;
    this.status = info.status;
    this.updated = info.updated;
    this.sourceTemplateKey = info.sourceTemplateKey;
    this.type = info.type;
    this.documentAI = deal.documentAI ? true : false;
    this.parties = deal.parties.map(({ partyID, partyName, isSigning }) => ({
      partyID,
      partyName,
      isSigning,
    }));
    if (deal.hasLenses && deal.lensChecks) {
      if (deal.lensChecks.grade) this.grade = deal.lensChecks.grade;

      if (deal.lensChecks.checks.length > 0) {
        this.lensChecks = map(deal.lensChecks.checks, ({ lens, lensFilterChecks }) => {
          const filters = map(lensFilterChecks, ({ id, failedCheck }) => {
            return { id, failedCheck };
          });
          return { id: lens.id, filters };
        });
      }
    }

    if (deal.documentAI && deal.extracted) {
      if (deal.extracted.variables) {
        const needsReview = filter(deal.extracted.variables, { needsReview: true });
        if (needsReview.length > 0) this.needsReview = true;
      }
    }

    this.attachments = map(deal.attachments, (attachment) => {
      const { key, filename, extension, bucketPath, attachmentType } = attachment;
      return { key, filename, extension, bucketPath, attachmentType };
    });

    if (deal.workflow) {
      const { collectionStepName, name, serviceProviderName, serviceProviders, steps, workflowKey } = deal.workflow;

      this.workflow = {
        collectionStepName,
        name,
        serviceProviderName,
        serviceProviders,
        workflowKey,
        steps: [],
      };

      forEach(steps, (step) => {
        delete step.workflow;
        this.workflow.steps.push(step);
      });
    }

    // executes only if object in question is a 'Deal` as only deals would have sourceTemplateKey
    if (this.sourceTemplateKey) {
      // split sourceTemplateKey into sourceTeam and sourceTemplate
      const sourceInfo = info.sourceTemplateKey.split(':') || null;
      this.sourceTeam = sourceInfo[0] || null;
      this.sourceTemplate = sourceInfo[1] || null;
    }

    if (deal.parentDealID) {
      this.parentDealID = deal.parentDealID;
    }

    this.dealType = deal.dealType;

    if (deal.signedDate) {
      this.signedDate = DateFormatter.toMilliseconds(deal.signedDate);
    }

    if (deal.created) {
      this.created = DateFormatter.toMilliseconds(deal.created);
    }

    if (deal.deleted) {
      this.deleted = deal.deleted;
    }

    if (deal.connections) {
      this.connections = map(deal.connections, (connection) =>
        pick(connection, Object.keys(META_FIELDS.connections.children))
      );
    }

    // Note: External deal (uploaded) are created by steps (create, apply template, save attachment, save new version)
    // Because of this, the currentVersion isn't always available at the watcher:create task.
    if (deal.currentVersion) this.currentVersion = deal.currentVersion.key;

    //displayed status will depend on whether this is a Deal or a Template
    let statusLabel;

    if (deal.template) {
      const {
        batch,
        documentAI,
        inbound,
        inboundParty,
        inboundMulti,
        guestSigning,
        autoGuest,
        readonly,
        commenting,
        team,
        title,
        type,
        description,
        key,
        integrations,
        workflow,
        lenses,
        scoring,
      } = deal.template;

      this.template = {
        autoGuest: autoGuest || false,
        batch: batch || false,
        commenting: commenting || false,
        dealID: deal.dealID,
        description: description || '',
        documentAI: documentAI || false,
        guestSigning: guestSigning || false,
        hasLenses: lenses ? size(lenses) > 0 : false,
        inbound: inbound || false,
        inboundMulti: inboundMulti || false,
        inboundParty: inboundParty || null,
        // store *whether* there are integrations, but not the actual data because it will contain API keys and such
        integrations: integrations != null ? true : false,
        key: key || null,
        public: deal.template.public || false,
        readonly: readonly || false,
        showLetterhead: get(deal.template, 'showLetterhead', true),
        showTitle: get(deal.template, 'showTitle', true),
        team: team || null,
        title: title || null,
        type: type || null,
        scoring: scoring || null,
        workflow: workflow || null,
      };

      statusLabel = deal.template.public ? LABEL_PUBLIC : LABEL_PRIVATE;

      forEach(deal.parties, ({ partyID, partyName, isSigning }) => {
        const existingParty = find(this.parties, { partyID });
        if (!existingParty) {
          this.parties.push({
            partyID,
            partyName,
            isSigning,
          });
        }
      });
    } else {
      // Maps the status to the correct display (e.g. 'todo' -> 'Setup')
      const step = info.status ? find(deal.workflow.steps, { key: info.status }) : null;
      // Use display names (title property) in search. if none found (e.g., INGESTION), assume it's in Draft
      statusLabel = step ? step.name : DealStatus.DRAFT.title;
      // If we have a valid step, capture workflow step's defined color for display in Vault etc
      this.statusColor = step ? step.color : null;
    }

    this.status = statusLabel;

    this.versions = map(deal.versions, (version) => ({
      id: version.key,
      ...pick(version, ['dateCreated', 'description', 'docxKey', 'origin', 'owner', 'pdfKey']),
    }));

    // Users is a nested attribute
    const users = [],
      tags = [];
    deal.users.map((du) => {
      const obj = pick(du, [
        'address',
        'addressProperties',
        'email',
        'fullName',
        'org',
        'phone',
        'role',
        'tags',
        'title',
        'uid',
        'dealUserID',
        'inviteID',
        'inviteStatus',
        'inviteStatusMessage',
      ]);

      this.users.push(obj);

      //store user tags outside of the users array and flattened
      //so that we can individually target (and exclude) searches directly via user-tag facet combos
      map(du.tags, (tag) => tags.push(`${du.uid}|${tag}`));

      //include displayable party name into each user if there is one
      if (du.partyName) obj.party = du.partyName;

      if (obj.uid) users.push(obj.uid);
      //in a search context we don't actually care about the official "Party" names
      //as they're usually generic things like "Company" and "Employee"
      //what we really want are the legalNames of the Users who are marked as parties!
      if (du.partyID != null) {
        const partyObj = find(this.parties, { partyID: du.partyID });
        if (!partyObj) {
          this.parties.push({
            userNames: du.fullName ? [du.fullName] : [],
            legalName: du.get('legalName'),
            partyID: du.partyID,
            partyName: du.partyName,
            isSigning: false,
          });
        } else {
          if (du.fullName) {
            if (!partyObj.userNames) {
              partyObj.userNames = [];
            }
            partyObj.userNames.push(du.fullName);
          }
          // Need to add legal name here after checking partyObj
          //this is becauase we query by legalName, so if we are able to get
          // username, we should also be able to get legalName
          if (du.get('legalName')) {
            partyObj.legalName = du.get('legalName');
          }
        }
      }
    });

    //only store list of tags if we find any
    if (tags.length > 0) this.tags = tags;

    //for variables, we only want the simple (value) types and also want to omit images
    forEach(deal.variables, (v) => {
      if (v.type !== VariableType.SIMPLE) {
        //return here so that these variables don't get pushed into DealRecord and indexed
        return;
      }

      //otherwise -- for normal simple variables, we want to index them even if they're empty
      //use a special value for empty variables to power the "Known" and "Unknown" filters
      //and for the rest, try to re-type the values for other operators (< > etc)
      const variable = {
        name: v.name,
        value: v.searchVal,
        valueType: v.valueType,
        displayValue: v.val,
        displayName: v.displayName,
      };

      if (v.valueType === ValueType.DATE) {
        try {
          const dateCheck = new Date(v.searchVal);
          // Must be a date and at least 5 chars (unix timestamp or y/m/d), we can't store partial dates in ES
          if (dateCheck.toString() !== 'Invalid Date' && v.searchVal && v.searchVal.toString().length > 5) {
            variable.dateValue = v.searchVal;
          }
        } catch (err) {
          console.log('Failed to verify date variable.', err);
        }
      }

      if (v.valueType === ValueType.CURRENCY || v.valueType === ValueType.PERCENT || v.valueType === ValueType.NUMBER) {
        // Validate this is a number
        if (!isNaN(v.searchVal)) {
          variable.floatValue = v.searchVal;
        }
      }

      if (v.valueType === ValueType.IMAGE) {
        if (v.value?.downloadURL && v.value?.key) {
          // New image variable format w/ attachment
          variable.value = v.value.downloadURL;
          variable.displayValue = v.value.downloadURL;
          variable.attachmentID = v.value.key;
        } else if (v.value) {
          // Legacy image attachment, use placeholder text instead of base64 string
          variable.value = `[IMAGE_BASE64]`;
          variable.displayValue = `[IMAGE_BASE64]`;
        } else {
          // Image variable with no image
          variable.value = null;
          variable.displayValue = null;
        }
      }

      if ((v.valueType === ValueType.LIST || v.valueType === ValueType.MULTI_SELECT) && v.prompt) {
        variable.options = v.prompt.replace(/\n/g, ', ');
      }

      if (v.valueType === ValueType.MULTI_SELECT && v.value) {
        variable.displayValue = v.value.replace(/\n/g, ', ');
        variable.value = v.value.replace(/\n/g, ', ');
        variable.arrayValue = v.value.split('\n');
      }

      // For tables we don't need to repeat the table data (omit displayValue)
      // We want to store the table data as a json string (will parse when required e.g API response)
      if (v.valueType === ValueType.TABLE) {
        const tableResult = [];
        const tableRows = v.val;

        try {
          if (tableRows && v._columns) {
            for (let i = 0; i < tableRows.length; i++) {
              tableResult[i] = {};
              for (const col of v._columns) {
                if (col.calculated) {
                  // Calculate column for the current row
                  try {
                    tableResult[i][col.id] = col.calculate({ row: tableRows[i] });
                  } catch (err) {
                    tableResult[i][col.id] = err.errorValue ?? 'error';
                  }
                } else {
                  // Nothing to calculate, use the value as is
                  tableResult[i][col.id] = tableRows[i][col.id];
                }
              }
            }
          }
        } catch (err) {
          console.log('Failed generate table data', err);
        }

        delete variable.displayValue;

        // Some tables will not have columns defined in the DB,
        // so if we aren't able to generate a tableResult we fallback to the value in the DB
        variable.value = JSON.stringify(tableResult.length ? tableResult : v.val);
      }

      this.variables.push(variable);
    });
    // There is two ways metaSections could be built
    // 1. The currentVersion is a file one (content is read from the PDF file)
    // 2. The currentVersion is the original (a native deal), so we retrieve the sections.
    if (deal.currentVersion && deal.currentVersion.isOriginal) {
      // Get a flat, ordered list of contract sections
      // Then for each, grab the latest version of the text with variables replaced
      let sections;
      if (deal.isTemplate) {
        // For templates, we want to index ALL sections (including those that would be filtered out via conditions)
        sections = deal.buildSource(true, false);
      }
      // For normal Deals, we only want to index those which pass conditions
      else {
        sections = deal.applyConditions(deal.buildSource(true));
      }

      // Include header/footer for indexing
      for (const footerSection of filter(deal.sections, { sectiontype: SectionType.TEMPLATE_FOOTER })) {
        sections.push(footerSection);
      }

      for (const headerSection of filter(deal.sections, { sectiontype: SectionType.TEMPLATE_HEADER })) {
        sections.push(headerSection);
      }

      sections.map((section) => {
        //TODO: we need a SectionRecord model... (definitely)

        const obj = {};
        obj.id_s = `${this.dealID}|${section.id}`;
        obj.dealID_s = this.dealID;
        obj.type_s = section.sectiontype;
        if (section.showOrder) obj.number_s = section.displayNumber;

        if (section.displayTitle) {
          obj.title_t = section.displayTitle;
        }
        // Also enable lookup of manually titled sections
        else if (section.titleCL) {
          obj.title_t = section.titleCL;
        }

        if (section.titleCL) {
          obj.titleCL_t = section.titleCL;
        }

        const body = section.currentVersion.getText('body', true, deal.variables);
        if (body) obj.body_t = body;

        // Because Sections is a separate index, we need to "flatten" (aka replicate) several other properties too
        //so that we can filter results according to access
        // We should automate that, but it can wait for now
        obj.users_s = users;

        // If section is linked to another section via clause library, capture that link
        // Note this is still necessary on non-template deals in order to compute counts (# times used)
        // But we then need to be extra careful to filter these back out during dynamic updates (API.updateLinkedCL)
        if (section.originCL) {
          obj.originCL_s = section.originCL;
        }

        // If this is a Template, all sections will have the Template's key; otherwise template = false
        if (deal.isTemplate) {
          // Exclude from CL search if:
          // 1) template is in draft,
          // 2) entire template is marked for exclusion, or
          // 3) section is marked for exclusion
          // 4) section is part of a Caption
          if (!deal.template.public || deal.template.excludeCL || section.excludeCL || section.isCaption) {
            obj.excludeCL_b = true;
          }

          obj.template_s = this.template.key;
          obj.displayTemplate_s = deal.template.title;

          if (section.conditions.length > 0) {
            obj.conditions_o = map(section.conditions, (condition) => {
              return {
                variable_s: condition.variable,
                values_s: Array.isArray(condition.values) ? condition.values : [condition.values],
              };
            });
          }
        }

        // If we can find the source team, populate that too
        obj.team_s = this.template ? this.template.team : this.sourceTeam ? this.sourceTeam : null;

        this.sections.push(obj);
      });
    } else if (deal.currentOCR) {
      const content = get(deal, 'currentOCR.content', []);
      map(content, (text, key) => {
        const obj = {};
        obj.id_s = `${this.dealID}|${key}`;
        obj.dealID_s = this.dealID;
        obj.body_t = text;
        obj.users_s = users;
        obj.team_s = this.template ? this.template.team : this.sourceTeam ? this.sourceTeam : null;

        this.sections.push(obj);
      });
    }

    this.activities = deal.filterActivity({});
  }

  get meta() {
    const meta = pick(this, keys(META_FIELDS));
    return convertMetaToES(meta, META_FIELDS);
  }

  get metaSections() {
    return this.sections;
  }

  get metaActivities() {
    // Format activities for ElasticSearch
    return map(this.activities, (activity) => ({
      created_dt: activity.id,
      dealID_s: this.dealID,
      id_s: `${this.dealID}|${activity.id}`,
      message_t: activity.message || null,
      type_s: activity.action,
      user_s: activity.user,
    }));
  }

  get usersByParty() {
    //group list of users by party
    const parties = {};
    map(this.users, (u) => {
      if (!u.party) return;
      if (!parties[u.party]) parties[u.party] = [];
      parties[u.party].push(u);
    });
    return parties;
  }

  get displayUpdated() {
    return DateFormatter.fromMilliseconds(this.updated);
  }

  get displayUpdatedShort() {
    return DateFormatter.fromMilliseconds(this.updated, true);
  }

  get isExternal() {
    return this.dealType === DEAL_TYPE.EXTERNAL || this.dealType === DEAL_TYPE.EXTERNAL_WORD;
  }

  get displayDeletedShort() {
    return this.deleted ? DateFormatter.fromMilliseconds(this.deleted.time, true) : this.displayUpdatedShort;
  }

  userRole(uid) {
    const me = find(this.users, { uid });
    return me ? me.role : null;
  }

  canLeave(uid) {
    const me = find(this.users, { uid });
    // A user who is not on the deal can't leave it :-)
    if (!me) return false;

    // If there are no other owners, leaving deal would orphan it so disallow
    // Also make sure other owners have a userID, as we don't want the last owner
    // to not have an account
    const otherOwners = filter(this.users, (du) => du.role === DealRole.OWNER && du.uid && du.uid !== uid);
    if (!otherOwners.length) return false;

    // Here we know there are other owners, but if current user is in a party and the deal is signed,
    // leaving is disallowed as well
    if (me.party && this.status === DealStatus.SIGNED.title) return false;

    // Finally here we know the current user can safely leave the deal without orphaning or altering a signed deal
    return true;
  }

  // A DealRecord does not know internally whether a particular user can sign,
  // Because that data requires loading the full Deal (ie to inspect individual sections)
  // But if the current user is in a party and the deal is not yet fully signed, we can enable the attempt
  // For use in bulk signing context (see BatchActionDropdown)
  canTrySigning(uid) {
    // Only deals in REVIEW or SIGNING stages *may* be signable
    if (![DealStatus.REVIEW.title, DealStatus.SIGNING.title].includes(this.status)) return false;

    const me = find(this.users, { uid });
    // A user who is not on the deal can't sign it :-)
    if (!me) return false;

    // Here we've got a user who is a signer and a deal that may be signable, so attempting is ok!
    return !!me.party;
  }
}
