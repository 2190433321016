import { autoBindMethods } from 'class-autobind-decorator';
import { clone, find, findIndex, forEach, get, keys, map } from 'lodash';
import qs from 'query-string';

import { DateFormatter } from '../utils/DateTime';
import { FACETS } from './DealRecord';
import FacetFilter from './FacetFilter';
import { OPERATORS } from './Operator';
import VariableFilter, { DYNAMIC_DATES } from './VariableFilter';

// This needs to be explicitly specified here,
// so that that we aren't referencing a Component class from inside the Model
// But these refer by key to Columns defined in Columns.jsx
export const DEFAULT_COLS = ['status', 'name', 'origin', 'parties', 'updated', 'owners', 'attachments'];
export const LENS_COLS = ['grade'];
export const OTHER_COLS = ['connections'];
export const TRASH_COLS = ['status', 'name', 'origin', 'parties', 'deleted_o.time', 'owners', 'attachments'];

@autoBindMethods
export default class SearchParams {
  location;
  history;
  params;

  facets = [];
  variables = [];
  grades = [];
  lenses = [];
  page;

  constructor(location, history, json) {
    this.location = location;
    this.history = history;
    let params;

    // If we're loading SearchParams from a saved search, use that
    if (typeof json === 'object') {
      params = json;
    }
    // Otherwise load in from URL
    else {
      params = qs.parse(location.search);
    }
    this.params = params;

    const facets = [],
      variables = [],
      date = [],
      connections = [];

    // For each param in querystring, lookup the corresponding named facet for display
    forEach(params, (value, key) => {
      // Special url params handled elsewhere -- skip these in the loop
      if (
        [
          'page',
          'sort',
          'hitsPerPage',
          'query',
          'name',
          'columns',
          'vars',
          'filterID',
          'dateFilters',
          'connections',
          'crossTemplate',
          'sharingStatus',
          'needsReview',
          'grades',
          'deleted',
        ].indexOf(key) > -1
      )
        return;

      // If param is a supported facet, add it to typed array
      const facet = find(FACETS, { key });

      // Next, see if the target facet is supported
      if (facet) {
        facets.push(new FacetFilter(facet.key, value.split('|')));
        return;
      } else {
        // We shouldn't get here -- only process specific facets,
        // i.e., those defined in FACETS const or excluded (handled specially) above
        console.log(`[SEARCH] - Unrecognized search param [${key}] - ignoring`);
      }
    });

    // Now parse var filters, which, if present, will be a JSON.stringify'd Array on the 'vars' param
    if (params.vars) {
      try {
        const vars = JSON.parse(params.vars);
        forEach(vars, ({ v, t, op, val }) => {
          variables.push(new VariableFilter(v, { valueType: t, operator: op, values: val }));
        });
      } catch (e) {
        console.log('[SEARCH] - Unrecognized variable filter format in URL - ignoring');
      }
    }

    //Get the date filter and set date to be used for api call.
    if (params.dateFilters) {
      try {
        const dateParsed = JSON.parse(params.dateFilters);
        const timezone = DateFormatter.timezoneIANA();
        forEach(dateParsed, (d) => {
          if (d.o === OPERATORS.DYNAMIC.name) {
            const offset = DYNAMIC_DATES.find((dd) => dd.title === d.dynamicDates);
            const now = new Date();
            const then = new Date();

            then.setDate(then.getDate() + offset.val);

            date.push({ name: d.n, value: DateFormatter.buildESDateFormat(then, now), timezone: timezone });
          } else {
            date.push({ name: d.n, value: d.v, timezone: timezone });
          }
        });
      } catch (e) {
        console.log('[SEARCH] - Unrecognized Date filter format in URL - ignoring');
      }
    }

    //Get the date filter and set date to be used for api call.
    if (params.connections) {
      try {
        const connectionParsed = JSON.parse(params.connections);
        forEach(connectionParsed, ({ type, id }) => {
          connections.push({ type, id });
        });
      } catch (e) {
        console.log('[SEARCH] - Unrecognized Connection filter format in URL - ignoring');
      }
    }

    this.facets = facets;
    this.variables = variables;
    this.grades = params.grades ? JSON.parse(params.grades) : [];
    this.lenses = params.lenses ? JSON.parse(params.lenses) : [];
    this.date = date;
    this.connections = connections;

    // Paging is visually a 1-based index, but convert to 0-based internally for API/Search
    this.page = params.page == null ? 0 : params.page - 1;
  }

  clearSearch() {
    if (this.deleted) this.history.push(this.location.pathname + '?deleted=true');
    else this.history.push(this.location.pathname);
  }

  toggleParam(param, value) {
    // Ensure that we're only supporting URL params that are explicitly searchable
    const facet = find(FACETS, { key: param });
    if (!facet) return;

    let values = get(this.params, param) ? get(this.params, param).split('|') : [];
    const idx = values.indexOf(value);

    // Value already found, remove it from search query
    if (idx > -1) {
      if (facet.multi) values.splice(idx, 1);
    }
    // Value not present; either add it (if multi) or replace (e.g., for templateID)
    else {
      if (facet.multi) values.push(value);
      else values = value ? [value] : [];
    }

    if (values.length) this.params[param] = values.join('|');
    else delete this.params[param];

    // Anytime the selected template changes (whether to a new one or null)
    // We need to clear all variable filters because they're template specific
    if (param === 'sourceTemplateKey' && value !== this.sourceTemplateKey) {
      delete this.params.vars;
      delete this.params.grades;
      delete this.params.lenses;
    }

    if (param === 'teamID') {
      if (value) {
        this.params.teamID = value;
      } else {
        delete this.params.teamID;
      }

      // whenever we modify the team param, we need to reset both the sourceTemplateKey & vars
      delete this.params.sourceTemplateKey;
      delete this.params.vars;
      delete this.params.grades;
      delete this.params.lenses;
    }

    // Always reset paging anytime search changes
    delete this.params.page;

    this.go();
  }

  toggleVarFilter(filter) {
    // Ensure we have an object store for var filters (though we may delete again below)
    let vars = [],
      columns = clone(this.columns);
    try {
      if (this.params.vars) vars = JSON.parse(this.params.vars);

      const columnName = `v.${filter.variable}.${filter.valueType}`;
      const colIdx = columns.findIndex((v) => {
        // Find index using startsWith, as the valueType will be empty when clearing a filter
        return v.startsWith(`v.${filter.variable}.`);
      });

      // Whether no operator (clearing the var filter) or a new value,
      // first remove existing filter from array if present
      const idx = findIndex(vars, { v: filter.variable });
      if (idx > -1) vars.splice(idx, 1);

      if (!filter.operator) {
        // Remove the column too when filter is removed
        if (colIdx > -1) columns.splice(colIdx, 1);
      }
      // Otherwise push raw version, which will get JSON.stringify'd into params below
      else {
        vars.push({
          v: filter.variable,
          op: filter.operator.key,
          val: filter.values,
          t: filter.valueType,
        });

        // Ensure that a newly added var filter also shows up in columns if not already there
        if (colIdx === -1) columns.push(columnName);
      }
    } catch (error) {
      console.log('[SEARCH] - Unrecognized variable filter format in URL - ignoring');
    }

    // If there are no variable filters, delete the param
    if (!vars.length) {
      delete this.params.vars;
    }
    // Otherwise serialize as string
    else {
      this.params.vars = JSON.stringify(vars);
    }

    // Always reset paging anytime search changes
    delete this.params.page;

    // If columns changed, update columns param too
    // but here we do it manually instead of using setter because go() will update URL
    // so we don't want to do that twice
    if (columns.join(',') !== this.columns.join(',')) {
      this.params.columns = columns.join(',');
    }
    // If that makes the columns back to default, remove param
    if (this.params.columns === DEFAULT_COLS.join(',') || this.params.columns === TRASH_COLS.join(',')) {
      delete this.params.columns;
    }

    // Finally, push to URL
    this.go();
  }

  toggleDateFilter(date, dateTo, dateType, operatorName, dynamicDates) {
    let dateFilters = [];
    const value = DateFormatter.buildESDateFormat(date, dateTo);

    try {
      //get the current dateFilters
      if (this.params.dateFilters) {
        dateFilters = JSON.parse(this.params.dateFilters);
      }

      //check if there is already a filter on the specified datetype.
      let index = findIndex(dateFilters, (df) => {
        return df.n === dateType;
      });
      //if the filter already exists and its a update not delete updated the specified date type.
      if (index !== -1 && value) {
        dateFilters[index] = {
          v: value,
          n: dateType,
          o: operatorName,
          dynamicDates: dynamicDates ? dynamicDates : null,
        };
      }
      //if we find the index but its a delete opperation (value is null, date is null, dateTo is null) delete the entry
      else if (index !== -1 && !value) {
        dateFilters.splice(index, 1);
      }
      //otherwise add it if the value exists
      else if (index === -1 && value) {
        dateFilters.push({
          v: value,
          n: dateType,
          o: operatorName,
          dynamicDates: dynamicDates ? dynamicDates : null,
        });
      }
      //if there is a filter add it.
      if (dateFilters.length > 0) {
        //reload the dateFilter search parameter
        this.params.dateFilters = JSON.stringify(dateFilters);
      } else {
        delete this.params.dateFilters;
      }
    } catch (err) {
      console.log('[SEARCH] - Unrecognized date filter format in URL - ignoring');
    }

    this.go();
  }

  toggleConnection({ type, id }) {
    let connections = [];

    try {
      //get the current connections
      if (this.params.connections) {
        connections = JSON.parse(this.params.connections);
      }

      //check if there is already a filter on the specified datetype.
      let index = findIndex(connections, (connectionFilter) => {
        return connectionFilter.type === type;
      });

      //if the filter already exists and its an update, not a delete, updated the specified connection type.
      if (index !== -1 && id) {
        connections[index] = { id, type };
      }
      //if we find the index but its a delete opperation (value is null, date is null, dateTo is null) delete the entry
      else if (index !== -1 && !id) {
        connections.splice(index, 1);
      }
      //otherwise add it if the value exists
      else if (index === -1 && id) {
        connections.push({ type, id });
      }

      //if there is a filter add it.
      if (connections.length > 0) {
        //reload the dateFilter search parameter
        this.params.connections = JSON.stringify(connections);
      } else {
        delete this.params.connections;
      }
    } catch (err) {
      console.log('[SEARCH] - Unrecognized connnection filter format in URL - ignoring');
    }

    this.go();
  }

  setPage(page) {
    this.params.page = page;
    this.go();
  }

  get hitsPerPage() {
    return this.params.hitsPerPage || 50;
  }

  set hitsPerPage(hitsPerPage) {
    this.params.hitsPerPage = hitsPerPage;
    if (this.params.page) delete this.params.page;
    this.go();
  }

  searchGlobalFailedInvitation() {
    this.facets = [];
    this.variables = [];
    this.date = [];
    this.connections = [];
    this.variables = [];
    this.page = 0;
    this.params = { sharingStatus: 'failed' };
    this.go();
  }

  searchGlobalNeedsReview() {
    this.facets = [];
    this.variables = [];
    this.date = [];
    this.connections = [];
    this.variables = [];
    this.page = 0;
    this.params = { needsReview: true };
    this.go();
    return;
  }

  searchDeletedDeals() {
    this.facets = [];
    this.variables = [];
    this.date = [];
    this.connections = [];
    this.variables = [];
    this.page = 0;
    this.params = { deleted: true };
    this.go();
    return;
  }

  // TemplateSelector passes in a Template object on new selection
  // But it may be null (if selecting none) so ensure that this is propagated correctly to params
  selectTemplate(template) {
    this.toggleParam('sourceTemplateKey', get(template, 'sourceTemplateKey', null));
  }

  selectTeam(team) {
    this.toggleParam('teamID', get(team, 'teamID', null));
  }

  getFacetValues(key) {
    return get(
      find(this.facets, (filter) => filter.facet.key === key),
      'values',
      []
    );
  }

  go() {
    let path = this.location.pathname;
    if (keys(this.params).length) path += `?${qs.stringify(this.params)}`;
    this.history.push(path);
  }

  setGrades(grades) {
    const remove = grades.length === 0;

    let columns = clone(this.columns);
    const colIdx = columns.findIndex((v) => {
      return v.startsWith('grade');
    });

    // Remove the column too when filter is removed
    if (colIdx > -1 && remove) columns.splice(colIdx, 1);

    // Ensure that a newly added var filter also shows up in columns if not already there
    if (colIdx === -1) columns.push('grade');

    if (columns.join(',') !== this.columns.join(',')) {
      this.params.columns = columns.join(',');
    }

    // If that makes the columns back to default, remove param
    if (this.params.columns === DEFAULT_COLS.join(',')) {
      delete this.params.columns;
    }

    if (grades.length > 0) this.params.grades = JSON.stringify(grades);
    else delete this.params.grades;
    this.go();
  }

  setLenses(lenses, lensID, filterID, remove) {
    /*Keeping all lense column stuff until we define how we want to show this for all lens types
    let columns = clone(this.columns);
    const columnName = `l.${lensID}.${filterID}`;
    const colIdx = columns.findIndex((v) => {
      return v.startsWith(columnName);
    });

    // Remove the column too when filter is removed
    if (colIdx > -1 && remove) columns.splice(colIdx, 1);

    // Ensure that a newly added var filter also shows up in columns if not already there
    if (colIdx === -1) columns.push(columnName);

    if (columns.join(',') !== this.columns.join(',')) {
      this.params.columns = columns.join(',');
    }
    // If that makes the columns back to default, remove param
    if (this.params.columns === DEFAULT_COLS.join(',')) {
      delete this.params.columns;
    }*/

    if (lenses.length > 0) this.params.lenses = JSON.stringify(lenses);
    else delete this.params.lenses;
    this.go();
  }

  get isEmpty() {
    return (
      !this.facets.length &&
      !this.variables.length &&
      !this.date.length &&
      !this.connections.length &&
      !this.sharingStatus &&
      (this.columns.join(',') === DEFAULT_COLS.join(',') || this.columns.join(',') === TRASH_COLS.join(','))
    );
  }

  get statuses() {
    return this.getFacetValues('status');
  }
  get tags() {
    return this.getFacetValues('tags');
  }
  get parties() {
    return this.getFacetValues('parties');
  }
  get users() {
    return this.getFacetValues('users');
  }
  get sourceTemplateKey() {
    return this.getFacetValues('sourceTemplateKey').toString() || null;
  }
  get teamID() {
    return this.getFacetValues('teamID').toString() || null;
  }
  get query() {
    return this.params.query || '';
  }
  get name() {
    return this.params.name || '';
  }
  get variableQuery() {
    if (this.params.variableQuery) {
      const variables = this.params.variableQuery.split('+');
      let variablesFormatted = map(variables, (variable, index) => {
        //remove #
        variable = variable.slice(1);
        //split the value and key
        const variableDef = variable.split(':');
        //remove whitespace around the string
        const valueFormatted = variableDef[1].trim();
        return { name: variableDef[0], value: valueFormatted };
      });
      return variablesFormatted;
    } else {
      return [];
    }
  }

  set query(query) {
    if (query) this.params.query = query;
    else delete this.params.query;
    this.go();
  }

  set variableQuery(variableQuery) {
    if (variableQuery) this.params.variableQuery = variableQuery;
    else delete this.params.variableQuery;
    this.go();
  }

  set name(name) {
    if (name) this.params.name = name;
    else delete this.params.name;
    this.go();
  }

  get sort() {
    return this.params.sort || null;
  }

  get crossTemplate() {
    return this.params.crossTemplate || null;
  }

  set crossTemplate(crossTemplate) {
    if (crossTemplate) this.params.crossTemplate = crossTemplate;
    else delete this.params.crossTemplate;
    this.go();
  }

  get needsReview() {
    return this.params.needsReview || null;
  }

  set needsReview(needsReview) {
    if (needsReview) this.params.needsReview = needsReview;
    else delete this.params.needsReview;
    this.go();
  }

  set sort(sort) {
    this.params.sort = sort;
    if (this.params.page) delete this.params.page;
    this.go();
  }

  get columns() {
    const colString = get(this.params, 'columns');
    if (colString) return colString.split(',');
    if (this.deleted) return TRASH_COLS;
    else return DEFAULT_COLS;
  }

  set columns(columns) {
    // Serialize columns as comma separated list in URL param
    if (columns.length) {
      this.params.columns = columns.join(',');
    }
    // Or remove if empty set (set to defualt)
    else {
      delete this.params.columns;
    }
    // Also no need to actually show columns in URL if it's the default set
    if (this.params.columns === DEFAULT_COLS.join(',')) {
      delete this.params.columns;
    }
    // Finally, push to URL
    this.go();
  }

  get sharingStatus() {
    return this.params.sharingStatus || null;
  }

  set sharingStatus(sharingStatus) {
    if (sharingStatus) this.params.sharingStatus = sharingStatus;
    else delete this.params.sharingStatus;
    this.go();
  }

  get deleted() {
    return this.params.deleted ? this.params.deleted === 'true' || false : null;
  }

  set deleted(deleted) {
    if (deleted === true) this.params.deleted = true;
    else delete this.params.deleted;
    this.go();
  }

  // Build a json object ready to pass to API.getDeals()
  get apiArgs() {
    const args = {
      query: this.query,
      name: this.name,
      variableQuery: this.variableQuery,
      hitsPerPage: this.hitsPerPage,
      page: this.page,
      facets: [],
      dateFilters: this.date,
      connections: this.connections,
      grades: this.grades,
      lenses: this.lenses,
    };

    forEach(this.facets, (filter) => {
      if (filter.facet.key === 'tags') {
        args.tags = filter.values;
      } else {
        args.facets.push({ facet: filter.facet.key, values: filter.values });
      }
    });

    if (this.needsReview) {
      args.needsReview = this.needsReview;
    }

    if (this.variables.length) {
      args.variables = map(this.variables, 'json');
    }

    if (this.sharingStatus) {
      args.sharingStatus = this.sharingStatus;
    }

    if (this.deleted) {
      args.deleted = this.deleted;
    }

    if (this.sort) args.sort = this.sort;

    return args;
  }

  // Get a savable version of the search (for saved searches/reporting)
  // which is just the URL params
  get json() {
    return clone(this.params);
  }

  //build dateFilters so we can get all of the necesary data from the params in the URL.
  get dateFilters() {
    try {
      const dateFilters = JSON.parse(this.params.dateFilters);
      forEach(dateFilters, (dateFilter, idx) => {
        const dates = dateFilter.v.split(',');
        if (dateFilters[idx].o === 'DYNAMIC') {
          dateFilters[idx].filterLabel = dateFilters[idx].dynamicDates;
        } else {
          //Adding "T00:00-0800" solves the time issues switching date days
          dateFilters[idx].date = dates[0].concat('T00:00-0800');
          dateFilters[idx].filterLabel = dates[0];
          if (dates[1]) {
            dateFilters[idx].dateTo = dates[1].concat('T00:00-0800');
          }
          if (dates[0] !== dates[1] && dates[1]) {
            dateFilters[idx].filterLabel = dateFilters[idx].filterLabel.concat(' and ', dates[1]);
          }
        }
      });
      return dateFilters;
    } catch (e) {
      return this.params.dateFilters;
    }
  }
}
