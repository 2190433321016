import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';

import autobindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import { plural } from 'pluralize';
import PropTypes from 'prop-types';

import { Checkbox, ControlLabel, FormControl, FormGroup, Overlay, Radio } from 'react-bootstrap';

import SectionType from '@core/enums/SectionType';
import { ADDRESS_PROPERTIES } from '@core/models/Address';
import { findVariableSuggestText, rxSuggest, rxVariableReplace } from '@core/models/Content';
import PDFDeal from '@core/models/PDFDeal';
import { PARTY_PROPERTIES } from '@core/models/Party';
import TableColumn, { DISALLOWED_CHARS, sanitize as sanitizeColumn } from '@core/models/TableColumn';
import Variable from '@core/models/Variable';
import {
  DATE_LANGUAGES,
  DATE_PROPERTIES,
  EXTRACT_TYPE,
  NUMBER_PROPERTIES,
  REDACTION_EXTENTS,
  REDACTION_METHODS,
  SAVEABLE_VAR_FIELDS,
  SEPERATORS,
  SimpleTypeLabels,
  TEXT_TRANSFORMS,
  ValueType,
  VariableKey,
  VariableType,
  redactionValues,
  sanitize as sanitizeVariable,
  validateFormula,
} from '@core/models/Variable';
import { getUniqueKey } from '@core/utils/Generators';
import { setCursor } from '@core/utils/HTMLInput';

import {
  Alert,
  Button,
  Dropdown,
  Ellipsis,
  Form,
  Icon,
  Loader,
  MenuItem,
  Popover,
  Switch,
  Validator,
} from '@components/dmp';

import ImageUploader from '@components/ImageUploader';
import MultiselectDropdown from '@components/MultiselectDropdown';
import PartySelector from '@components/PartySelector';
import VariableConnector from '@components/connect/VariableConnector';
import TooltipButton from '@components/editor/TooltipButton';
import VariableSuggest from '@components/editor/VariableSuggest';
import API from '@root/ApiClient';
import Fire from '@root/Fire';
import VariableIndex from '@root/utils/VariableIndex';

import DecimalSelector from './DecimalSelector';

@autobindMethods
class VariableEditor extends Component {
  static defaultProps = {
    showValue: true,
    showPrompt: true,
    showPropertySelector: true,
    showTextTransformSelector: true,
    displayToName: false,
    showLabels: false,
  };

  static propTypes = {
    text: PropTypes.string,
    creating: PropTypes.bool,
    rootClose: PropTypes.bool,
    candidate: PropTypes.object,
    container: PropTypes.object,
    deal: PropTypes.object,
    target: PropTypes.object,
    hide: PropTypes.func,
    onSave: PropTypes.func,
    showPrompt: PropTypes.bool,
    showValue: PropTypes.bool,
    showPropertySelector: PropTypes.bool,
    showTextTransformSelector: PropTypes.bool,

    displayToName: PropTypes.bool,
    showLabels: PropTypes.bool,
    variableIndex: PropTypes.instanceOf(VariableIndex),
  };

  constructor(props) {
    super(props);

    // This is just for a base id for child controls
    this.id = 'var-' + getUniqueKey();

    this.state = {
      type: '',
      name: '',
      displayName: '',
      value: '',
      prompt: '',
      valueType: null,
      assigned: null,
      pluralName: '',

      revealing: false,
      renaming: false,

      property: 'none',
      addressDisplayProperty: 'none',
      textTransform: 'none',
      isValueValid: true,
      decimals: null,

      autoPull: false,
      autoPush: false,
      multiline: false,
      imageAttachment: null,
      showTotalRow: false,
      columnsToTotal: [],
      totalRowLabel: null,
      editingColumns: false,
      formula: '',
      vsState: null,
      variants: [],
      extract: false,
      extractType: null,
      tableColumnWarning: null,
      seperator: null,
      extractionInstructions: '',
      redact: false,
      redactionExtent: REDACTION_EXTENTS.ENTIRE_VALUE,
      redactionMethod: REDACTION_METHODS.BLACKOUT,
      redactionValue: '',
      calcValue: null,
      language: null,
    };

    this.nameRef = createRef();
    this.displayNameRef = createRef();
    this.refFormula = createRef();
    this.refSuggest = createRef();

    this.tableErrorTimer = null;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.tableErrorTimer);
  }

  componentDidMount() {
    this._isMounted = true;

    this.populate(this.props);
    //reveal protected vars if we have any
    this.revealProtected(this.getVariable(this.props));
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.revealProtected(this.getVariable(newProps));
  }

  async populate(props) {
    const { text, creating, candidate, deal } = props;
    const variable = this.getVariable(props) || {};

    //we might be adding a new variable, which won't be part of the deal yet
    //so we pull a few properties from the raw props.text passed in
    let type = /^\[[!@#$%*+^]/.test(text) ? text.substr(1, 1) : VariableType.SIMPLE;
    let displayName = variable.displayName || '',
      pieces = [];
    let name = this.getVariableName(text);

    //special case for new refs -- the text passed in should actually be used as displayName
    //and name will be generated when first saved
    if (type === VariableType.REF && !variable.name) {
      name = '';
      //splitting on space means that if someone highlights existing text like "Section 4.1"
      //the displayName (prefix) will auto-populate with "Section"
      //which is exactly the desired behavior
      pieces = text.substring(2, text.length - 1).split(' ');
      displayName = pieces.shift();
    } else {
      pieces = text.substring(2, text.length - 1).split('.');
    }

    // If candidate element data is passed in (ingestion),
    // gather whatever additional data we have from that
    if (candidate) {
      if (candidate.displayName) displayName = candidate.displayName;
      if (candidate.type) type = candidate.type;
      if (candidate.valueType) variable.valueType = candidate.valueType;
    }

    // Special case for multiline text vars which are stored as arrays for line breaks
    // Normally this would be encapsulated in Variable.val but we don't have a Variable instance yet
    // So we need to repeat it here
    const multiline = _.get(variable, 'multiline', false);
    const valueType = variable.valueType || ValueType.STRING;
    let value;
    // For multiline vars, convert to string for editing
    if (multiline && Array.isArray(variable.value)) value = variable.value.join('\n');
    else if (variable.valueType === ValueType.MULTI_SELECT) value = variable.baseVariable.multiSelectedOptions;
    else value = variable.value || '';

    const showTotalRow = _.get(variable, 'showTotalRow', false);
    const columnsToTotal = _.get(variable, 'columnsToTotal', []);
    const formula = _.get(variable, 'formula') || '';
    const redactionExtent = _.get(variable, 'redactionExtent') || null;
    const redactionMethod = _.get(variable, 'redactionMethod') || null;
    const redactionValue = _.get(variable, 'redactionValue') || null;

    const decimals = _.get(variable, 'decimals') || 'Default';

    const prompt =
      variable.valueType === ValueType.TABLE
        ? _.map(variable.columns, (tc) => `${tc.id?.replace('_null', '')}`).join('\n')
        : variable.prompt;

    const { property, textTransform } =
      variable instanceof Variable ? variable.getPropertyAndTextTransform(pieces) : {};

    const addressProperty = ADDRESS_PROPERTIES.find(({ data }) => pieces.includes(data));

    const addressDisplayProperty =
      addressProperty && variable.type === VariableType.PARTY && property === 'address' ? addressProperty.data : 'none';

    this.setState({
      type,
      name,
      displayName,
      pluralName: variable.pluralName || '',
      value,
      prompt: prompt || '',
      valueType,
      assigned: variable.assigned || null,
      autoPull: _.get(variable, 'autoPull', false),
      autoPush: _.get(variable, 'autoPush', false),
      multiline,
      formula,
      decimals,

      revealing: false,

      property,
      textTransform,
      addressDisplayProperty,
      isValueValid: true,
      showTotalRow,
      columnsToTotal,
      totalRowLabel: variable?.totalRowLabel === null ? 'Total' : variable?.totalRowLabel?.trim(),
      editingColumns: false,
      variants: variable.variants ? variable.variants.join('\n') : '',
      extract: variable.extract ? variable.extract : false,
      extractType: variable.extractType ? variable.extractType : null,
      extractionInstructions: variable.extractionInstructions ? variable.extractionInstructions : '',
      seperator: variable.seperator,
      redact: redactionExtent || redactionMethod,
      redactionExtent,
      redactionMethod,
      redactionValue,
      language: _.get(variable, 'language', null),
    });

    //if we're in creation mode (which should usually be true here)
    //attempt to actually lookup the target section based on the text!
    //note pieces array has been shifted so is now just the target section number
    if (creating && pieces.length > 0) {
      const numberText = pieces.join(' ');
      const isAppendix = text.match(/Appendix|Exhibit|Schedule|Attachment/) != null;
      const targetSection = deal.findSection(numberText, isAppendix);

      if (targetSection) {
        this.setState({ value: targetSection.id });
      }
    }
  }

  getVariableName(text) {
    return text.replace(rxVariableReplace, '').split('.')[0];
  }

  isTextTranformUpdated() {
    const { text } = this.props;
    const variable = this.getVariable(this.props);
    const pieces = text.replace(/[!@#$%*+\[\]]/gi, '').split('.');
    const { textTransform, property } =
      variable && variable instanceof Variable ? variable.getPropertyAndTextTransform(pieces) : {};

    return textTransform !== this.state.textTransform || property !== this.state.property;
  }

  isAssignedUpdated() {
    const { assigned } = this.state;
    const variable = this.getVariable(this.props);
    return variable.assigned !== assigned;
  }

  isRedactionUpdated() {
    const { redact, redactionExtent, redactionMethod, redactionValue } = this.state;
    const variable = this.getVariable(this.props);

    return (
      (!redact && (redactionExtent || redactionMethod)) ||
      variable?.redactionExtent !== redactionExtent ||
      variable?.redactionMethod !== redactionMethod ||
      variable?.redactionValue !== redactionValue
    );
  }

  showUpdateSection() {
    return (
      this.isTextTranformUpdated() ||
      this.isAssignedUpdated() ||
      this.isShowTotalOrTotalLabelUpdated() ||
      this.isRedactionUpdated()
    );
  }

  canUpdateConnected() {
    const variable = this.getVariable(this.props);
    return variable?.type === VariableType.CONNECTED && variable.autoPush;
  }

  isShowTotalOrTotalLabelUpdated() {
    const variable = this.getVariable(this.props) || {};
    const { showTotalRow, totalRowLabel } = this.state;
    const variableLabel = variable?.totalRowLabel;

    const totalRowUpdated = showTotalRow !== variable.showTotalRow;
    const variableLabelUpdated = variableLabel && totalRowLabel !== variableLabel.trim();

    const isUpdated = totalRowUpdated || variableLabelUpdated;

    return isUpdated;
  }

  get isValid() {
    const { name, type, vsState, formula } = this.state;

    // Refs don't allow editing the name, so no need to validate it
    if (type === VariableType.REF) return true;

    // For Calculated vars, if VariableSuggest is open or if formula is invalid, don't allow save
    if (type === VariableType.CALCULATED && (vsState || !validateFormula(formula))) return false;

    return name && isNaN(name[0]);
  }

  getVariable(props) {
    const { deal, text } = props;

    return deal.variables[this.getVariableName(text)];
  }

  get typeDisplayName() {
    switch (this.state.type) {
      case VariableType.PARTY:
        return 'Party';
      case VariableType.REF:
        return 'Reference';
      case VariableType.TERM:
        return 'Term';
      case VariableType.PROTECTED:
        return 'Secret';
      case VariableType.SIMPLE:
      default:
        return 'Variable';
    }
  }

  validate() {
    const { value } = this.state;
    const variable = this.getVariable(this.props);

    if (!variable) return true;
    return variable.validateValue(value);
  }

  validateFormula() {
    const { formula } = this.state;
    const variable = this.getVariable(this.props);

    if (!variable) return true;
    if (variable.type !== VariableType.CALCULATED) return true;

    return validateFormula(formula);
  }

  revealProtected(v) {
    if (v && v.value && v.type == VariableType.PROTECTED) {
      if (this._isMounted) this.setState({ revealing: true });
      API.call('getProtected', { dealID: v.deal.dealID, varName: v.name }, (value) => {
        if (this._isMounted) this.setState({ value, revealing: false });
      });
    }
  }

  handleChange(e, prop) {
    const { type } = this.state;
    const { creating, deal, displayToName } = this.props;

    const newState = {};
    newState[prop] = e.target.value;

    // Variable.name always needs to be sanitized
    // Variable.displayName should also auto-set variable name if we're either creating a ref
    // or if we're in Draft, where the variable name isn't visible or set explicitly
    let updateName;
    if (prop === 'name') updateName = true;
    if (prop === 'displayName') {
      if (creating && type == VariableType.TERM) updateName = true;
      else if (displayToName) updateName = true;
      else if (deal.inDraft) updateName = true;
    }

    //If table column, sanitize column, else, variable
    if (updateName) newState.name = sanitizeVariable(e.target.value);

    this.setState(newState);
  }

  handleTypeChange(valueType) {
    const v = this.getVariable(this.props);

    if (v && v.valueType !== valueType) {
      // Image
      if ([v.valueType, valueType].includes(ValueType.IMAGE)) this.setState({ value: '' });

      // Table
      if ([v.valueType, valueType].includes(ValueType.TABLE)) this.setState({ value: '' });
    }

    this.setState({ valueType });
  }

  updateVariableSuggest(e) {
    const vsState = findVariableSuggestText({ element: e.target, rx: rxSuggest });
    this.setState({ vsState });
  }

  async commitVariableSuggestion(newText) {
    const { vsState, formula } = this.state;

    const newFormula = formula.slice(0, vsState.start) + newText + formula.slice(vsState.start + vsState.input.length);
    await this.setState({ formula: newFormula, vsState: null });

    const el = ReactDOM.findDOMNode(this.refFormula.current);
    setCursor(el, vsState.start + newText.length);
  }

  handleVS(e) {
    const { vsState } = this.state;
    const varSuggest = this.refSuggest.current;

    // Allow VariableSuggest to hijack certain keys (enter, escape, arrows) if active
    if (vsState && varSuggest && varSuggest.handleKey(e)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  async saveColumnsPrompt(e) {
    const { deal } = this.props;
    const { valueType, prompt, displayName } = this.state;

    if (e) e.stopPropagation();
    const variable = this.getVariable(this.props);

    if (!variable) {
      return this.save(e);
    }

    if (variable && valueType === ValueType.TABLE && variable.prompt !== prompt) {
      if (!prompt) {
        variable.value = null;
        variable.columns = null;
        variable.prompt = null;
        variable.totalRowLabel = null;
      } else {
        //convert the headers to arrays
        const newTableHeaders = prompt.split('\n');
        const prevTableHeaders = variable.prompt?.split('\n') || [];

        const prevTableColumns = variable.columns;

        const updatedColumns = [];
        newTableHeaders?.forEach((header) => {
          if (header) {
            const prevTableColumnHeader = prevTableColumns.find((col) => col.id === header);
            if (prevTableColumnHeader) {
              updatedColumns.push(prevTableColumnHeader);
            } else {
              const newTableColumn = new TableColumn(
                {
                  id: sanitizeColumn(header),
                  displayName: header,
                  valueType: ValueType.STRING,
                  editable: true,
                },
                variable
              );
              updatedColumns.push(newTableColumn);
            }
          }
        });

        variable.columns = updatedColumns;
        variable.valueType = valueType;
        variable.displayName = displayName;

        //temporary object to store changed row objects
        //We can use the originalValues because at this point they are the exact same.
        let updatedTableValue = variable.val;

        //loop through the keys (headers)
        _.forEach(newTableHeaders, (header, index) => {
          let prevHeader = prevTableHeaders[index];
          //if the keys (headers) don't match swap the old key value pair with the new key value pair.
          if (header !== prevHeader) {
            //since there can be multiple columns we need to loop through each column and update the key value pair
            _.forEach(updatedTableValue, (row) => {
              //set the new column name to have the value of the old column name
              row[header] = row[prevHeader];
              //delete the old key value pair
              delete row[prevHeader];
            });
          }
        });

        variable.value = JSON.stringify(updatedTableValue);
      }
      await Fire.saveVariableDefinition(deal, variable.json);
    }
    this.setState({
      editingColumns: false,
    });
  }

  async save(e) {
    const { deal, creating, onSave, hide, displayToName } = this.props;
    const {
      assigned,
      property,
      textTransform,
      pluralName,
      multiline,
      value,
      type,
      formula,
      showTotalRow,
      totalRowLabel,
      addressDisplayProperty,
      imageAttachment,
      extract,
      extractType,
      extractionInstructions,
      redact,
      redactionExtent,
      redactionMethod,
      redactionValue,
      language,
    } = this.state;

    const { template } = deal;

    if (e) e.stopPropagation();

    // We should re-validate since Validator has a debouce and users could still try to
    // save an invalid value by hitting return. Also this solidify the 'inline' version.
    const isValueValid = this.validate();
    if (!isValueValid) {
      return;
    }

    this.setState({ calcError: null });
    const v = this.getVariable(this.props);

    if (type === VariableType.CALCULATED) {
      if (this.validateFormula()) {
        if (v && formula) {
          try {
            v.calculate(formula);
          } catch (e) {
            if (e.code !== 'ref') {
              //Allow editor to target vars not yet in document
              this.setState({ calcError: e });
              return;
            }
          }
        }
      }
    }

    //take all savable variable properties from state
    const variable = _.pick(this.state, SAVEABLE_VAR_FIELDS);

    if (variable.valueType === ValueType.MULTI_SELECT) {
      variable.value = _.map(value, 'value').join('\n');
    }

    if (template && template.documentAI) {
      variable.extract = extract;
      variable.extractType = extract && !extractType ? EXTRACT_TYPE.STRUCTURED : extractType;
      variable.extractionInstructions = extractionInstructions.length > 0 ? extractionInstructions : null;
    }

    if (variable.variants.length > 0) {
      variable.variants = variable.variants.replace(/\n/g, '|');
    }

    // Are we switching variable type? These value types have a unique shape/schema
    if (v && v.valueType !== variable.valueType) {
      // From image
      if (v.valueType === ValueType.IMAGE) this.removeImageAttachment();

      // To image
      if (variable.valueType === ValueType.IMAGE) {
        variable.value = '';
      }

      // From Table
      if (v.valueType === ValueType.TABLE) variable.columns = '';
    }

    //this.clean function is Fire.js prevents us from storing '' so we need this to differentiate
    //between a null values and an empty string value due to different UX behaviors for differnt initial states.
    if (variable.totalRowLabel === '') {
      variable.totalRowLabel = ' ';
    }

    variable.redactionExtent = redact ? redactionExtent : null;
    variable.redactionMethod = redact ? redactionMethod : null;
    variable.redactionValue = redact ? redactionValue : null;

    if (redact) {
      variable.autoPush = false;
    }

    if (variable.decimals === 'Default') variable.decimals = null;

    if (variable.type === VariableType.CONNECTED) {
      if (this.isShowTotalOrTotalLabelUpdated()) {
        const variable = this.getVariable(this.props);
        if (variable) {
          variable.showTotalRow = showTotalRow;
          variable.totalRowLabel = totalRowLabel === '' ? ' ' : totalRowLabel;

          await Fire.saveVariableDefinition(deal, variable.json);
          hide();
          return;
        }
      }

      //build text version of variable to pass back to editor
      if (typeof onSave === 'function') {
        let newText = `[${variable.type}${variable.name}`;
        if (!creating && property != 'none') {
          newText += `.${property}`;
        }
        if (!creating && textTransform != 'none') {
          newText += `.${textTransform}`;
        }
        newText += ']';

        onSave(newText, creating);
        hide();
        return;
      }

      if (assigned && variable.assigned !== assigned) {
        const variable = this.getVariable(this.props);

        if (variable) {
          variable.assigned = assigned;

          await Fire.saveVariableDefinition(deal, variable.json);
          hide();
          return;
        }
      }
    }

    // Save optional pluralName property on Party vars if specified
    if (pluralName) variable.pluralName = pluralName;

    if (variable.type != VariableType.SIMPLE) {
      delete variable.valueType;
      delete variable.multiline;
    }

    if ([VariableType.PROTECTED, VariableType.SIMPLE].includes(variable.type)) {
      variable.assigned = assigned || null;
    }

    // Save multiline default value as string array
    if (variable.valueType === ValueType.STRING && multiline) {
      variable.value = value ? value.split('\n') : null;
    }

    // Protected elements need to be saved via API because they get encrypted
    if (variable.type == VariableType.PROTECTED) {
      const value = variable.value;
      //delete from the variable object so that it doesn't get overwritten with unencrypted version in Firebase call
      delete variable.value;
      this.setState({ revealing: true });
      API.call('setProtected', { dealID: deal.dealID, value, varName: variable.name }, ({ crypted }) => {
        if (this._isMounted) this.setState({ revealing: false, value: crypted || '' });
      });
    }

    if (redact && variable.redactedValue) {
      variable.value = variable.redactedValue;
    }

    // Only rename (replace) the variable if it is a PDFDeal, displayToName was enabled and the name did change
    const originalVariable = this.getVariable(this.props);
    let sectionsUpdated = [];

    //When we update a table header we want to replace the value and header key in each row with the previous content.
    //Do not run this action if originalVariable prompt doesn't exist. There is nothing to map column values to.
    if (
      originalVariable &&
      originalVariable.prompt &&
      variable.valueType === ValueType.TABLE &&
      originalVariable.valueType === ValueType.TABLE &&
      variable.prompt !== originalVariable.prompt
    ) {
      //if the updated table column headers are cleared completely reset the table values because we cannot map which allows for those key:value pairs to be saved in the variable.value json.
      if (!variable.prompt) {
        variable.value = null;
        variable.columns = null;
      } else {
        //convert the headers to arrays
        const newTableHeaders = variable.prompt.split('\n');
        const prevTableHeaders = originalVariable.prompt.split('\n');

        const prevTableColumns = originalVariable.columns;

        const updatedColumns = [];
        newTableHeaders?.forEach((header) => {
          const prevTableColumnHeader = prevTableColumns.find((col) => col.id === header);
          if (prevTableColumnHeader) {
            updatedColumns.push(prevTableColumnHeader);
          } else {
            const newTableColumn = new TableColumn(
              {
                id: header,
                displayName: header,
                valueType: ValueType.STRING,
                editable: true,
              },
              originalVariable
            );
            updatedColumns.push(newTableColumn);
          }
        });

        variable.columns = _.map(updatedColumns, 'json');

        //temporary object to store changed row objects
        //We can use the originalValues because at this point they are the exact same.
        let updatedTableValue = originalVariable.val;

        //loop through the keys (headers)
        _.forEach(newTableHeaders, (header, index) => {
          let prevHeader = prevTableHeaders[index];
          //if the keys (headers) don't match swap the old key value pair with the new key value pair.
          if (header !== prevHeader) {
            //since there can be multiple columns we need to loop through each column and update the key value pair
            _.forEach(updatedTableValue, (row) => {
              //set the new column name to have the value of the old column name
              row[header] = row[prevHeader];
              //delete the old key value pair
              delete row[prevHeader];
            });
          }
        });

        variable.value = JSON.stringify(updatedTableValue);
      }
    }

    if (deal instanceof PDFDeal && displayToName && originalVariable && originalVariable.name !== variable.name) {
      await Fire.renameVariable(deal, variable, originalVariable.name);
    } else if (originalVariable && originalVariable.name !== variable.name) {
      sectionsUpdated = await Fire.renameVariable(deal, variable, originalVariable.name);
      await Fire.saveVariableDefinition(deal, variable);
    } else if (variable.valueType === ValueType.IMAGE) {
      if (imageAttachment) {
        // If we have imageAttachment it is because we're just uploaded an image, let's save it.
        variable.value = await this.createImageAttachment(imageAttachment);
      } else if (!variable.value && !imageAttachment) {
        // If we have neither a value or an imageAttachment it means we've cleared the image, let's delete it.
        await this.removeImageAttachment();
      }

      await Fire.saveVariableDefinition(deal, variable);
    } else {
      await Fire.saveVariableDefinition(deal, variable);
    }

    // If renaming the variable changed any section content (which it probably did)
    // We need to also propagate that change through the Clause Library
    _.forEach(sectionsUpdated, (section) => {
      if (!section.shouldSyncWithCL) {
        const args = {
          originCL: `${deal.dealID}|${section.id}`,
          title: section.displayname || null,
          body: section.content || null,
          teamID: section.deal.team,
        };
        API.call('updateLinkedCL', args);
      }
    });

    //build text version of variable to pass back to editor
    if (typeof onSave === 'function') {
      let newText = `[${variable.type}${variable.name}`;
      if (!creating && property != 'none') {
        newText += `.${property}`;
      }
      if (!creating && textTransform != 'none') {
        newText += `.${textTransform}`;
      }
      if (variable.type === VariableType.PARTY && property === 'address' && addressDisplayProperty != 'none') {
        newText += `.${addressDisplayProperty}`;
      }
      newText += ']';
      onSave(newText, creating);
    }

    hide();
  }

  cancel(e) {
    if (e) e.stopPropagation();
    this.props.hide();
  }

  focus() {
    let el;
    if (this.state.type == '$' || !this.props.creating) {
      if (this.displayNameRef.current) {
        el = ReactDOM.findDOMNode(this.displayNameRef.current);
        if (el) el.focus();
      }
    } else {
      if (this.nameRef.current) {
        el = ReactDOM.findDOMNode(this.nameRef.current);
        if (el) el.focus();
      }
    }
  }

  onImage(imageAttachment) {
    if (imageAttachment) {
      const base64 = Buffer.from(imageAttachment.attachmentBytes).toString('base64');
      const encodedBase64 = `data:image/${imageAttachment.attachment.extension};base64,${base64}`;
      this.setState({ value: encodedBase64, imageAttachment });
    } else {
      this.setState({ value: '', imageAttachment });
    }
  }

  async removeImageAttachment() {
    const { deal } = this.props;
    const variable = this.getVariable(this.props);
    if (variable) {
      const { value } = variable;

      //only remove the attachment if its not legacy base64 string.
      if (variable.hasAttachment) {
        const imageAttachment = _.find(deal.attachments, { key: value.key });
        await Fire.deleteAttachment(imageAttachment);
      }
    }
  }

  async createImageAttachment() {
    const { deal } = this.props;
    const { attachment, attachmentBytes } = this.state.imageAttachment;
    const updatedAttachment = await Fire.saveAttachment(attachment, attachmentBytes);
    await API.call('syncDealPermissions', { dealID: deal.dealID });
    const { key, bucketPath } = updatedAttachment;
    const downloadURL = await Fire.storage.ref(bucketPath).getDownloadURL();
    return { key, downloadURL };
  }

  onShowTotalRowChange() {
    this.setState((prevState) => ({
      showTotalRow: !prevState.showTotalRow,
    }));
  }

  onColumnsPromptKeypress(e) {
    const key = String.fromCharCode(e.which);

    //Allow space and enter
    if (!['\r', ' '].includes(key) && new RegExp(DISALLOWED_CHARS).test(key)) {
      //Instantiate the regex here or else it only works ever other time https://stackoverflow.com/questions/3891641/regex-test-only-works-every-other-time
      this.setState({ tableColumnWarning: `Character "${key}" is not allowed.` });
      clearTimeout(this.tableErrorTimer);
      this.tableErrorTimer = setTimeout(() => {
        this._isMounted && this.setState({ tableColumnWarning: null });
      }, 5000);
      e.preventDefault();
    }
  }

  onColumnsPromptChange(e) {
    const variable = this.getVariable(this.props);
    const exisitingColNamesPrompt = _.map(variable?.columns, (tc) => `${tc.id?.replace('_null', '')}`).join('\n');
    const newPrompt = e.target.value;

    this.setState({
      prompt: newPrompt,
      editingColumns: exisitingColNamesPrompt !== newPrompt,
    });
  }

  cancelVariableColumnsPrompt() {
    const variable = this.getVariable(this.props);
    const exisitingColNamesPrompt = _.map(variable.columns, (tc) => `${tc.id?.replace('_null', '')}`).join('\n');
    this.setState({
      prompt: exisitingColNamesPrompt,
      editingColumns: false,
    });
  }

  render() {
    const { container, target, hide, rootClose, placement = 'bottom' } = this.props;

    return (
      <Overlay
        container={container}
        onEntered={this.focus}
        onHide={hide}
        placement={placement}
        rootClose={rootClose}
        show={true}
        target={target}
      >
        <Popover className="popover-variable-editor" id={`${this.id}-pop`} data-cy="popover-variable-editor">
          {this.renderEditor()}
        </Popover>
      </Overlay>
    );
  }

  handleDateExtent(e) {
    const { redactionExtent } = this.state;
    let extent = e.target.value;

    if (extent === REDACTION_EXTENTS.ENTIRE_DATE) {
      this.setState({ redactionExtent: REDACTION_EXTENTS.ENTIRE_DATE });
      return;
    }

    let extents = redactionExtent.split(',').filter((e) => e !== REDACTION_EXTENTS.ENTIRE_DATE);

    //If found, remove and vice-versa
    if (extents.includes(extent)) {
      extents = extents.filter((e) => e !== extent);
    } else {
      extents.push(extent);
    }

    if (
      [REDACTION_EXTENTS.REDACT_MONTH, REDACTION_EXTENTS.REDACT_DAY, REDACTION_EXTENTS.REDACT_YEAR].every((e) =>
        extents.includes(e)
      )
    )
      extents = [];

    extent = extents.join(',');

    this.setState({ redactionExtent: extent || REDACTION_EXTENTS.ENTIRE_DATE });
  }

  renderRedactionSettings() {
    const { redact, redactionExtent, redactionMethod, redactionValue, valueType } = this.state;

    if (![ValueType.STRING, ValueType.NUMBER, ValueType.DATE, ValueType.IMAGE].includes(valueType)) return;

    return (
      <>
        <FormGroup id="redact">
          <Switch
            checked={redact}
            id={`${this.id}-chk-redact`}
            onChange={() =>
              this.setState({
                redact: !redact,
                redactionExtent: !redact
                  ? valueType === ValueType.DATE
                    ? REDACTION_EXTENTS.ENTIRE_DATE
                    : REDACTION_EXTENTS.ENTIRE_VALUE
                  : null,
                redactionMethod: !redact ? REDACTION_METHODS.BLACKOUT : null,
              })
            }
            size="small"
            data-cy="chk-redact"
          >
            Redact value
          </Switch>
        </FormGroup>
        {redact && (
          <>
            <FormGroup className="radio-group">
              <div className="control-label">Redaction extent</div>
              {valueType !== ValueType.DATE && (
                <Radio
                  name="redact-extent"
                  checked={redactionExtent === REDACTION_EXTENTS.ENTIRE_VALUE}
                  onChange={() => this.setState({ redactionExtent: REDACTION_EXTENTS.ENTIRE_VALUE })}
                  value={REDACTION_EXTENTS.ENTIRE_VALUE}
                >
                  Redact entire value
                </Radio>
              )}
              {valueType === ValueType.DATE && (
                <>
                  <Checkbox
                    name="redact-entire-date"
                    checked={redactionExtent === REDACTION_EXTENTS.ENTIRE_DATE}
                    onChange={this.handleDateExtent}
                    value={REDACTION_EXTENTS.ENTIRE_DATE}
                  >
                    Redact entire date
                  </Checkbox>
                  <Checkbox
                    name="redact-day"
                    checked={redactionExtent.includes(REDACTION_EXTENTS.REDACT_DAY)}
                    onChange={this.handleDateExtent}
                    value={REDACTION_EXTENTS.REDACT_DAY}
                  >
                    Redact day
                  </Checkbox>
                  <Checkbox
                    name="redact-month"
                    checked={redactionExtent.includes(REDACTION_EXTENTS.REDACT_MONTH)}
                    onChange={this.handleDateExtent}
                    value={REDACTION_EXTENTS.REDACT_MONTH}
                  >
                    Redact month
                  </Checkbox>
                  <Checkbox
                    name="redact-year"
                    checked={redactionExtent.includes(REDACTION_EXTENTS.REDACT_YEAR)}
                    onChange={this.handleDateExtent}
                    value={REDACTION_EXTENTS.REDACT_YEAR}
                  >
                    Redact year
                  </Checkbox>
                </>
              )}
              {[ValueType.NUMBER, ValueType.STRING].includes(valueType) && (
                <Radio
                  name="redact-extent"
                  checked={redactionExtent === REDACTION_EXTENTS.SHOW_LAST_4}
                  onChange={() => this.setState({ redactionExtent: REDACTION_EXTENTS.SHOW_LAST_4 })}
                  value={REDACTION_EXTENTS.SHOW_LAST_4}
                >
                  {valueType === ValueType.NUMBER ? 'Redact all but last 4 digits' : 'Redact all but last 4 characters'}
                </Radio>
              )}
            </FormGroup>
            <FormGroup className="radio-group">
              <div className="control-label">Redaction method</div>
              <Radio
                name="redaction-method"
                checked={redactionMethod === REDACTION_METHODS.BLACKOUT}
                onChange={() => this.setState({ redactionMethod: REDACTION_METHODS.BLACKOUT })}
              >
                Blackout
              </Radio>
              <Radio
                name="redaction-method"
                checked={redactionMethod === REDACTION_METHODS.CHARACTER_REPLACEMENT}
                onChange={() =>
                  this.setState({
                    redactionMethod: REDACTION_METHODS.CHARACTER_REPLACEMENT,
                    redactionValue: redactionValues[0].value,
                  })
                }
              >
                Character replacement
              </Radio>
              {valueType !== ValueType.DATE && (
                <Radio
                  name="redaction-method"
                  checked={redactionMethod === REDACTION_METHODS.CUSTOM_TEXT}
                  onChange={() =>
                    this.setState({
                      redactionMethod: REDACTION_METHODS.CUSTOM_TEXT,
                      redactionValue: redactionValues[0].value,
                    })
                  }
                >
                  {valueType === ValueType.IMAGE ? 'Custom text overlay (optional)' : 'Custom text'}
                </Radio>
              )}
              {redactionMethod === REDACTION_METHODS.CUSTOM_TEXT && (
                <>
                  <div className="control-label">Redaction extent</div>
                  <FormControl
                    bsSize="small"
                    type="text"
                    placeholder="e.g. [REDACTED]"
                    value={redactionValue || ''}
                    onChange={(e) => this.setState({ redactionValue: e.target.value })}
                    data-cy="input-variable-redaction-value"
                  />
                </>
              )}
              {redactionMethod === REDACTION_METHODS.CHARACTER_REPLACEMENT && (
                <>
                  <div className="control-label">Character</div>
                  <Dropdown
                    id={`${this.id}-dd-replacement-character`}
                    title={redactionValues.find((v) => v.value === redactionValue)?.title || redactionValues[0].title}
                    onSelect={(redactionValue) => this.setState({ redactionValue })}
                    size="small"
                    block
                    data-cy="dd-replacement-character"
                  >
                    {redactionValues.map((t, idx) => (
                      <MenuItem key={idx} eventKey={t.value} active={t.value === redactionValue}>
                        {t.title}
                      </MenuItem>
                    ))}
                  </Dropdown>
                </>
              )}
            </FormGroup>
          </>
        )}
      </>
    );
  }

  renderEditor() {
    const {
      deal,
      creating,
      showPrompt,
      displayToName,
      showValue,
      showLabels,
      showPropertySelector,
      showTextTransformSelector,
      hide,
      variableIndex,
    } = this.props;
    const {
      assigned,
      name,
      displayName,
      pluralName,
      type,
      valueType,
      property,
      addressDisplayProperty,
      textTransform,
      revealing,
      isValueValid,
      renaming,
      multiline,
      formula,
      vsState,
      variants,
      seperator,
      extract,
      extractType,
      extractionInstructions,
      redact,
      decimals,
      calcError,
      language,
    } = this.state;

    const isRef = type === VariableType.REF;
    const isParty = type === VariableType.PARTY;
    const isSimple = type === VariableType.SIMPLE;
    const isConnected = type === VariableType.CONNECTED;
    const isTerm = type == VariableType.TERM;
    const isFootnote = type === VariableType.FOOTNOTE;
    const isProtected = type === VariableType.PROTECTED;
    const isCalculated = type === VariableType.CALCULATED;
    const isList = isSimple && valueType == ValueType.LIST;
    const isImage = isSimple && valueType == ValueType.IMAGE;
    const isDate = isSimple && valueType == ValueType.DATE;
    const isText = isSimple && valueType == ValueType.STRING;
    const isContact = isSimple && valueType == ValueType.CONTACT;
    const isMultiSelect = isSimple && valueType == ValueType.MULTI_SELECT;
    const isTable = (isSimple || isConnected) && valueType == ValueType.TABLE;
    const displayType = VariableKey[type];
    const displayValueType = _.find(SimpleTypeLabels, { data: valueType });
    const { template } = deal;
    const canExtract = !isImage && !isTable && !isContact && !isMultiSelect;

    let properties = [];
    if (isParty) properties = PARTY_PROPERTIES;
    if ((isSimple || isConnected) && [ValueType.NUMBER, ValueType.CURRENCY].indexOf(valueType) > -1)
      properties = NUMBER_PROPERTIES;
    if (isDate) {
      properties = _.filter(DATE_PROPERTIES, (prop) => {
        if (language?.key !== 'english' && !prop.langSupport) return;
        else return prop;
      });
    }

    const propertyTitle = _.get(_.find(properties, { data: property }), 'label', '');
    const textTransformTitle = _.get(_.find(TEXT_TRANSFORMS, { data: textTransform }), 'label', '');
    const variable = this.getVariable(this.props) || {};
    let calcValue;

    try {
      if (calcError) {
        calcValue = calcError;
      } else {
        variable.calculate(variable.formula);
      }
    } catch (e) {
      calcValue = e;
    }

    return (
      <Form data-cy="variable-editor">
        {!displayToName && !isRef && !deal.inDraft && (
          <FormGroup className="id">
            {creating || renaming ? (
              <FormControl
                ref={this.nameRef}
                bsSize="small"
                type="text"
                placeholder={`${this.typeDisplayName} ID`}
                value={name}
                onChange={(e) => this.handleChange(e, 'name')}
                data-cy="input-variable-id"
              />
            ) : (
              <TooltipButton tip="Click to rename" disabled={isConnected || isFootnote}>
                <Ellipsis>
                  <span
                    className={cx('element-name', displayType, { connected: isConnected })}
                    onClick={() => (isConnected || isFootnote ? null : this.setState({ renaming: true }))}
                  >
                    {isFootnote ? `Footnote (${variable.ftNumber})` : name}
                  </span>
                </Ellipsis>
              </TooltipButton>
            )}
          </FormGroup>
        )}
        {isConnected && (
          <VariableConnector
            id={`${this.id}-var-connector`}
            variable={variable.baseVariable}
            variableIndex={variableIndex}
            onResolve={() => hide()}
            disablePush={redact}
          />
        )}
        {!displayName && extract && (
          <Alert dmpStyle="danger" className="alert" size="xsmall">
            <b>Display name</b> is required in order for AI-extraction to work properly. If you do not set this, the
            variable will not be extracted.
          </Alert>
        )}
        {!displayToName && !isConnected && !isFootnote && (
          <FormGroup className="display">
            <FormControl
              bsSize="small"
              type="text"
              value={displayName}
              ref={this.displayNameRef}
              placeholder={isRef ? "Prefix, e.g., 'Section'" : 'Display name'}
              onChange={(e) => this.handleChange(e, 'displayName')}
              data-cy="input-variable-name"
              className={cx('displayName', { error: !displayName && extract })}
            />
          </FormGroup>
        )}
        {isParty && (
          <FormGroup className="pluralName">
            <FormControl
              bsSize="small"
              type="text"
              value={pluralName}
              placeholder={`Plural name, e.g., ${plural(displayName || 'Party')}`}
              onChange={(e) => this.handleChange(e, 'pluralName')}
            />
          </FormGroup>
        )}
        {displayToName && (
          <FormGroup className="display">
            <FormControl
              bsSize="small"
              type="text"
              value={displayName || name}
              ref={this.displayNameRef}
              placeholder="Name"
              onChange={(e) => this.handleChange(e, 'displayName')}
            />
          </FormGroup>
        )}
        {isCalculated && (
          <FormGroup className="dmp-validator-container formula">
            <div className="control-label">Formula</div>
            <FormControl
              ref={this.refFormula}
              componentClass="textarea"
              bsSize="small"
              type="text"
              value={formula}
              placeholder="Enter formula"
              onChange={(e) => this.handleChange(e, 'formula')}
              onKeyDown={this.handleVS}
              onKeyUp={this.updateVariableSuggest}
              data-cy="variable-formula"
            />
            {(variable.formula && variable.isBroken) ||
              (calcError && (
                <>
                  <br />
                  <Alert dmpStyle="danger">
                    {calcValue && calcValue.message && calcValue.value ? (
                      <>
                        <b>{calcValue.message}:</b> {calcValue?.value}
                      </>
                    ) : (
                      calcValue?.message || 'There is an error'
                    )}
                  </Alert>
                </>
              ))}
            {vsState && (
              <VariableSuggest
                ref={this.refSuggest}
                variableIndex={variableIndex}
                deal={deal}
                input={vsState.input}
                onSelect={this.commitVariableSuggestion}
                target={this.refFormula}
                variableTypes={[VariableType.SIMPLE, VariableType.CALCULATED, VariableType.CONNECTED]}
                isFormula
              />
            )}
            {!vsState && (
              <Validator
                value={formula}
                validate={this.validateFormula}
                onResult={(isFormulaValid) => this.setState({ isFormulaValid })}
                debounce={100}
                invalidTip="Invalid formula"
              />
            )}

            <DecimalSelector
              id={`${this.id}-dd-prop-decimal-places`}
              onSelect={(decimals) => this.setState({ decimals })}
              title={decimals}
            />
          </FormGroup>
        )}
        {isSimple && (
          <FormGroup className="val-type">
            {showLabels && <ControlLabel>Type</ControlLabel>}

            <Dropdown
              id={`${this.id}-dd-valtype`}
              className="dd-valtype"
              title={displayValueType.label}
              onSelect={this.handleTypeChange}
              size="small"
              block
              data-cy="dd-val-type"
            >
              {SimpleTypeLabels.map((t, idx) => (
                <MenuItem key={idx} eventKey={t.data} active={t.data === displayValueType.data}>
                  {t.label}
                </MenuItem>
              ))}
            </Dropdown>

            {isText && (
              <Switch
                checked={multiline}
                id={`${this.id}-chk-multiline`}
                onChange={() => this.setState({ multiline: !multiline })}
                size="small"
                data-cy="chk-multiline"
              >
                Multiline input
              </Switch>
            )}
          </FormGroup>
        )}
        {isDate && (
          <FormGroup>
            <Dropdown
              size="small"
              block
              id={`language-dd`}
              className="dd-prop"
              onSelect={(language) => {
                this.setState({ language });
              }}
              title={language ? language.title : 'Language Format'}
              data-cy="variable-prop"
            >
              {DATE_LANGUAGES.map((p, idx) => (
                <MenuItem key={p.key} eventKey={p}>
                  {p.title}
                </MenuItem>
              ))}
            </Dropdown>
          </FormGroup>
        )}
        {showPrompt && (isSimple || isProtected || isTable) && (
          <FormGroup
            className={cx('placeholder', { options: isList }, { columns: isTable }, { connected: isConnected })}
          >
            {!isImage && this.renderPrompt()}
          </FormGroup>
        )}
        {valueType === ValueType.MULTI_SELECT && (
          <FormGroup className="seperator">
            <ControlLabel>Seperator</ControlLabel>
            <Dropdown
              id="seperator"
              className="dd-valtype"
              title={seperator}
              onSelect={(seperator) => this.setState({ seperator })}
              size="small"
              block
            >
              {SEPERATORS.map((sep, idx) => (
                <MenuItem key={idx} eventKey={sep} active={seperator === sep}>
                  {sep}
                </MenuItem>
              ))}
            </Dropdown>
          </FormGroup>
        )}
        {showValue && (isSimple || isProtected || isFootnote) && (
          <FormGroup className={`default${isTerm ? ' definition' : ''}`}>
            {this.renderValueField()}
            {revealing && <Loader />}
          </FormGroup>
        )}
        {isRef && this.renderRefSelector()}
        {(isSimple || isProtected || this.canUpdateConnected()) && !deal.inDraft && !variable.isBroken && (
          <FormGroup className="assignee">
            <div className="control-label">Assigned party</div>
            <PartySelector
              id={`${this.id}-dd-party`}
              size="small"
              block
              deal={deal}
              assigned={assigned}
              onSelect={(assigned) => this.setState({ assigned })}
            />
          </FormGroup>
        )}
        {showPropertySelector && !creating && properties.length > 0 && !isCalculated && (
          <>
            <FormGroup>
              <div className="control-label">Instance overrides</div>
              <Dropdown
                size="small"
                block
                id={`${this.id}-dd-prop`}
                className="dd-prop"
                onSelect={(property) => {
                  this.setState({ property });
                }}
                title={propertyTitle}
                data-cy="variable-prop"
              >
                {properties.map((p, idx) => (
                  <MenuItem key={idx} eventKey={p.data} info={isDate ? p.description(language.code) : p.description}>
                    {p.label}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
            {isParty && property === 'address' && (
              <FormGroup>
                <Dropdown
                  size="small"
                  block
                  id={`${this.id}-dd-address-prop`}
                  className="dd-prop address-properties-menu-options"
                  onSelect={(addressDisplayProperty) => this.setState({ addressDisplayProperty })}
                  title={_.get(_.find(ADDRESS_PROPERTIES, { data: addressDisplayProperty }), 'label', '')}
                  data-cy="address-properties-menu-options"
                >
                  {ADDRESS_PROPERTIES.map((p, idx) => (
                    <MenuItem key={idx} eventKey={p.data} info={p.description}>
                      {p.label}
                    </MenuItem>
                  ))}
                </Dropdown>
              </FormGroup>
            )}
          </>
        )}
        {!isImage &&
          !isTable &&
          !isParty &&
          !isFootnote &&
          showTextTransformSelector &&
          !creating &&
          !isCalculated &&
          TEXT_TRANSFORMS.length > 0 && (
            <FormGroup>
              {!showPropertySelector ||
                creating ||
                (!properties.length > 0 && <div className="control-label">Instance overrides</div>)}
              <Dropdown
                size="small"
                block
                id={`${this.id}-dd-prop`}
                className="dd-prop transform-menu-options"
                onSelect={(textTransform) => this.setState({ textTransform })}
                title={textTransformTitle}
                dataCyToggle="variable-prop-transform"
              >
                {TEXT_TRANSFORMS.map((p, idx) => (
                  <MenuItem key={idx} eventKey={p.data} data-cy="variable-transform-option">
                    {p.label}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
          )}
        {isConnected && template && !showPropertySelector && this.renderRedactionSettings()}
        {template && template.documentAI && isSimple && canExtract && (
          <FormGroup className="variants">
            <div className="extract-container">
              <span className="extract-icon">
                <Icon name="aiAuto" />
                Use AI-extracted value
              </span>
              <TooltipButton tip="Variable display name required" disabled={displayName || (!displayName && extract)}>
                <Switch
                  id="chk-extracr"
                  checked={this.state.extract}
                  onChange={() => this.setState({ extract: !this.state.extract })}
                  className="extract-switch"
                  size="small"
                  disabled={!displayName && !extract}
                  data-cy="extract-switch"
                />
              </TooltipButton>
            </div>
            {this.state.extract && (
              <div className="extraction-type" data-cy="extraction-type">
                <div className="control-label">Data Type</div>
                <span className="radio-container">
                  <Radio
                    checked={extractType === EXTRACT_TYPE.STRUCTURED || !extractType}
                    name="StructuredExtraction"
                    onChange={(e) => this.setState({ extractType: e.target.value ? EXTRACT_TYPE.STRUCTURED : null })}
                    value={extractType === EXTRACT_TYPE.STRUCTURED}
                    data-cy="structured-extraction"
                  >
                    Structured
                  </Radio>
                  <TooltipButton
                    tip={'Best for clearly formatted inputs such as fields with labels and key-value pairs'}
                  >
                    <Icon name="info" />
                  </TooltipButton>
                </span>
                <span className="radio-container">
                  <Radio
                    checked={extractType === EXTRACT_TYPE.FREEFORM}
                    name="FreeformExtraction"
                    onChange={(e) => this.setState({ extractType: e.target.value ? EXTRACT_TYPE.FREEFORM : null })}
                    value={extractType === EXTRACT_TYPE.FREEFORM}
                    data-cy="freeform-extraction"
                  >
                    Freeform
                  </Radio>
                  <TooltipButton
                    tip={"Best for notes, descriptions, and large blocks of text that don't follow a rigid structure"}
                  >
                    <Icon name="info" />
                  </TooltipButton>
                </span>
              </div>
            )}
            {this.state.extract && (extractType === EXTRACT_TYPE.STRUCTURED || !extractType) && (
              <>
                <div className="control-label">Variants</div>
                <FormControl
                  bsSize="small"
                  componentClass="textarea"
                  value={variants}
                  placeholder="Variants"
                  onChange={(e) => this.handleChange(e, 'variants')}
                />
              </>
            )}
            {this.state.extract && extractType === EXTRACT_TYPE.FREEFORM && (
              <>
                <div className="control-label">Extraction Instructions</div>
                <FormControl
                  bsSize="small"
                  componentClass="textarea"
                  value={extractionInstructions}
                  placeholder="Extraction Instructions"
                  onChange={(e) => this.handleChange(e, 'extractionInstructions')}
                />
              </>
            )}
          </FormGroup>
        )}
        {(!isConnected || this.showUpdateSection()) && (
          <FormGroup className="actions">
            <Button className="cancel" dmpStyle="link" size="small" onClick={this.cancel} data-cy="btn-cancel-var">
              Cancel
            </Button>
            <Button
              className="save"
              disabled={!this.isValid || !isValueValid}
              size="small"
              onClick={this.save}
              data-cy={'btn-save-var'}
            >
              {creating ? 'Save' : 'Update'}
            </Button>
          </FormGroup>
        )}
      </Form>
    );
  }

  renderPrompt() {
    const { onColConfig } = this.props;
    const { type, valueType, prompt, showTotalRow, totalRowLabel, editingColumns, tableColumnWarning } = this.state;
    const variable = this.getVariable(this.props);

    if (valueType === ValueType.LIST || valueType === ValueType.MULTI_SELECT)
      return (
        <FormControl
          className="expandable-input"
          bsSize="small"
          componentClass="textarea"
          value={prompt}
          placeholder="Enter options, 1 per line"
          onChange={(e) => this.handleChange(e, 'prompt')}
        />
      );
    else if (valueType === ValueType.TABLE) {
      const colNames =
        type === VariableType.CONNECTED
          ? _.map(variable.columns, (tc) => `{${tc.id?.replace('_null', '')}}`).join('\n')
          : prompt;

      const replaceString = type === VariableType.CONNECTED ? /[\n\{\}\s]/g : /[\n\s]/g; //Connected tables should allow { and }, otherwise just strip spaces and new lines
      const invalidCharacters = _.uniq(colNames.replaceAll(replaceString, '').match(DISALLOWED_CHARS)).join('');

      return (
        <div className="columns-prompt">
          <div className="control-label">
            <span>Columns</span>
            <Button
              disabled={variable?.valueType !== ValueType.TABLE || (type !== VariableType.CONNECTED && !prompt)}
              dmpStyle="link"
              size="small"
              onClick={() => onColConfig(variable)}
              data-cy="btn-config-columns"
            >
              Configure
            </Button>
          </div>
          <FormControl
            bsSize="small"
            componentClass="textarea"
            disabled={type === VariableType.CONNECTED}
            value={colNames}
            placeholder="Columns not configured"
            onKeyPress={this.onColumnsPromptKeypress}
            onChange={this.onColumnsPromptChange}
            spellCheck="false"
          />

          <div className="edit-actions">
            {type !== VariableType.CONNECTED && tableColumnWarning && (
              <div className="columns-edit warning">
                <Icon name="info" />
                &nbsp;{tableColumnWarning}
              </div>
            )}

            {invalidCharacters && (
              <div className="columns-edit error">
                <Icon name="info" />
                &nbsp;
                {invalidCharacters.length > 1 ? (
                  <>
                    Characters <b>{invalidCharacters}</b> are not allowed
                  </>
                ) : (
                  <>
                    Character <b>{invalidCharacters}</b> is not allowed
                  </>
                )}
              </div>
            )}

            {type !== VariableType.CONNECTED && editingColumns && !invalidCharacters && (
              <div className="columns-edit">
                <div className="columns-edit-save">
                  <Button dmpStyle="link" size="small" onClick={this.cancelVariableColumnsPrompt}>
                    Cancel
                  </Button>
                  <Button className="save-columns" dmpStyle="link" size="small" onClick={this.saveColumnsPrompt}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>

          {variable?.columnsToTotal?.length > 0 && (
            <>
              <Switch
                checked={showTotalRow}
                onChange={this.onShowTotalRowChange}
                size="small"
                data-cy="switch-show-total-row"
              >
                Show total row
              </Switch>
              {showTotalRow && (
                <>
                  <div className="control-label">
                    <span>Total row label</span>
                  </div>
                  <FormControl
                    bsSize="small"
                    type="text"
                    className={cx('total-row-label', 'expandable-input')}
                    componentClass="textarea"
                    placeholder="e.g., Total"
                    value={totalRowLabel}
                    disabled={variable?.columns.length > 0 && variable?.columns[0].totalColumn}
                    onChange={(e) => this.handleChange(e, 'totalRowLabel')}
                    data-cy="input-total-row-label"
                  />
                </>
              )}
            </>
          )}
        </div>
      );
    } else
      return (
        <FormControl
          bsSize="small"
          value={prompt}
          placeholder="Empty placeholder"
          onChange={(e) => this.handleChange(e, 'prompt')}
        />
      );
  }

  renderRefSelector() {
    const { deal } = this.props;
    const { value } = this.state;
    const section = value ? deal.sections[value] : null;
    const canReference = section?.sectiontype === (SectionType.SOURCE || SectionType.APPENDIX);
    const title = section && canReference ? section.displayTOC.replace(/^-+\s/g, '') : 'Select Section';
    const toc = deal.buildTOC();

    return (
      <FormGroup className="col-ref-selector">
        <Dropdown
          size="small"
          id={`${this.id}-dd-ref`}
          onSelect={(value) => this.setState({ value })}
          title={title}
          block
          data-cy="ref-dropdown"
        >
          {_.map(toc, (sec, idx) => {
            const disabled = sec?.sectiontype !== (SectionType.SOURCE || SectionType.APPENDIX);

            return (
              <MenuItem key={idx} eventKey={sec.id} ellipsis className={cx({ disabled })}>
                {sec.displayTOC}
              </MenuItem>
            );
          })}
        </Dropdown>
      </FormGroup>
    );
  }

  renderValueField() {
    const { type, value, valueType, revealing, multiline, prompt } = this.state;
    const { deal } = this.props;
    const isImage = type == VariableType.SIMPLE && valueType == ValueType.IMAGE;
    const isTable = type == VariableType.SIMPLE && valueType == ValueType.TABLE;
    const isMultiSelect = VariableType.SIMPLE && valueType == ValueType.MULTI_SELECT;
    const isFootnote = type == VariableType.FOOTNOTE;
    const variable = this.getVariable(this.props);
    const shouldValidate = variable && variable.hasValidation;

    switch (type) {
      case VariableType.TERM:
        return (
          <FormControl
            bsSize="small"
            componentClass="textarea"
            placeholder="Definition"
            value={value}
            onChange={(e) => this.handleChange(e, 'value')}
          />
        );
      default:
        if (isImage) {
          return <ImageUploader image={value ? value : null} onImage={this.onImage} deal={deal} />;
        } else if (isTable) return <span />;
        else if (isMultiSelect)
          return (
            <FormGroup className="multi-select-options">
              <div className="control-label">Default Selections</div>
              <MultiselectDropdown
                onChange={(value) => this.setState({ value })}
                options={
                  variable
                    ? variable.prompt === prompt
                      ? variable.baseVariable?.options
                      : variable.memoOptions(ValueType.MULTI_SELECT, prompt)
                    : []
                }
                defaultValue={value ? value : []}
              />
            </FormGroup>
          );
        else
          return (
            <FormGroup className={shouldValidate ? 'dmp-validator-container' : null}>
              <FormControl
                bsSize="small"
                type="text"
                className={cx(
                  'variable-value',
                  { 'expandable-input': valueType === ValueType.STRING && !multiline && !isFootnote },
                  {
                    revealing,
                  }
                )}
                componentClass={valueType === ValueType.STRING ? 'textarea' : 'input'}
                placeholder={revealing ? '' : deal.inDraft ? 'Value' : 'Default value'}
                value={revealing ? '' : value}
                onChange={(e) => this.handleChange(e, 'value')}
                data-cy="variable-editor-value"
              />
              {shouldValidate && (
                <Validator
                  value={value}
                  validate={this.validate}
                  onResult={(isValid) => this.setState({ isValueValid: isValid })}
                  debounce={100}
                  invalidTip="Not a valid number value."
                />
              )}
            </FormGroup>
          );
    }
  }
}

export default VariableEditor;
