import * as chrono from 'chrono-node';
import { DateTime } from 'luxon';
import { PDFDocument } from 'pdf-lib';

import { EVENT_TYPES } from '@core/models/TimelineEvent';

export function parseDateString(dateString) {
  const parsedDate = chrono.parseDate(dateString);
  return DateTime.fromJSDate(parsedDate, { zone: 'local' });
}

function tapeLayoutDayFull(opts) {
  return tapeLayoutDay({ ...opts, onlyShowOddDays: false });
}

function tapeLayoutDaySparse(opts) {
  return tapeLayoutDay({ ...opts, onlyShowOddDays: true });
}

function tapeLayoutDay({ camera, pixelsPerDay, originDate, tapeLength, onlyShowOddDays = false }) {
  const padding = {
    dateLabelX: 5,
    dateLabelY: 5,
    tickLabelX: 5,
  };

  const minI = Math.floor(camera.x / pixelsPerDay);
  const maxI = minI + Math.floor(tapeLength / pixelsPerDay) + 1;
  const ticks = [];

  let leftmostMonthTick = null;

  for (let i = minI; i <= maxI; i++) {
    const tickDate = originDate.plus({ days: i });

    const isEven = (num) => num % 2 === 0;
    if (onlyShowOddDays && isEven(tickDate.day)) {
      continue;
    }

    const tick = {
      xPos: i * pixelsPerDay,
      label: `${tickDate.toFormat('d')}`,
    };
    if (tickDate.day === 1) {
      tick['topLabel'] = tickDate.toFormat('LLLL y');
      if (leftmostMonthTick === null) {
        leftmostMonthTick = tick;
      }
    }
    ticks.push(tick);
  }

  const leftDate = originDate.plus({ days: minI });
  let leftLabel = {
    xPos: null, // Currently this is unused
    text: leftDate.toFormat('LLLL y'),
    visible: true,
  };

  if (leftmostMonthTick) {
    const topLabelScreenXPos = leftmostMonthTick.xPos - camera.x;

    // TODO: don't hardcode this threshold?
    if (topLabelScreenXPos < 125) {
      leftLabel.visible = false;
    }

    if (topLabelScreenXPos < padding.dateLabelX - padding.tickLabelX) {
      leftLabel.visible = true;
      leftLabel.text = leftmostMonthTick.topLabel;
      leftmostMonthTick.topLabel = '';
    }
  }

  const tapeLayout = {
    padding,
    ticks,
    leftLabel,
  };
  return tapeLayout;
}

function daysBetween(dateLeft, dateRight) {
  return dateRight.diff(dateLeft, 'days').days;
}

function tapeLayoutMonth({ camera, pixelsPerDay, originDate, tapeLength }) {
  const escapeHatchLimit = 200;

  const padding = {
    dateLabelX: 5,
    dateLabelY: 5,
    tickLabelX: 5,
  };

  const ticks = [];

  const monthScreenXPos = (date) => {
    return daysBetween(originDate, date) * pixelsPerDay - camera.x;
  };

  // Compute the first month tick (on the left) we need to worry about:
  let leftMonth = originDate.set({ day: 1 });
  for (let i = 0; i < escapeHatchLimit && monthScreenXPos(leftMonth) > 0; i++) {
    leftMonth = leftMonth.minus({ month: 1 });
  }
  for (let i = 0; i < escapeHatchLimit && monthScreenXPos(leftMonth) < 0; i++) {
    leftMonth = leftMonth.plus({ month: 1 });
  }
  leftMonth = leftMonth.minus({ month: 1 });

  let currentMonth = leftMonth.set();
  let currentX = daysBetween(originDate, currentMonth) * pixelsPerDay;

  let letfmostYearTick = null;

  for (let i = 0; i < escapeHatchLimit; i++) {
    if (currentX - camera.x > tapeLength) {
      break;
    }
    let tick = {
      xPos: currentX,
      label: currentMonth.toFormat('LLL'),
    };
    if (currentMonth.month === 1) {
      tick['topLabel'] = currentMonth.toFormat('y');
      if (!letfmostYearTick) {
        letfmostYearTick = tick;
      }
    }
    ticks.push(tick);

    let nextMonth = currentMonth.plus({ month: 1 });
    let pixelDistance = daysBetween(currentMonth, nextMonth) * pixelsPerDay;
    currentX += pixelDistance;
    currentMonth = nextMonth;
  }

  let leftLabel = {
    xPos: null, // Currently this is unused
    text: leftMonth.toFormat('y'),
    visible: true,
  };

  if (letfmostYearTick) {
    const topLabelScreenXPos = letfmostYearTick.xPos - camera.x;

    // TODO: don't hardcode threshold
    if (topLabelScreenXPos < 50) {
      leftLabel.visible = false;
    }

    if (topLabelScreenXPos < padding.dateLabelX - padding.tickLabelX) {
      leftLabel.visible = true;
      leftLabel.text = letfmostYearTick.topLabel;
      letfmostYearTick.topLabel = '';
    }
  }

  const tapeLayout = {
    padding,
    ticks,
    leftLabel,
  };
  return tapeLayout;
}

function tapeLayoutYearFull(opts) {
  return tapeLayoutYear({ ...opts, interval: 1 });
}

function tapeLayoutYearEvery2(opts) {
  return tapeLayoutYear({ ...opts, interval: 2 });
}

function tapeLayoutYearEvery5(opts) {
  return tapeLayoutYear({ ...opts, interval: 5 });
}

function tapeLayoutYearEvery10(opts) {
  return tapeLayoutYear({ ...opts, interval: 10 });
}

function tapeLayoutYear({ camera, pixelsPerDay, originDate, tapeLength, interval = 1 }) {
  const escapeHatchLimit = 500;

  const padding = {
    dateLabelX: 5,
    dateLabelY: 5,
    tickLabelX: 5,
  };

  const ticks = [];

  const yearScreenXPos = (date) => {
    return daysBetween(originDate, date) * pixelsPerDay - camera.x;
  };

  // Compute the first year tick (on the left) we need to worry about:
  let leftYear = originDate.set({ month: 1, day: 1 });
  for (let i = 0; i < escapeHatchLimit && yearScreenXPos(leftYear) > 0; i++) {
    leftYear = leftYear.minus({ year: 1 });
  }
  for (let i = 0; i < escapeHatchLimit && yearScreenXPos(leftYear) < 0; i++) {
    leftYear = leftYear.plus({ year: 1 });
  }
  leftYear = leftYear.minus({ year: 1 });

  let currentYear = leftYear.set();
  let currentX = daysBetween(originDate, currentYear) * pixelsPerDay;

  for (let i = 0; i < escapeHatchLimit; i++) {
    if (currentX - camera.x > tapeLength) {
      break;
    }

    if (currentYear.year % interval === 0) {
      ticks.push({
        xPos: currentX,
        label: currentYear.toFormat('y'),
      });
    }

    let nextYear = currentYear.plus({ year: 1 });
    let pixelDistance = daysBetween(currentYear, nextYear) * pixelsPerDay;
    currentX += pixelDistance;
    currentYear = nextYear;
  }

  let leftLabel = {
    xPos: null,
    text: '',
    visible: false,
  };

  const tapeLayout = {
    padding,
    ticks,
    leftLabel,
  };
  return tapeLayout;
}

export function buildTapeLayout({ layout, camera, length }) {
  const pixelsPerDay = layout.timescale * camera.scale;

  let tapeLayoutFunc = tapeLayoutDayFull;
  if (pixelsPerDay < 30.0) {
    tapeLayoutFunc = tapeLayoutDaySparse;
  }
  if (pixelsPerDay < 15.0) {
    tapeLayoutFunc = tapeLayoutMonth;
  }
  if (pixelsPerDay < 1.2) {
    tapeLayoutFunc = tapeLayoutYearFull;
  }
  const every2Thresh = 0.15;
  if (pixelsPerDay < every2Thresh) {
    tapeLayoutFunc = tapeLayoutYearEvery2;
  }
  if (pixelsPerDay < every2Thresh / 2) {
    tapeLayoutFunc = tapeLayoutYearEvery5;
  }
  if (pixelsPerDay < every2Thresh / 4) {
    tapeLayoutFunc = tapeLayoutYearEvery10;
  }

  const originDate = DateTime.fromISO(layout.originDateIso);

  const tapeLayout = tapeLayoutFunc({
    camera,
    pixelsPerDay,
    originDate,
    tapeLength: length,
  });

  return tapeLayout;
}

/**
 *
 * @param {DateTime} originDate
 * @param {DateTime} targetDate
 * @param {number} timescale
 * @returns
 */
function convertDateToWorld(originDate, targetDate, timescale) {
  const diffObj = targetDate.diff(originDate, 'days').toObject();
  const days = diffObj.days;
  return days * timescale;
}

export function buildLayout(events, timescale, funcMeasureTextWidth) {
  // See: TimelineEvent.js for the schema of events.
  //
  // Example event object:
  // {
  //     "id": "c8ed62bd4055",
  //     "status": "AI", -- AI or HUMAN
  //     "date": "2016-03-31",
  //     "type": "MEDICAL",
  //     "summary": "Cynthia Villafuerte undergoes laparoscopic appendectomy with Dr. Kim at Mountainview Hospital.",
  //     "source": "-O1Ss4m664nWwJkDS0_Z"
  // }

  if (!events || events.length === 0) {
    return null;
  }

  const layout = {
    eventBoxes: [],
    timescale,
  };

  // TODO: min/max boxWidth
  const boxWidth = 240; //TODO: configurable

  const boxMargin = 10;

  const textHorizPad = 10;
  const textVertPad = 10;

  // Must sort events by date so the layout and autofit algorithms work properly:
  events = [...events].sort((a, b) => {
    const dateA = parseDateString(a.date).valueOf();
    const dateB = parseDateString(b.date).valueOf();
    return dateA - dateB;
  });

  const originDate = parseDateString(events[0].date);
  if (!originDate || !originDate.isValid) {
    console.error('Failed to parse origin date:', events[0].date);
    return null;
  }
  layout.originDateIso = originDate.toISO();

  const wrapText = (text, width, { fontSize }) => {
    const rawWords = text.replace(/\r/g, '').split(' '); // TODO: could also split on "-", and/or other symbols?
    let words = [];
    for (const word of rawWords) {
      if (word.includes('\n')) {
        const parts = word.split(/(\n)/);
        words.push(...parts);
      } else {
        words.push(word);
      }
    }

    let wordIndex = 0;
    const isAtEnd = () => {
      return wordIndex > words.length - 1;
    };
    const consumeWord = () => {
      return words[wordIndex++];
    };

    const lines = [];
    let line = [];
    while (!isAtEnd()) {
      const word = consumeWord();
      if (word === '\n') {
        lines.push(line.join(' '));
        line = [];
        continue;
      }

      const proposedLine = [...line, word];

      if (funcMeasureTextWidth(proposedLine.join(' '), fontSize) <= width) {
        line = proposedLine;
      } else {
        if (line.length === 0) {
          // Very first word doesn't fit, so we just jam it in for now:
          lines.push(word);
          line = [];
        } else {
          // Word doesn't fit on current line, so finish the line and start a new
          // one with the word:
          lines.push(line.join(' '));
          line = [word];
        }
      }
    }
    if (line.length > 0) {
      // Don't forget to include the final line
      lines.push(line.join(' '));
    }

    return lines;
  };

  for (const evt of events) {
    const eventDate = parseDateString(evt.date);
    if (!eventDate.isValid) {
      console.error('Failed to parse event date:', evt);
      continue;
    }

    let xPos = convertDateToWorld(originDate, eventDate, timescale);

    const textWidth = boxWidth - textHorizPad * 2;

    const summaryTextLines = wrapText(evt.summary, textWidth, {
      fontSize: 12,
    });

    const summaryTextLineSpacing = 15;
    const textHeight = summaryTextLines.length * summaryTextLineSpacing;

    const boxHeight = textHeight + 35; // TODO: calculate, don't hard-code anything!

    const typeText = EVENT_TYPES[evt.type].title;
    const typeTextFontSize = 10;
    const typeTextXPos = boxWidth - textHorizPad - funcMeasureTextWidth(typeText, typeTextFontSize);

    const box = {
      x: xPos,
      y: 0,
      width: boxWidth,
      height: boxHeight,

      horizPad: textHorizPad,
      vertPad: textVertPad,

      color: EVENT_TYPES[evt.type].color,
      fillColor: EVENT_TYPES[evt.type].fillColor,

      dateText: eventDate.toFormat('L/d/y'),

      typeText,
      typeTextFontSize,
      typeTextXPos,

      summaryTextLines,
      summaryTextLineSpacing,
      summaryTextYOffset: 18, // TODO: compute this?

      textWidth, //TODO: ?
      textHeight,

      // TODO: eventually, shouldn't need this?
      event: { ...evt },
    };

    // We now check if this box collides with any existing boxes. If it does, we slide it down underneath that
    // box, and check again, until there are no more collisions.
    //
    // TODO: make this "box layout" algorithm pluggable so other algos can be swapped in (and preferences).
    // TODO: This algo is extremely bad algorithmic complexity (very SLOW), need to optimize!
    let colliding = false;
    let i = 0;
    do {
      colliding = false;
      for (const otherBox of layout.eventBoxes) {
        if (boxBoxCollision(box, otherBox, boxMargin)) {
          box.y = otherBox.y + otherBox.height + boxMargin;
          colliding = true;
        }
      }

      if (i++ > 10000000) {
        throw new Error('Detected infinite loop in buildLayout() aborted!');
      }
    } while (colliding);

    layout.eventBoxes.push(box);
  }

  return layout;
}

export function computeBoundingBox(layout) {
  // Compute the minimum bounding box that includes all event boxes
  const bb = { x: 0, y: 0, width: 0, height: 0 };
  for (const box of layout.eventBoxes) {
    bb.x = Math.min(bb.x, box.x);
    bb.width = Math.max(bb.width, box.x + box.width);
    bb.y = Math.min(bb.y, box.y);
    bb.height = Math.max(bb.height, box.y + box.height);
  }
  return bb;
}

export function autoTimescale({ layout, targetAspectRatio, events, funcMeasureTextWidth }) {
  if (!layout?.eventBoxes) {
    return layout;
  }
  const DEBUG = false;

  const compareThreshold = 0.001; //TODO: good compare threshold?
  let nudgePercentage = 0.1; //TODO: good starting value? Dynamic based on layout?
  let side = 0;

  for (let i = 0; i < 500; i++) {
    if (DEBUG) console.log('step:', i + 1);

    // * Compute bounding box and its aspect ratio
    const bb = computeBoundingBox(layout);
    const aspect = bb.width / bb.height;
    if (DEBUG) console.log('aspect=', aspect);

    // * Check if aspect ratio is close enough to stop
    if (Math.abs(aspect - targetAspectRatio) <= compareThreshold) {
      if (DEBUG) console.log('aspect close enough, done!');
      break;
    }

    // * Check if we switched "sides":
    const oldSide = side;
    if (aspect > targetAspectRatio) {
      side = 1;
    } else {
      side = -1;
    }
    if (oldSide !== 0 && oldSide !== side) {
      if (DEBUG) console.log('We switched sides, so that means we overshot!');
      nudgePercentage /= 2.0; //TODO: how much to change?
      if (nudgePercentage < 0.0000000001) {
        //TODO: adjust
        if (DEBUG) console.log('Timescale nudgePercentage reached (essentially) zero, so giving up!');
        break;
      }
      if (DEBUG) console.log('Reduced nudgePercentage to:', nudgePercentage);
      // Skip this round and start trying again with this smaller nudge percentage
      continue;
    }

    // * Nudge the timescale in the desired direction
    const nudgeFactor = aspect > targetAspectRatio ? 1 - nudgePercentage : 1 + nudgePercentage;
    if (DEBUG) console.log('nudgeFactor=', nudgeFactor);
    let newTimescale = layout.timescale * nudgeFactor;
    if (DEBUG) console.log('newTimescale=', newTimescale);

    // * Rebuild the layout using the new timescale
    // TODO: we could speed things up by having a special version of buildLayout that skips rebuilding the
    // interiors of the event boxes, but instead just repositions them relative to eachother, will be much
    // much faster than having to redo everything including calling out to pdfkit to do font metrics (that
    // has to be super slow I imagine)
    layout = buildLayout(events, newTimescale, funcMeasureTextWidth);
  }

  if (DEBUG) console.log('exited loop, about to return layout');
  return layout;
}

export function zoomToFit({ layout, width, height }) {
  if (!layout) return;

  // * Calculate bounding box of all event boxes:
  const bb = computeBoundingBox(layout);

  // * Try fitting to width first, if events dont fit then fit to height:
  let newScale = width / bb.width;
  if (bb.height * newScale > height) {
    newScale = height / bb.height;
  }

  return newScale;
}

// Checks if two AABB's (axis aligned bounding box) collide or not.
function boxBoxCollision(boxA, boxB, margin = 0) {
  const topA = boxA.y;
  const bottomA = boxA.y + boxA.height;
  const leftA = boxA.x;
  const rightA = boxA.x + boxA.width;

  const topB = boxB.y;
  const bottomB = boxB.y + boxB.height;
  const leftB = boxB.x;
  const rightB = boxB.x + boxB.width;

  return !(
    leftA >= rightB + margin ||
    rightA <= leftB - margin ||
    bottomA <= topB - margin ||
    topA >= bottomB + margin
  );
}

/**
 * Renders the timevine layout into a PDF document
 * @param {PDFDocument} doc The PDF document to render into
 * @param {object} layout A timevine Layout object, usually created via buildLayout()
 */
export function renderToPDF(
  doc,
  layout,
  camera,
  margin,
  width,
  height,
  tapeLayout,
  tapeLength,
  tapeThickness,
  tapePadding
) {
  if (!layout?.eventBoxes) {
    return;
  }

  // PDFKit Oddities:
  // * Seems like the save/restore mechanism DOES NOT properly restore fonts!
  // * The text wrapping system goes haywire in the complex case of these event
  //   boxes, so I have implemented a custom line-wrap algorithm in the layout step
  //   so that here we can just draw single lines of text (lineBreak: false).

  doc.save();
  doc.font('Helvetica'); //TODO: Font will not be restored! Maybe this is fine?
  doc.rect(margin, margin, width, height).clip();

  // DEBUG BOX
  // doc.save();
  // doc.rect(margin, margin, width, height);
  // doc.stroke('#0F0');
  // doc.restore();
  // ---------

  doc.translate(margin, margin);

  // Draw the tape:
  doc.save();
  doc.moveTo(0, tapeThickness);
  doc.lineTo(tapeLength, tapeThickness);
  doc.stroke('#888');
  doc.restore();

  for (const tick of tapeLayout.ticks) {
    doc.save();
    doc.lineWidth(1.0);
    doc.moveTo(tick.xPos, tapeThickness / 2);
    doc.lineTo(tick.xPos, tapeThickness);
    doc.stroke('#888');
    doc.restore();

    doc.save();
    doc.fillColor('#888');
    doc.fontSize(12);
    doc.text(tick.label, tick.xPos + tapeLayout.padding.tickLabelX, 35, {
      lineBreak: false,
    });
    doc.restore();

    if (tick.topLabel) {
      doc.save();
      doc.fillColor('#888');
      doc.fontSize(12);
      doc.text(tick.topLabel, tick.xPos + tapeLayout.padding.tickLabelX, 5, {
        lineBreak: false,
      });
      doc.restore();
    }
  }

  doc.save();
  doc.translate(0, tapeThickness + tapePadding);

  doc.scale(camera.scale);

  // Draw lines connecting Event Boxes to Tape:
  for (const box of layout.eventBoxes) {
    doc.save();
    doc.lineWidth(1.0);
    doc.moveTo(box.x, box.y);
    doc.lineTo(box.x, -tapePadding / camera.scale);
    doc.stroke(box.color);
    doc.restore();
  }

  // Draw Event Boxes:
  for (const box of layout.eventBoxes) {
    // Draw the box itself:
    doc.save();
    doc.rect(box.x, box.y, box.width, box.height);
    doc.fillAndStroke(box.fillColor, box.color);
    doc.restore();

    // Draw the date:
    doc.save();
    doc.fontSize(10);
    doc.font('Helvetica-Bold');
    doc.text(box.dateText, box.x + box.horizPad, box.y + box.vertPad, {
      lineBreak: false,
    });
    doc.restore();

    // Draw the event type:
    doc.save();
    doc.fontSize(box.typeTextFontSize);
    doc.fillColor(box.color);
    doc.font('Helvetica-Bold');
    doc.text(box.typeText, box.x + box.typeTextXPos, box.y + box.vertPad, {
      lineBreak: false,
    });
    doc.restore();

    // Draw the event summary text:
    doc.save();
    doc.font('Helvetica');
    doc.fontSize(12);
    doc.fillColor('#5a6573'); // TODO: don't hardcode
    let y = box.y + box.vertPad + box.summaryTextYOffset; // TODO: don't hardcode the offset
    let lineSpacing = box.summaryTextLineSpacing; //TODO: calculate via font metrics (line spacing based on font height)
    for (const line of box.summaryTextLines) {
      doc.text(line, box.x + box.horizPad, y, {
        lineBreak: false,
      });
      y += lineSpacing;
    }

    doc.restore();
  }

  doc.restore(); // translate for tape

  doc.restore();
  doc.restore();
}
