import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';

import DealStatus from '@core/enums/DealStatus';
import SectionType, { HEADER_FOOTER_SUB_SECTION_TYPES } from '@core/enums/SectionType';
import Deal from '@core/models/Deal';
import { MEDIA_QUERY_LARGE } from '@core/models/LayoutStyle';
import { LIST_TYPES } from '@core/models/List';
import PDFElement, { ELEMENT_TYPE } from '@core/models/PDFElement';
import Team, { FEATURES as FEATURES_TEAM } from '@core/models/Team';
import { FEATURES as FEATURES_USER } from '@core/models/User';
import { CONNECTED_VAR_FIELDS, SAVEABLE_VAR_FIELDS, ValueType } from '@core/models/Variable';
import { Dt, dt } from '@core/utils/Environment';
import { generateExportURL } from '@core/utils/Generators';

import { Border, Button, ModalConfirm, Numbering } from '@components/dmp';
import ButtonClose from '@components/dmp/ButtonClose';
import ButtonIcon from '@components/dmp/ButtonIcon';
import Dropdown from '@components/dmp/Dropdown';

import DealPreview from '@components/DealPreview';
import DraftFlowSwitcher from '@components/DraftFlowSwitcher/DraftFlowSwitcher';
import EmptyState from '@components/EmptyState';
import FootnoteDisplay from '@components/FootnoteDisplay';
import PDFPreview from '@components/PDFPreview';
import PageBreak from '@components/PageBreak';
import DealBranding from '@components/deal/DealBranding';
import DealSettings from '@components/deal/DealSettings';
import TemplateInfo from '@components/deal/TemplateInfo';
import ConditionsView from '@components/editor/ConditionsView';
import EditorSidebar from '@components/editor/EditorSidebar';
import EditorStatus from '@components/editor/EditorStatus';
import PagesRenderView from '@components/editor/PagesRenderView';
import SectionEditor from '@components/editor/SectionEditor';
import SectionToolbar from '@components/editor/SectionToolbar';
import TableColumnManager from '@components/editor/TableColumnManager';
import TooltipButton from '@components/editor/TooltipButton';
import CaptionSection from '@components/section_types/CaptionSection';
import TemplateFooterHeaderSubSections from '@components/section_types/TemplateFooterHeaderSubSections';
import API from '@root/ApiClient';
import Fire from '@root/Fire';
import VariableIndex from '@root/utils/VariableIndex';

import PDFView from './PDFView';

const MenuItem = Dropdown.MenuItem;

export const SUMMARY_TYPES = [{ type: 'SUMMARY', title: 'Overview', icon: 'overview' }];
export const TEMPLATE_HEADER_TYPES = [
  { type: 'TEMPLATE_HEADER', title: '1-column', icon: '' },
  { type: 'TEMPLATE_HEADER', title: '2-column', icon: '' },
  { type: 'TEMPLATE_HEADER', title: '3-column', icon: '' },
];

export const TEMPLATE_FOOTER_TYPES = [
  { type: 'TEMPLATE_FOOTER', title: '1-column', icon: '' },
  { type: 'TEMPLATE_FOOTER', title: '2-column', icon: '' },
  { type: 'TEMPLATE_FOOTER', title: '3-column', icon: '' },
];

export const SOURCE_TYPES = [
  { header: 'Templated content' },
  { type: 'SOURCE', title: 'Paragraph', icon: 'sectionBody' },
  { type: 'HEADER', title: 'Header', icon: 'sectionHeader' },
  { type: 'CAPTION', title: 'Caption', icon: 'caption' },
  { divider: true },
  { header: 'User-generated' },
  { type: 'LIST', subType: 'BLOCK', title: 'Block', icon: 'block' },
  { type: 'LIST', subType: 'LIST', title: 'List', icon: 'list' },
  {
    type: 'LIST',
    subType: 'AI',
    title: 'Vine AI',
    icon: 'ai',
    test: ({ team }) => team?.isFeatureActive(FEATURES_TEAM.VINE_AI),
  },
  {
    type: 'LIST',
    subType: 'TIMELINE',
    title: 'AI Timeline',
    icon: 'timeline',
    test: ({ team }) =>
      team?.isFeatureActive(FEATURES_TEAM.VINE_AI) && team?.isFeatureActive(FEATURES_TEAM.TIMELINE_AI),
  },
  { divider: true },
  { header: 'Special' },
  { type: 'LIST', subType: 'REPEATER', title: 'Repeater', icon: 'repeater', test: ({ deal }) => deal.isConnected },
  { type: 'APPENDIX', title: 'Inline appendix', icon: 'sectionAppendix' },
  { type: 'APPENDIX', subType: 'LINKED', title: 'Linked appendix', icon: 'link' },
  { type: 'SIGNATURE', title: 'Signature', icon: 'sectionSignature' },
];

const PDFElements = [
  {
    elementType: ELEMENT_TYPE.VARIABLE,
    class: 'variable',
    icon: 'fieldsEdit',
    tip: 'Add variable field',
    display: 'Field',
  },
  {
    elementType: ELEMENT_TYPE.SIGNATURE,
    class: 'signature',
    icon: 'signatureScript',
    tip: 'Add signature field',
    display: 'Signature',
  },
];

const PREVIEW_TYPES = [
  {
    eventKey: 'native',
    title: 'Flow (Web)',
  },
  {
    eventKey: 'pdf',
    title: 'PDF',
  },
];

// Auto-timeout locks after 5 minutes of inactivity
const LOCK_TIMEOUT = 300000;

const OVERVIEW_HEADER_OPTIONS = {
  overview: 'Overview',
  header: 'Header',
  footer: 'Footer',
};

@autoBindMethods
export default class TemplateEditor extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal),
    user: PropTypes.object.isRequired,
    variableIndex: PropTypes.instanceOf(VariableIndex).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      linkingSummaryID: null,
      linkingLensID: null,
      linkingAIBlockID: null,
      upgrading: false,
      startingFlow: false,
      team: null,
      members: [],

      //modals state
      overview: false,
      overviewHeaderTab: OVERVIEW_HEADER_OPTIONS.overview,
      sharing: false,
      settings: false,
      colConfig: null,
      editingVariable: null,
      preview: false,
      unlinkingCL: null,

      //variable entity creation
      conditions: false,
      entity: null,
      locks: null,

      //stores the newly added source section ID
      queueFocus: null,
    };

    this.pdfContainerRef = createRef();
    this.refToolbarContainer = createRef();
    this.refEditorContainer = createRef();
    this.refToolbar = createRef();
    this.refDownload = createRef();
    this.refTools = createRef();
    this.editorStatus = createRef();
    this.refSourceSectionContainer = createRef();
    this.sectionRefs = {};
    this.refSummarySectionContainer = createRef();
    this.establishSectionRefs(props);
  }

  componentDidUpdate(prevProps) {
    const { deal } = this.props;

    if (this.state.queueFocus) {
      //If we have a section added and its queued for focus wait until the update deal is passed down. (from dealView loadDeal);
      if (_.findIndex(_.keys(deal.sections), (id) => this.state.queueFocus === id) > -1) {
        this.establishSectionRefs(this.props);
        this.onAddSection(this.state.queueFocus);
        this.setState({ queueFocus: null });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.establishSectionRefs(props);
  }

  establishSectionRefs(props) {
    const { deal } = props;
    _.forEach(deal.sections, (section) => {
      if (!this.sectionRefs[section.id]) {
        this.sectionRefs[section.id] = createRef();
      }
    });
  }

  async componentDidMount() {
    const { deal } = this.props;
    this._isMounted = true;

    // Show overview if we have one
    // Disabled because this is so fucking annoying. 99% of the time you wanna edit the contract (not overview)
    // if (summary.length > 0) this.setState({overview:true});

    // Activate section locking immediately
    this.watchLocks();

    const teamID = deal.isTemplate ? _.get(deal.template, 'team') : _.get(deal.info, 'sourceTeam');

    if (teamID) {
      // Load in team object and store in state, which is used for Branding display
      try {
        const teamJSON = await API.call('getTeam', { teamID });
        const team = new Team(teamJSON);
        this.setState({ team });
      } catch (err) {
        this.setState({ team: null });
      }

      // Load in team members, which are passed to sections
      // Team members are used in place of DealUsers for templates and required for correct display of ActivityView
      API.call('getTeamMembers', { teamID }, (members) => {
        this.setState({ members });
      });
    }

    window.addEventListener('keyup', this.findShortcut);
  }

  findShortcut(e) {
    if (e.ctrlKey && e.shiftKey && e.code === 'KeyP') {
      this.setState({ pdfPreview: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    Fire.unwatchSectionLocks(this.props.deal.dealID);
    this.removeLocks(true);
    window.removeEventListener('keyup', this.findShortcut);
  }

  //Maintain active listener on the list of locked sections for this deal
  watchLocks() {
    const { deal } = this.props;
    Fire.watchSectionLocks(deal.dealID, (locks) => {
      if (this._isMounted) {
        this.setState({ locks });
      }
    });
  }

  // Remove all of a user's locks
  removeLocks(explicit = false, exceptSectionID = null) {
    const { user } = this.props;
    const { locks } = this.state;
    const myLocks = this.getLocks(locks, user, true);

    if (this.nextLockTimeout) {
      clearTimeout(this.nextLockTimeout);
      this.nextLockTimeout = null;
    }
    _.forEach(myLocks, (lock, sectionID) => {
      if (exceptSectionID === sectionID) return;
      if (explicit) {
        console.log(`[LOCKS] Removing user [${lock.uid}] lock on section [${sectionID}]`);
      } else {
        console.log(`[LOCKS] User [${lock.uid}] lock on section [${sectionID}] timed out`);
      }
      // Unlock by calling blur() on the section component, which should exist (unless section got deleted)
      const editor = _.get(this.sectionRefs, `${sectionID}.current`);
      if (editor) editor.blur();
      this.unlock(sectionID);
    });
  }

  getLocks(locks, user, mine) {
    return _.pickBy(locks, ({ uid }) => (mine ? uid === user.id : uid !== user.id));
  }

  // Every time this is called, it will first cancel any already queued check,
  // So it effectively refreshes the timeout to keep the lock alive while user is editing
  queueUnlock() {
    if (this.nextLockTimeout) {
      clearTimeout(this.nextLockTimeout);
    }
    this.nextLockTimeout = setTimeout(this.removeLocks, LOCK_TIMEOUT);
  }

  lock(sectionID) {
    const { deal, user } = this.props;

    //fixes the timing/rerendering issues
    //if locks are enabled again remove.
    this.setState({ locks: null });

    this.queueUnlock();

    return Fire.lockSection(deal.dealID, sectionID, {
      uid: user.id,
      displayName: _.get(user, 'info.fullName') || _.get(user, 'info.email') || user.email,
    });
  }

  unlock(sectionID) {
    const { deal } = this.props;
    Fire.unlockSection(deal.dealID, sectionID);
  }

  //TODO: IMPORTED!!
  //////////////////////
  moveSection(dir) {
    const source = SectionType.src(this.focusedSection.sectiontype);
    Fire.moveSection(this.focusedSection, dir, source);
  }

  removeSection() {
    if (this.focusedSection) Fire.removeSection(this.focusedSection);
  }

  buildEntity(entity) {
    this.setState({ entity });
  }

  toggleNumbering() {
    let sec = this.focusedSection;
    if (!sec) return;
    //for source sections, toggle ordering of current section
    //for summary, only allow toggling of full block
    if (sec.sectiontype == SectionType.SUMMARY) {
      while (sec.indentLevel > 0) sec = sec.parent;
    }
    const hideOrder = sec.hideOrder == null ? true : null;
    Fire.saveSection(sec, { hideOrder });
  }

  get canLink() {
    const sec = this.focusedSection;
    return !!sec && sec.sectiontype === SectionType.SUMMARY && sec.indentLevel === 1;
  }

  get sourceSelected() {
    return this.focusedSection && SectionType.src(this.focusedSection.sectiontype);
  }

  get summarySelected() {
    return this.focusedSection && !SectionType.src(this.focusedSection.sectiontype);
  }

  canMove(dir) {
    const sec = this.focusedSection;
    if (sec) {
      return sec.canMove(dir, SectionType.src(sec.sectiontype));
    }
    return false;
  }

  addSection(source) {
    Fire.addSection(this.props.deal.root, source);
  }

  addHeaderFooterSection(sectionType) {
    Fire.addHeaderFooterSection(this.props.deal.root, sectionType, HEADER_FOOTER_SUB_SECTION_TYPES.ONE_COL);
  }
  // Query SectionEditor refs to see which one has focus
  get focusedSection() {
    const sectionEditor = _.find(this.sectionRefs, (ref) => _.get(ref, 'current.hasFocus'));
    if (sectionEditor) {
      return sectionEditor.current.props.section;
    } else {
      return null;
    }
  }

  // This returns the true latest/current underlying Section model instance for the currently focused SectionEditor
  // It differs from focusedSection above because while the editor is focused (ie while user is typing), the editor is not rerendering
  // So other components (such as EditorSidebar and SectionToolbar) should use this in props,
  // To ensure they are always operating on the latest data
  get focusedSectionModel() {
    const { deal } = this.props;
    const focusedSection = this.focusedSection;
    if (focusedSection) return deal.sections[focusedSection.id];
    else return null;
  }

  get showingModal() {
    const { sharing, settings, preview, colConfig } = this.state;
    return sharing || settings || preview || colConfig;
  }

  async focusSection(sectionID) {
    const sectionEditor = this.sectionRefs[sectionID];
    if (sectionEditor && sectionEditor.current) sectionEditor.current.gainFocus('down');
  }

  checkFocus(sectionID) {
    return _.get(this.sectionRefs, `[${sectionID}].current.state.editorFocus`, null);
  }

  persistFocus(sectionID, field) {
    const component = this.sectionRefs[sectionID];
    const editor = _.get(component, `current.${field}Editor`);
    if (editor) editor.focus();
  }

  async addPDFElement(elementType, variableName = null) {
    const { deal } = this.props;

    let newVariable = { name: variableName };
    if (!variableName) {
      newVariable = deal.generateCandidateVariable(elementType);
    }

    const element = new PDFElement(
      {
        elementType,
        page: 0,
        x: 100,
        y: 100,
        variable: newVariable.name,
      },
      deal
    );

    await Fire.savePDFElement(element);
  }

  async saveLinkedVariables(linkedSection, linkedDeal) {
    const { deal } = this.props;

    for (const key in linkedSection.variables) {
      const variable = linkedSection.variables[key];
      if (!deal.variables[key]) {
        //get base variable definitions (in case the variable is derived)
        let variableDefinition = variable.isConnected
          ? _.pick(variable.baseVariable, CONNECTED_VAR_FIELDS)
          : _.pick(variable.baseVariable, SAVEABLE_VAR_FIELDS);

        if (variable.valueType === ValueType.IMAGE && variable.value && variable.hasAttachment) {
          // Image variable and the attachment need to be copied over
          variableDefinition = await Fire.copyImageVariable(variableDefinition, linkedDeal.attachments, deal);
        }

        await Fire.saveVariableDefinition(deal, variableDefinition);
      }
    }
  }

  async onSelectCL(sectionRecord) {
    const { deal } = this.props;
    const focusedSection = this.focusedSection;

    const [dealID, sectionID] = sectionRecord.id.split('|');
    const path = `deals/${dealID}/sections/${sectionID}`;
    const cl = await Fire.load(path);
    const linkedDeal = await Fire.getDeal(dealID);
    const linkedSection = linkedDeal.sections[sectionID];

    // Loop through the linked clause and add any variables that are not currently defined in the template
    if (linkedSection) {
      await this.saveLinkedVariables(linkedSection, linkedDeal);
    }

    const topLevelFocusedSection =
      focusedSection.isTemplateHeaderFooterSubSection &&
      focusedSection.sourceParent &&
      focusedSection.sourceparentid !== 'root'
        ? focusedSection.sourceParent
        : focusedSection;

    if (topLevelFocusedSection) {
      await Fire.saveSection(topLevelFocusedSection, {
        displayname: cl.displayname || null,
        content: cl.content || null,
        originCL: sectionRecord.id,
        titleCL: cl.titleCL || null,
      });

      const sectionEditor = _.get(this.sectionRefs[topLevelFocusedSection.id], 'current');
      if (sectionEditor) {
        await sectionEditor.rerender(cl);
        sectionEditor.focus();
      }
    }

    //Template Header/Footer Subsection cases
    const { THREE_COL, TWO_COL } = HEADER_FOOTER_SUB_SECTION_TYPES;

    if ([SectionType.TEMPLATE_FOOTER, SectionType.TEMPLATE_HEADER].includes(cl.sectiontype)) {
      await Fire.saveSection(topLevelFocusedSection, {
        headerFooterConfigKey: cl.headerFooterConfigKey || null,
        headerFooterNumbering: cl.headerFooterNumbering || null,
        headerOrder: cl.headerOrder || null,
        subSectionType: cl?.subSectionType || null,
      });

      if ([TWO_COL, THREE_COL].includes(cl?.subSectionType)) {
        for await (let child of linkedSection.sourceChildren) {
          // Loop through the linked child  and add any variables that are not currently defined in the template
          await this.saveLinkedVariables(child, linkedDeal);

          //In this case we already have sections to populate
          if (
            [TWO_COL, THREE_COL].includes(topLevelFocusedSection?.subSectionType) &&
            !!topLevelFocusedSection.sourceChildren[child.sourceorder]
          ) {
            // If one child section exist already, overwrite it
            await Fire.saveSection(topLevelFocusedSection.sourceChildren[child.sourceorder], {
              displayname: child.displayname || null,
              content: child.content || null,
              originCL: `${dealID}|${child.id}`,
            });
          } else {
            // If none exist, create a new one
            child.deal.dealID = deal.dealID;
            await Fire.saveSection(child, {
              sourceparentid: topLevelFocusedSection.id,
              displayname: child.displayname || null,
              content: child.content || null,
              sectiontype: child.sectiontype,
              originCL: `${dealID}|${child.id}`,
            });
          }
        }
      }
    }
  }

  onLinkingLens(lens) {
    this.setState({ linkingLensID: lens ? lens.id : null });
  }

  onColConfig(tableVar) {
    this.setState({ colConfig: tableVar || false });
  }

  unlinkCL() {
    const { unlinkingCL } = this.state;
    Fire.saveSection(unlinkingCL, { originCL: null });
    this.setState({ unlinkingCL: null });

    // This is not generally good, but something about using async/await alongside closing the modal
    // was causing the browser to hang/crash... so just queue up focus for after modal closes
    const id = unlinkingCL.id;
    setTimeout(() => {
      this.focusSection(id);
    }, 500);
  }

  onAddSection(newID) {
    const newSec = _.get(this.sectionRefs, `${newID}.current`);
    if (!newSec) return;

    //if the section being added is not in view scroll to it.
    if (!this.isInView(newSec.refSelf.current)) {
      newSec.refSelf.current.scrollIntoView();
    }
    //no matter what, focus on newly added section
    newSec.gainFocus('down');

    //if the newly added section is a summary header with no children,
    //also add a child summary section by default
    if (newSec.isSummaryHeader && newSec.props.section.children.length == 0) {
      Fire.addSection(newSec.props.section, false, null, { parentid: newID });
    }
  }

  //check's if a element or reference is in the current view.
  isInView(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  // In some cases (e.g., the B / I / U inline style buttons on SectionToolbar)
  // It's best if an action on the Toolbar passes data to the SectionEditor to let that component do the work
  async propagateCommand(sectionID, command) {
    const sectionEditor = _.get(this.sectionRefs, `${sectionID}.current`);

    if (sectionEditor) {
      await sectionEditor.bodyEditor.focus();
      sectionEditor.handleBodyCommand(command);
    }
  }

  async handleExternalSignRequest() {
    const { deal, history } = this.props;
    await Fire.updateDealInfo(deal.info, { status: DealStatus.REVIEW.data });
    history.push(`/deals/${deal.dealID}/contract`);
  }

  async handlePreviewAction(eventKey) {
    switch (eventKey) {
      case 'native':
        this.setState({ preview: true });
        break;
      case 'pdf':
        this.setState({ pdfPreview: true });
        break;
      default:
        break;
    }
  }

  renderOverviewSections() {
    const { overviewHeaderTab } = this.state;
    const { summary, headers, footers } = this.props;
    switch (overviewHeaderTab) {
      case OVERVIEW_HEADER_OPTIONS.overview:
        return <>{this.renderSummarySections(summary)}</>;

      case OVERVIEW_HEADER_OPTIONS.header:
        return <>{this.renderHeaderFooterSections(headers, SectionType.TEMPLATE_HEADER)}</>;

      case OVERVIEW_HEADER_OPTIONS.footer:
        return <>{this.renderHeaderFooterSections(footers, SectionType.TEMPLATE_FOOTER)}</>;
    }
  }

  renderOverviewPanelHeader() {
    const { overview, overviewHeaderTab } = this.state;
    return (
      <div className="overview-panel-header" data-cy="overview-panel-header">
        <span className="panel-title">
          <ButtonGroup className="panel-tabs">
            {Object.entries(OVERVIEW_HEADER_OPTIONS).map(([k, v]) => (
              <Button
                key={k}
                dmpStyle="link"
                active={overviewHeaderTab == v}
                onClick={() => this.setState({ overviewHeaderTab: v })}
                data-cy={`${k}-tab`}
              >
                {v}
              </Button>
            ))}
          </ButtonGroup>
        </span>
        <ButtonClose
          className="panel-close"
          onClick={() => this.setState({ overview: !overview })}
          data-cy="panel-close"
        />
      </div>
    );
  }

  render() {
    const { deal, mode, user } = this.props;

    if (!deal || !user) return null;

    return (
      <div className={`template-editor mode-${mode}`}>
        <EditorStatus ref={this.editorStatus} />

        {deal.isExternal ? this.renderExternal() : this.renderEditor()}
      </div>
    );
  }

  renderEditor() {
    const { deal, mode, user, source, template, variableIndex, team } = this.props;
    const {
      overview,
      preview,
      settings,
      startingFlow,
      linkingSummaryID,
      unlinkingCL,
      colConfig,
      downloadURL,
      pdfPreview,
    } = this.state;
    if (!deal || !user) return null;

    const draft = mode === 'draft';

    // Support bespoke Deal editing (no Template)
    const showLetterhead = template ? template.showLetterhead : deal.showLetterhead;
    const showTitle = template ? template.showTitle : deal.showTitle;
    const enabledBorders = window.innerWidth >= MEDIA_QUERY_LARGE ? _.filter(deal.style.border, 'enabled') : null;
    const numberScheme = deal.style.type.LineNumbering.raw.native.numberScheme;

    return (
      <div className={`template-editor mode-${mode}`}>
        <div className="template-toolbar" ref={this.refToolbarContainer}>
          <SectionToolbar
            setQueueFocus={(newID) => this.setState({ queueFocus: newID })}
            ref={this.refToolbar}
            section={this.focusedSectionModel}
            container={this.refToolbarContainer.current}
            onDelete={this.deleteSection}
            linkingSummaryID={linkingSummaryID}
            checkFocus={this.checkFocus}
            persistFocus={this.persistFocus}
            propagateCommand={this.propagateCommand}
            team={team}
          />

          <div className="spacer" />

          {!draft && ( //No Overview editing for an ingested deal
            <div className="toolbar-group" ref={this.refTools}>
              <ButtonGroup>
                <TooltipButton tipID="tip-overview" tip={`Toggle visibility of ${dt} overview`} placement="bottom">
                  <ButtonIcon
                    tool
                    icon="overview"
                    size="default"
                    className={`btn-tool overview${overview ? ' active' : ''}`}
                    onClick={() => this.setState({ overview: !overview })}
                    data-cy="tip-overview"
                  />
                </TooltipButton>

                <TooltipButton tipID="tip-settings" tip="Manage template settings" placement="bottom">
                  <ButtonIcon
                    tool
                    icon="settingsCog"
                    size="default"
                    className="settings btn-tool"
                    onClick={() => this.setState({ settings: true })}
                    data-cy="btn-settings"
                  />
                </TooltipButton>
              </ButtonGroup>
              {user.can(FEATURES_USER.VINE_IO) && (
                <TooltipButton tipID="tip-vine" tip="Download .vine for this template" placement="bottom">
                  <ButtonIcon tool noFill icon="vine" size="default" onClick={this.download} data-cy="tip-vine" />
                </TooltipButton>
              )}
              {/* This link is invisible but needs to be present for triggering .vine file saving from the preceding icon */}
              <a ref={this.refDownload} href={downloadURL} download />
              <Dropdown
                id="dd-preview"
                onSelect={this.handlePreviewAction}
                pullRight
                title={'Preview as...'}
                size="small"
                dmpStyle="primary"
                data-cy="dd-preview"
              >
                {_.map(PREVIEW_TYPES, (type) => {
                  return (
                    <MenuItem eventKey={type.eventKey} key={type.eventKey} data-cy={type.eventKey}>
                      {type.title}
                    </MenuItem>
                  );
                })}
              </Dropdown>
            </div>
          )}

          {draft && (
            <div className="toolbar-group" ref={this.refTools}>
              <Button
                dmpStyle="link"
                icon="flowMode"
                className="edit-in-flow btn-tool"
                onClick={() => this.setState({ startingFlow: true })}
                data-cy="btn-edit-in-flow"
              >
                Edit in Flow
              </Button>
            </div>
          )}
        </div>

        <div className="editor-container" ref={this.refEditorContainer} data-cy="editor-container">
          <div className="editor-content" ref="source">
            <div className={cx('source-wrapper', { solo: !overview })}>
              <div
                className="source-page"
                data-cy="source-page"
                style={deal.style.layout.PageMargin.getPageWebMargins(window)}
                ref={this.refSourceSectionContainer}
              >
                {_.map(enabledBorders, (border) => (
                  <Border borderStyle={border} pageMargin={deal.style.layout.PageMargin} />
                ))}
                {numberScheme !== 'off' && window.innerWidth >= MEDIA_QUERY_LARGE && (
                  <Numbering deal={deal}></Numbering>
                )}
                {showLetterhead && team && (
                  <div
                    className="template-header"
                    onClick={() => this.setState({ settings: true })}
                    data-cy="template-header-branding"
                  >
                    <DealBranding branding={team.branding} deal={deal} />
                  </div>
                )}

                {/*Title is stored in different spots for templates vs ingested deals, but both should be editable*/}
                {showTitle && (
                  <div
                    className="template-header"
                    onClick={() => this.setState({ settings: true })}
                    style={{ marginBottom: deal.style.layout.Section.web.bottom }}
                    data-cy="template-header"
                  >
                    <div className="title" style={deal.style.type.Title.css}>
                      {template ? template.title : deal.info.name}
                    </div>
                  </div>
                )}

                {this.renderSourceSections(source)}
              </div>
            </div>

            {overview && (
              <>
                {this.renderOverviewPanelHeader()}
                <div className="summary-wrapper">
                  <div
                    className="summary-page"
                    ref={this.refSummarySectionContainer}
                    data-cy="summary-page"
                    style={deal.style.layout.PageMargin.getPageWebMargins(window)}
                  >
                    {this.renderOverviewSections()}
                  </div>
                </div>
              </>
            )}
          </div>
          <EditorSidebar
            id="editor-sidebar"
            title="Section"
            container={this.refEditorContainer}
            target={this.refTools}
            deal={deal}
            team={team}
            section={this.focusedSectionModel}
            onSelectCL={this.onSelectCL}
            onColConfig={this.onColConfig}
            onLinkingLens={this.onLinkingLens}
            variableIndex={variableIndex}
            openSettings={() => this.setState({ settings: true })}
            user={user}
          />
        </div>

        {/*All modals and popovers are rendered but hidden until invoked*/}

        <DealPreview {...this.props} show={preview} close={() => this.setState({ preview: false })} />
        <PDFPreview show={pdfPreview} close={() => this.setState({ pdfPreview: false })} deal={deal} user={user} />

        <TemplateInfo
          {...this.props}
          show={settings && !!template}
          deal={deal}
          template={template}
          close={() => this.setState({ settings: false })}
        />

        <DealSettings
          {...this.props}
          show={settings && !template}
          deal={deal}
          team={team}
          onHide={() => this.setState({ settings: false })}
          onSave={() => this.setState({ settings: false })}
        />

        <DraftFlowSwitcher
          {...this.props}
          show={startingFlow}
          deal={deal}
          close={() => this.setState({ startingFlow: false })}
        />

        <ModalConfirm
          show={!!unlinkingCL}
          title="Unlink clause?"
          cancelText="Keep clause linked"
          confirmText="Unlink clause"
          body={
            <>
              <div>Are you sure you want to unlink this section from its original source?</div>
              <br />
              <div>Unlinking will enable editing and turn this section into a new clause in your clause library.</div>
            </>
          }
          onHide={() => this.setState({ unlinkingCL: null })}
          onConfirm={this.unlinkCL}
          data-cy="unlink-cl-modal"
        />

        <TableColumnManager
          show={!!colConfig}
          dataSource={colConfig}
          variableIndex={variableIndex}
          close={() => this.setState({ colConfig: null })}
        />
      </div>
    );
  }

  renderExternal() {
    const { deal, mode, user } = this.props;
    const { settings } = this.state;

    if (!deal || !user) return null;

    return (
      <div className={`template-editor template-editor-pdf mode-${mode}`}>
        <div className="template-toolbar">
          <div className="toolbar-group">
            <TooltipButton
              tipID={'tip-drafting'}
              tip={
                <div style={{ textAlign: 'left' }}>
                  <b>Prepare for signing</b>
                  <p>Configure field and send out for signature</p>
                </div>
              }
              placement="bottom"
            >
              <Button className="btn-tool btn-deal-status">
                <span className="status-dot" />
                Drafting
              </Button>
            </TooltipButton>
          </div>

          {PDFElements.map((config, idx) => (
            <div className="toolbar-group" key={idx}>
              <TooltipButton tipID={'tip' + config.elementType} tip={config.tip} placement="bottom">
                <Button icon={config.icon} className="btn-tool" onClick={() => this.addPDFElement(config.elementType)}>
                  {config.display}
                </Button>
              </TooltipButton>
            </div>
          ))}
        </div>{' '}
        {/* END .template-toolbar-leftside*/}
        <div className="template-toolbar-rightside">
          <div className="toolbar-group">
            <ButtonIcon
              tool
              icon="settingsCog"
              className="btn-tool"
              onClick={() => this.setState({ settings: true })}
            />

            <Button
              className="send-sign-request"
              dmpStyle="primary"
              size="small"
              onClick={this.handleExternalSignRequest}
            >
              Send sign request
            </Button>
          </div>
        </div>
        <PDFView {...this.props} />
        <DealSettings
          {...this.props}
          show={settings}
          deal={deal}
          onHide={() => this.setState({ settings: false })}
          onSave={() => this.setState({ settings: false })}
        />
      </div>
    );
  }

  onVarClick(e, variableName) {
    e.stopPropagation();
    e.preventDefault();

    const sec = this.focusedSection;

    if (sec) {
      const text = `[${variableName}]`;

      //only allow click-to-insert for source sections
      //and for SUMMARY sections at level 1 indent
      //i.e., the child items of an Overview block
      if (SectionType.src(sec.sectiontype) || (sec.sectiontype == SectionType.SUMMARY && sec.indentLevel == 1)) {
        this.insertText(text, sec);
      }
    }

    return false;
  }

  // Anytime a section focuses, make sure that no other sections are focused or locked
  async onSectionFocus(section) {
    const { linkingSummaryID, linkingAIBlockID } = this.state;

    // First, blur all other previously focused sections
    // This ensures that visually only 1 section is focused at a time, but doesn't affect lock state
    let focused = _.filter(this.sectionRefs, (editor) => editor.current && editor.current.hasFocus);
    focused = _.map(focused, 'current');
    _.forEach(focused, (editor) => {
      if (editor.props.section.id !== section.id) {
        editor.blur();
      }
    });

    // Await the new lock to be established before removing all others
    // This ensures that in the event of rapid section traversal (e.g., fast clicking)
    // The user can ultimately only have 1 section locked at a time
    await this.lock(section.id);

    // Now finally remove all other locks
    this.removeLocks(true, section.id);

    // If we were in linking mode and focus changes, either exit linking mode if source side gets focused,
    // or update linking focus to currently focused section
    if (section.isSummary && section.indentLevel === 1) {
      this.setState({ linkingSummaryID: section.id });
    } else if (section.isList && [LIST_TYPES.AI, LIST_TYPES.TIMELINE].includes(section.subType)) {
      this.setState({ linkingAIBlockID: section.id });
    } else if (linkingSummaryID) {
      this.setState({ linkingSummaryID: null });
    } else if (linkingAIBlockID) {
      this.setState({ linkingAIBlockID: null });
    }
  }

  // Usually SectionEditor.blur() is called externally (from here),
  // to ensure only 1 section is being edited/locked at a time
  // But when called from SectionEditor (ie, on 'escape' keypress),
  // We need to remove the corresponding section lock
  onSectionBlur(sectionID, removeLocks) {
    if (removeLocks) this.removeLocks(true);
  }

  async deleteSection(moveDirection) {
    const section = this.focusedSection;
    if (!section) return;

    // Select previous section after deletion if none is specified
    if (!moveDirection) moveDirection = 'up';

    // Move focus prior to deleting so that lock gets removed
    this.moveFocus('up', section);

    if (section.isCaption) {
      await Fire.removeCaption(section);
    } else if (
      section.subSectionType === HEADER_FOOTER_SUB_SECTION_TYPES.TWO_COL ||
      section.isTemplateHeaderFooterSubSection
    ) {
      await Fire.removeTwoColHeaderFooter(section);
    } else {
      await Fire.removeSection(section);
    }

    // As long as section isn't linked to another in the CL,
    // Unlink any that may be linked to *this* section
    if (section.deal.template && !section.originCL) {
      API.call('unlinkOriginCL', {
        originCL: `${section.deal.dealID}|${section.id}`,
        teamID: section.deal.template.team,
      });
    }
  }

  async download() {
    const { deal } = this.props;

    // Generate a fresh download URL and assign to hidden download link
    const token = await Fire.token();
    const downloadURL = generateExportURL({ deal, token, type: 'vine' });
    await this.setState({ downloadURL });

    // Trigger click to auto download (as long as we're not in testing mode)
    if (typeof window.Cypress === 'undefined') {
      this.refDownload.current.click();
    }
  }

  // Every individual SectionEditor can kick off a (debounced) call to save, which shows status message
  // But we can't use TemplateEditor.state to handle this, because doing so causes the entire template to unnecessarily re-render
  // So we're handling manually via refs to a component which is guaranteed to always be present
  updateMessage(message, type, hide) {
    if (this.editorStatus.current) {
      this.editorStatus.current.showMessage(message, type, hide);
    }
  }

  setQueueFocus(newID) {
    this.setState({ queueFocus: newID });
  }
  editVariable(editingVariable) {
    this.setState({ editingVariable });
  }
  editorUnlinkCL(sec) {
    this.setState({ unlinkingCL: sec });
  }

  renderSummarySections(sections) {
    const { linkingSummaryID, locks } = this.state;
    const { user, variableIndex } = this.props;

    if (sections.length === 0) {
      return (
        <EmptyState
          type="template"
          title="No Overview"
          subtitle="You have not created any overview content yet for this template."
          action="Start now"
          onAction={() => this.addSection(false)}
        />
      );
    }

    return sections.map((section) => {
      let lock = _.get(locks, section.id, null);
      if (lock && lock.uid === _.get(user, 'id')) lock = null;

      // LIST sections can now appear on Overview too via links (replacing SCOPE sections)
      // But our current ref structure would point both components to the same ref, which creates focus bugs
      // However, the LIST section on Overview is not focusable as it's just FPO, so we don't need a ref anyway
      const sectionRef = section.isList ? null : this.sectionRefs[section.id];

      return (
        <SectionEditor
          setQueueFocus={(newID) => this.setState({ queueFocus: newID })}
          container={this.refs.summary}
          editVariable={(editingVariable) => this.setState({ editingVariable })}
          keepAlive={this.queueUnlock}
          key={section.id}
          linkingSummaryID={linkingSummaryID}
          lock={lock}
          moveFocus={(dir) => this.moveFocus(dir, section, sections)}
          onBlur={this.onSectionBlur}
          onFocus={this.onSectionFocus}
          readonly={!!lock || section.shouldSyncWithCL}
          ref={sectionRef}
          section={section}
          toc={this.props.toc}
          tocKey={this.props.tocKey}
          updateMessage={this.updateMessage}
          userID={user.id}
          team={this.state.team}
          toolbar={this.refToolbar}
          unlinkCL={(sec) => this.setState({ unlinkingCL: sec })}
          variableIndex={variableIndex}
          onColConfig={this.onColConfig}
          templateRef={this.refSummarySectionContainer}
          overviewMode
          handleLockSection={this.lock}
          handleUnlockSection={this.unlock}
        />
      );
    });
  }

  renderHeaderFooterSections(sections, sectionType) {
    const { linkingSummaryID, locks } = this.state;
    const { user, variableIndex } = this.props;

    const sectionTypeText = sectionType === SectionType.TEMPLATE_FOOTER ? 'footer' : 'header';

    if (sections.length == 0) {
      return (
        <EmptyState
          type="template"
          title={`No page ${sectionTypeText} set`}
          subtitle={`You have not created any ${sectionTypeText}s yet for this template.`}
          action="Start now"
          onAction={() => this.addHeaderFooterSection(sectionType)}
        />
      );
    }
    const editors = [];
    sections.forEach((section) => {
      let lock = _.get(locks, section.id, null);
      if (lock && lock.uid === _.get(user, 'id')) lock = null;

      const sectionRef = section.isList ? null : this.sectionRefs[section.id];
      const { THREE_COL, TWO_COL } = HEADER_FOOTER_SUB_SECTION_TYPES;

      if ([TWO_COL, THREE_COL].includes(section.subSectionType)) {
        editors.push(
          <TemplateFooterHeaderSubSections section={section} key={section.id} measureClass="section-text">
            {this.renderHeaderFooterSections(section.sourceChildren)}
          </TemplateFooterHeaderSubSections>
        );
        return;
      }

      const headerFooterSection = section.isTemplateHeaderFooterSubSection ? section.sourceParent : section;

      const editor = (
        <React.Fragment key={section.id}>
          <SectionEditor
            setQueueFocus={(newID) => this.setState({ queueFocus: newID })}
            container={this.refs.summary}
            editVariable={(editingVariable) => this.setState({ editingVariable })}
            keepAlive={this.queueUnlock}
            key={section.id}
            linkingSummaryID={linkingSummaryID}
            lock={lock}
            moveFocus={(dir) => this.moveFocus(dir, section, sections)}
            onBlur={this.onSectionBlur}
            onFocus={this.onSectionFocus}
            readonly={!!lock || section.shouldSyncWithCL || headerFooterSection.isDefault}
            ref={sectionRef}
            section={section}
            toc={this.props.toc}
            tocKey={this.props.tocKey}
            updateMessage={this.updateMessage}
            userID={user.id}
            team={this.state.team}
            toolbar={this.refToolbar}
            unlinkCL={(sec) => this.setState({ unlinkingCL: sec })}
            variableIndex={variableIndex}
            onColConfig={this.onColConfig}
            templateRef={this.refSummarySectionContainer}
            overviewMode
            handleLockSection={this.lock}
            handleUnlockSection={this.unlock}
          />
          {!section.isTemplateHeaderFooterSubSection && !!section.conditions?.length && (
            <ConditionsView conditions={section.conditions} deal={section.deal} section={section} />
          )}
          {!section.isTemplateHeaderFooterSubSection && <PagesRenderView section={section} />}
        </React.Fragment>
      );
      editors.push(editor);
    });

    return editors;
  }

  renderSourceSections(sections) {
    const { linkingSummaryID, linkingLensID, linkingAIBlockID, locks } = this.state;
    const { user, deal, variableIndex } = this.props;

    // We need to separately compute a flattened list of sections for keyboard traversal,
    // because the "source" prop passed now includes SectionColumns sections,
    // which are dynamically created container models for rendering
    const flat = deal.buildSource(true, false);

    if (sections.length == 0) {
      return (
        <EmptyState
          type="deal"
          title={`Empty ${Dt}`}
          subtitle={`You have not created any source ${dt} content yet for this template.`}
          action="Start now"
          onAction={() => this.addSection(true)}
        />
      );
    }

    const editors = [];

    _.forEach(sections, (section) => {
      let lock = _.get(locks, section.id, null);
      if (lock && lock.uid === _.get(user, 'id')) lock = null;

      if (section.sectiontype === SectionType.COLUMNS) {
        editors.push(
          <>
            <div className={cx('section-columns', { 'page-break': section.pageBreak })} key={section.id}>
              {section.pageBreak && <PageBreak section={section} />}
              {section.sections.length > 0 && this.renderSourceSections(section.sections)}
            </div>
            {_.map(section.sections, (source) => {
              return (
                <FootnoteDisplay
                  key={source.id}
                  section={source}
                  styles={source.footnoteStyle.css}
                  activeFootnotes={source.activeFootnotes}
                  container={this.refSourceSectionContainer.current}
                />
              );
            })}
          </>
        );
        return;
      } else if (section.sectiontype === SectionType.CAPTION) {
        editors.push(
          <>
            <CaptionSection section={section} key={section.id} measureClass="section-text">
              {this.renderSourceSections(section.sourceChildren)}
            </CaptionSection>
            {_.map(section.sourceChildren, (source) => {
              return (
                <FootnoteDisplay
                  key={source.id}
                  section={source}
                  styles={source.footnoteStyle.css}
                  activeFootnotes={source.activeFootnotes}
                  container={this.refSourceSectionContainer.current}
                />
              );
            })}
          </>
        );
        return;
      }

      /*
        In order to fully fix extra re-rendering of SectionEditor, we would need to move
        "moveFocus={}" to a Class function vs the current inlined one.
        This one is failry complex since it is relying on the "flat" variable.
        We will leave it there for now and get back to it when performance improvement must happen.
      */
      const editor = (
        <SectionEditor
          setQueueFocus={this.setQueueFocus}
          key={section.id}
          container={this.refs.source}
          editVariable={this.editVariable}
          keepAlive={this.queueUnlock}
          linkingSummaryID={linkingSummaryID}
          linkingAIBlockID={linkingAIBlockID}
          linkingLensID={linkingLensID}
          lock={lock}
          moveFocus={(dir) => this.moveFocus(dir, section, flat)}
          onBlur={this.onSectionBlur}
          onFocus={this.onSectionFocus}
          paste={this.forceUpdate}
          readonly={!!lock || section.shouldSyncWithCL}
          ref={this.sectionRefs[section.id]}
          section={section}
          source={this.props.source}
          toc={this.props.toc}
          tocKey={this.props.tocKey}
          updateMessage={this.updateMessage}
          userID={user.id}
          team={this.state.team}
          toolbar={this.refToolbar}
          onSelectCL={this.onSelectCL}
          unlinkCL={this.editorUnlinkCL}
          variableIndex={variableIndex}
          onColConfig={this.onColConfig}
          templateRef={this.refSourceSectionContainer}
          handleLockSection={this.lock}
          handleUnlockSection={this.unlock}
        />
      );
      editors.push(editor);
    });

    return editors;
  }

  moveFocus(dir, fromSection, sections) {
    const { deal } = this.props;
    const { locks } = this.state;

    // Don't attempt focus if a modal is showing (causes Draft.js errors)
    if (this.showingModal) return;

    // This fixes a focus traversal bug that was introduced with columns and SectionToolbar
    // Now when sections are sequentially deleted via toolbar, focus should stay on the editor
    if (!sections) sections = deal.buildSource(true, false);

    const currentIndex = fromSection ? sections.indexOf(fromSection.layoutSection) : -1;
    let targetIndex = currentIndex;
    let toSection,
      toRef = null;

    // Captions require special focus handling because their nested SectionEditors are not in the sections array
    // If we're traversing between columns inside the caption, we can handle it here and stop
    if (_.get(fromSection, 'isCaption') || _.get(fromSection, 'isTemplateHeaderFooterSubSection')) {
      const caption = fromSection.layoutSection;
      if (fromSection === caption.lhs && dir === 'down') {
        toRef = _.get(this.sectionRefs, _.get(caption, 'rhs.id', '') + '.current');
      } else if (fromSection === caption.rhs && dir === 'up') {
        toRef = _.get(this.sectionRefs, _.get(caption, 'lhs.id', '') + '.current');
      }

      if (toRef) {
        toRef.gainFocus(dir);
        return;
      }
    }

    do {
      targetIndex = dir === 'up' ? targetIndex - 1 : targetIndex + 1;
      if (targetIndex < 0 || targetIndex >= sections.length) break;
      toSection = sections[targetIndex];
      if (!toSection) break;

      // If we're going from a normal Section TO a Caption,
      // Again we need special handling to manually target the appropriate column
      if (
        toSection.sectiontype === SectionType.CAPTION ||
        toSection.subSectionType === HEADER_FOOTER_SUB_SECTION_TYPES.TWO_COL
      ) {
        if (dir === 'down') {
          toRef = _.get(this.sectionRefs, _.get(toSection, 'lhs.id', '') + '.current');
        } else {
          toRef = _.get(this.sectionRefs, _.get(toSection, 'rhs.id', '') + '.current');
        }
      } else {
        toRef = _.get(this.sectionRefs, `${toSection.id}.current`);
      }
      if (toSection.isList && fromSection.isSummary) {
        console.log(`Next section ${dir} is a linked list/block and not focusable on Overview -- skipping`);
        toRef = null;
      }
      if (toRef) {
        // Found a valid target SectionEditor; make sure the section isn't locked by another user though
        // Normal case, there's no lock on the next section, so we found what we're looking for
        if (!_.get(locks, toSection.id)) {
          break;
        }
        // If it's locked by the current user (which can happen during rapid keyboard traversal), that's fine
        // else if (_.get(locks, `${toSection.id}.uid`) === user.id) {
        //   break;
        // }
        // But if we get here, the target section is locked so we need to keep traversing
        else {
          console.log(`Next section ${dir} is locked -- skipping`);
          toRef = null;
        }
      }
    } while (!toRef);

    if (toRef) {
      console.log(`Moving focus from ${currentIndex} to ${targetIndex}`);
      toRef.gainFocus(dir);
    } else {
      console.log(`No valid/unlocked section found ${dir} from ${currentIndex} -- retaining focus`);
    }
  }

  insertText(text, section, creating) {
    if (!section) return;
    const toRef = this.sectionRefs[section.id];
    if (toRef && toRef.current) toRef.current.insertText(text, creating);
  }
}
