import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { Button } from '@components/dmp';

import Auth, { PROVIDERS } from '@root/Auth';
import CONFIG from '@root/Config';

export default class Connections extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      openIDProvider: null,
    };
  }

  componentDidMount() {
    this.getOpenIDProvider();
  }

  async disconnect(provider) {
    const { account } = this.props;

    try {
      await account.unlink(provider);
      console.log(`[AUTH] Successfully unlinked ${provider} account`);
      this.forceUpdate();
    } catch (error) {
      console.log(`[AUTH] Error unlinking account from ${provider}`, error);
    }
  }

  async getOpenIDProvider() {
    const { account } = this.props;
    const openIDProvider = await API.call('getOpenIDProvider', { email: account.email });
    this.setState({ openIDProvider });
  }

  renderProvider(provider) {
    const { account } = this.props;
    let connected = false;
    let connectDisabled = false;

    if (provider.name === PROVIDERS.OPEN_ID.name) {
      const { openIDProvider } = this.state;

      if (openIDProvider?.providerID) {
        provider.id = openIDProvider.providerID;
      } else {
        connectDisabled = true;
      }
    }

    if (provider.name === PROVIDERS.FILEVINE.name && !CONFIG.FVID_ENABLED) {
      connectDisabled = true;
    }

    connected = _.filter(account.providerData, { providerId: provider.id }).length > 0;
    const isDisconnectDisabled = account.providerData.length === 1;

    return (
      <div className="table-inner" key={provider.id}>
        <div className="s-mob-full bold">
          <img className="margin-right-s" src={provider.logo} alt={provider.name} />
          {provider.name}
        </div>
        <div>
          {!connected ? (
            <div>
              <Button
                className="connect"
                size="small"
                onClick={() => Auth.login(provider.id)}
                disabled={connectDisabled}
              >
                Connect
              </Button>
            </div>
          ) : (
            <div>
              <Button
                className="disconect"
                size="small"
                onClick={() => this.disconnect(provider.id)}
                disabled={isDisconnectDisabled}
              >
                Disconnect
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return <div className="table-outer">{_.map(PROVIDERS, (provider) => this.renderProvider(provider))}</div>;
  }
}
