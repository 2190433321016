import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { FILEVINE_SERVICE } from '@core/enums/IntegrationServices';
import { ACCEPTED_TYPES, TEMPLATE_TYPES as ATTACHMENT_TEMPLATE_TYPES } from '@core/models/Attachment';
import DealUser, { DEAL_USER_PROPERTIES } from '@core/models/DealUser';
import { FEATURES as TEAM_FEATURES } from '@core/models/Team';
import { TEMPLATE_TYPES } from '@core/models/Template';
import { FEATURES } from '@core/models/User';
import { BATCH_ID_VAR, VariableType } from '@core/models/Variable';
import { MERGE_TYPE } from '@core/models/Version';
import { downloadLink } from '@core/utils/CSV';
import { Dt, dt } from '@core/utils/Environment';
import { getBaseUrl } from '@core/utils/Generators';

import { Button, Checkbox, Dropdown, Loader, MenuItem, Validator } from '@components/dmp';

import PartySelector from '@components/PartySelector';
import FilevineTemplateConnection from '@components/deal/FilevineTemplateConnection';
import FileUp from '@components/editor/FileUp';
import TooltipButton from '@components/editor/TooltipButton';
import TeamSelector from '@components/teams/TeamSelector';
import TeammateSelector from '@components/teams/TeammateSelector';
import TemplateSelector from '@components/teams/TemplateSelector';
import WorkflowSelector from '@components/teams/WorkflowSelector';
import API from '@root/ApiClient';
import Dealer from '@root/Dealer';
import Fire from '@root/Fire';

import DealUserBlock from './DealUserBlock';

export const NEW_TEMPLATE_MODES = [
  {
    key: 'blank',
    title: 'Blank',
    tip: 'Create a new template from scratch',
    icon: 'draft',
  },
  {
    key: 'existing',
    title: 'Existing',
    tip: 'Create a new template based on an existing template',
    icon: 'template',
  },
  {
    key: 'upload',
    title: 'Upload',
    tip: 'Create a new template by uploading a .vine or .docx document',
    icon: 'import',
  },
];

const TABS = [
  {
    key: 'general',
    title: 'General',
    tip: `Manage general settings and features for ${dt}s generated from this template`,
  },
  {
    key: 'users',
    title: 'Users',
    hideFooter: true,
    tip: `Assign default users and roles to be added to ${dt}s generated from this template`,
  },
  {
    key: 'advanced',
    title: 'Advanced',
    tip: `Configure how ${dt}s from this template can interact with other systems`,
  },
  { key: 'connections', title: 'Connections', hideFooter: true, tip: 'Connect to third-party applications.' },
];

const ATTACHMENT_EXPORT_OPTIONS = [
  {
    exportAttachments: false,
    title: 'Do not print (default)',
  },
  {
    exportAttachments: true,
    title: 'Print at end of document',
  },
];

// Valid chars: a-z A-Z 0-9 _ -
const regexFindInvalidChars = /[^a-zA-Z0-9_\-]/g;

function generateKeyFromUserInput(source) {
  const placeholder = '-';

  // Convert to lowercase:
  let key = source.toLowerCase();

  // Remove any apostrophes (makes contractions look better):
  // eg: it's -> its instead of it-s
  key = key.replace(/'/g, '');

  // Replace any invalid chars:
  key = key.replace(regexFindInvalidChars, placeholder);

  // Remove leading/trailing separator chars:
  key = key.replace(/^[-_]*/, '');
  key = key.replace(/[-_]*$/, '');

  // Collapse consecutive separator chars to just one:
  key = key.replace(/-+/g, '-');
  key = key.replace(/_+/g, '_');

  return key;
}

function isKeyValid(source) {
  return source.match(regexFindInvalidChars) === null;
}

@autoBindMethods
export default class TemplateInfo extends Component {
  static defaultProps = {
    deal: null,
    show: false,
    team: null,
    teams: [],
  };

  static propTypes = {
    close: PropTypes.func.isRequired,
    deal: PropTypes.object,
    history: PropTypes.object.isRequired,
    show: PropTypes.bool,
    team: PropTypes.object,
    teams: PropTypes.array,
    template: PropTypes.object,
    user: PropTypes.object.isRequired,
    mode: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      type: null,
      description: '',
      key: '',
      keyIsAvailable: false,
      team: props.team || null,
      guestSigning: true, // whether recipients can sign deal via anonymous login
      commenting: true,
      readonly: false,
      batch: false, // whether template is enabled for batch operations
      inbound: false,
      inboundMulti: false, //whether multiple inbound deals of the same template type can be created
      inboundParty: null,
      autoGuest: false,
      autoParty: null,
      excludeCL: false,
      documentAI: false,
      exportAttachments: false,

      parsed: null, //parsed docx data ready for upload, from WordUp component

      sourceTemplate: null,
      creating: false,
      saving: false,
      teams: [],
      members: [],
      templateDealUsers: [],

      //settings area
      tab: TABS[0],
      linkCL: false,
      scoring: null,
    };
  }

  get isNew() {
    return this.props.template == null;
  }

  populate(props) {
    if (!this._isMounted || !props.show) return;

    const { template, teams, deal } = props;

    // If template is passed in props, component is being used to edit an existing template
    if (template) {
      const team = template.team ? _.find(teams, { teamID: template.team }) : null;

      this.setState({
        title: template.title || '',
        type: template.type || null,
        description: template.description || '',
        key: template.key || '',
        team,
        autoGuest: template.autoGuest == null ? false : template.autoGuest,
        guestSigning: template.guestSigning == null ? true : template.guestSigning,
        commenting: template.commenting == null ? true : template.commenting,
        readonly: template.readonly || false,
        batch: template.batch || false,
        inbound: template.inbound || false,
        inboundParty: template.inboundParty || null,
        inboundMulti: template.inboundMulti || false,
        autoParty: template.autoParty || null,
        excludeCL: template.excludeCL || false,
        // Assume existing keys are valid
        validKey: !!template.key,
        documentAI: template.documentAI || false,
        scoring: template.scoring,
        exportAttachments: template.exportAttachments,
      });

      // Load in team members
      if (template.team) {
        API.call('getTeamMembers', { teamID: template.team }, (members) => this.setState({ members }));
      }

      API.call('getTemplateDealUsers', { dealID: template.dealID }, (result) => {
        const templateDealUsers = (result.data ?? []).map((rawUser) => new DealUser(rawUser, deal, rawUser.key));
        this.setState({ templateDealUsers });
      });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.populate(props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get isCreationDisabled() {
    const { title, creating, sourceTemplate, parsed, keyIsAvailable, key } = this.state;
    const { mode } = this.props;
    return (
      !title ||
      !key ||
      !isKeyValid(key) ||
      !keyIsAvailable ||
      creating ||
      (mode == 'upload' && !parsed) ||
      (mode == 'existing' && !sourceTemplate)
    );
  }

  get isUpdateDisabled() {
    const { title, saving, key } = this.state;
    return !title || saving || (!this.existingKey && (!isKeyValid(key) || !this.state.keyIsAvailable));
  }

  // Some older templates may (somehow) not have keys
  // For those, we want to still allow user to type in a new key and save it (once)
  get existingKey() {
    const { deal } = this.props;
    return _.get(deal, 'template.key');
  }

  handleTitleChange(e) {
    const value = e.target.value;
    this.setState({ title: value });
  }

  handleTitleBur() {
    this.setState({ title: this.state.title.trim() });

    // Generate a Template Key from the Title string if there isn't one set already:
    if (this.state.title.length > 0 && this.state.key.length === 0) {
      this.setState({ key: generateKeyFromUserInput(this.state.title) });
    }
  }

  handleKeyChange(e) {
    const value = e.target.value;
    this.setState({ key: value, keyIsAvailable: false });
  }

  updateWorkflow(workflow) {
    const { deal } = this.props;
    Fire.saveTemplate(deal.dealID, { workflow });
  }

  async createTemplate() {
    const { sourceTemplate, parsed, title, type, key, description, linkCL } = this.state;
    const { user, history, mode, team } = this.props;

    // Whether the template is being created from upload, another template, or from scratch,
    // the deal object needs to be created first
    // so we have a common callback for tacking on the template and then redirecting
    const newTemplateInfo = {
      title,
      key,
      description,
      team: team.teamID,
      type,
    };

    await this.setState({ creating: true });

    let dealID, deal;

    // Create a deal via upload, then save template
    if (mode === 'upload' && parsed) {
      // .vine upload path
      if (parsed.fileType === ACCEPTED_TYPES.VINE.mime) {
        const newDealID = await API.call('importDeal', {
          deal: parsed.json,
          template: true,
          teamID: team.teamID,
          templateKey: key,
          templateData: newTemplateInfo,
        });

        deal = await Fire.getDeal(newDealID);
      }

      // .docx parsing/ingestion path
      else if (parsed.fileType === ACCEPTED_TYPES.WORD.mime) {
        const { sections, numbering } = parsed;
        dealID = await API.call('createDeal', {
          dealParams: {
            user,
            title,
            teamID: team.teamID,
            templateData: newTemplateInfo,
          },
        });
        deal = await Fire.getDeal(dealID);
        await Fire.ingestDeal(deal, user, { sections, title, numbering }, MERGE_TYPE.OVERWRITE);
        deal = await Fire.getDeal(dealID);
      }
    }
    // Direct copy from another template that this user has access to
    else if (mode === 'existing' && sourceTemplate) {
      // If creating from a deal, we need to grab that deal first
      let dealTemplate = await Fire.getDeal(sourceTemplate.dealID);

      // Creating a new Template from existing should inherit all behaviors/properties
      // (other than the new ones we just specified)

      const omittedTemplateProperties = ['title', 'key', 'description', 'team', 'dealID'];

      if (team !== sourceTemplate.team) {
        //We need to account for the changing of teams with different permissions
        //TODO: We are starting with Lense/document AI but this needs to be extended to other properties as well.
        const crossTeamOmissions = ['lenses', 'hasLenses', 'documentAI'];
        omittedTemplateProperties.concat(crossTeamOmissions);
      }

      _.assign(newTemplateInfo, _.omit(dealTemplate.template.json, omittedTemplateProperties));

      const newDealFromTemplate = await API.call('createDealFromTemplate', {
        templateID: dealTemplate.dealID,
        isTemplate: true,
        linkCL,
        templateData: newTemplateInfo,
      });

      deal = newDealFromTemplate.deal;
    } else if (mode === 'blank') {
      //otherwise we can just go ahead and create
      dealID = await API.call('createDeal', {
        dealParams: {
          user,
          title,
          teamID: team.teamID,
          templateData: newTemplateInfo,
        },
      });
      deal = await Fire.getDeal(dealID);
    }

    // assuming a new Deal got created for the new template, attach the Template object
    if (deal) {
      let eventDetails = { templateID: newTemplateInfo.key, templateName: newTemplateInfo.title };

      if (mode === 'existing') {
        eventDetails.sourceTemplateID = sourceTemplate.dealID;
      }

      API.call('userAuditLog', {
        dealID: deal.dealID,
        teamID: team.teamID,
        eventCategory: 'template',
        eventType: 'create',
        eventSubtype: mode,
        eventDetails,
        userEmail: user.email,
      });

      await this.setState({ creating: false });
      history.push(`/templates/${deal.dealID}/editor`);
    }
  }

  onParse(parsed) {
    let title, key, description;

    if (parsed && parsed.title) {
      title = parsed.title.replace(/\n/g, ' ');
      this.setState({ title });
    }

    // Populate description and key too if we have them from a .vine upload
    const json = _.get(parsed, 'json');
    if (json) {
      // If uploaded .vine is a true template, we can use the values there
      if (json.template) {
        description = _.get(parsed, 'json.template.description');
        key = _.get(parsed, 'json.template.key');
      }
      // If not we can still parse/suggest a template key based on title
      else if (title) {
        key = generateKeyFromUserInput(title);
      }
    }

    if (description) this.setState({ description });
    if (key) this.setState({ key });

    this.setState({ parsed, sourceTemplate: null });
  }

  async saveTemplate() {
    const { deal, template, user } = this.props;
    const { parsed, title, batch } = this.state;

    if (!_.get(template, 'dealID')) return;

    // NB: this is not the full list of Template properties;
    // we only want to store the properties which can be updated via the UI on this component
    const templateData = _.pick(this.state, [
      'autoGuest',
      'autoParty',
      'commenting',
      'description',
      'guestSigning',
      'batch',
      'inbound',
      'inboundMulti',
      'inboundParty',
      'readonly',
      'title',
      'type',
      'excludeCL',
      'documentAI',
      'scoring',
      'exportAttachments',
    ]);

    if (!this.state.existingKey) {
      templateData['key'] = this.state.key;
    }

    await this.setState({ saving: true });

    // If we have parsed data from a docx upload, import that first before saving template
    if (parsed != null) {
      const { sections, numbering } = parsed;
      await Fire.ingestDeal(deal, user, { sections, title, numbering }, MERGE_TYPE.OVERWRITE);
    }

    // If batch ops are enabled, ensure that we have the Batch ID var defined on this template
    if (batch) {
      await Fire.saveVariableDefinition(deal, BATCH_ID_VAR);
    }

    await Fire.saveTemplate(deal.dealID, templateData);
    this.close();
  }

  async checkKeyAvailability(templateKey) {
    const { team } = this.props;
    const valid = await API.call('validateTemplateKey', { teamID: team.teamID, templateKey });
    return valid;
  }

  addTeammate(tm) {
    const { deal } = this.props;
    this.refs.tm.updateAdding(tm.id);

    API.call('addTeammatesToDeal', { teammates: [tm], notify: false, dealID: deal.dealID }, () => {
      this.refs.tm.updateAdding(null);
    });
  }

  updateBehavior(aspect, value) {
    const newState = { [aspect]: value };

    // Make sure autoGuest can't be true if guestSigning is disabled
    if (aspect === 'guestSigning' && !value) {
      newState.autoGuest = false;
    }

    this.setState(newState);
  }

  buildCsvTemplate(varName, varValue) {
    const entry = {
      'Template Variable': varName,
      'Default Value': varValue,
    };
    return entry;
  }

  exportTemplateMetadata() {
    const { deal } = this.props;
    const csvData = [];
    const simpleVar = _.filter(deal.variables, { type: VariableType.SIMPLE });

    _.forEach(simpleVar, (simple) => {
      csvData.push(this.buildCsvTemplate(`#${simple.name}`, simple.value));
    });

    _.forEach(deal.parties, (party) => {
      _.forEach(DEAL_USER_PROPERTIES, (property) => {
        csvData.push(this.buildCsvTemplate(`@${party.partyID}.${property}`, null));
      });
    });

    // If we have an empty csv, at least push an empty row to avoid a page crash.
    if (!csvData.length) {
      csvData.push(this.buildCsvTemplate('', ''));
    }

    return downloadLink(csvData);
  }

  exportTemplateMetadataFileName() {
    return `${this.props.template.key}-meta.csv`;
  }

  close() {
    //ensure that any time modal is closed we reset any parsed upload that was present
    //this is necessary because otherwise the state data persists through closing/opening
    this.setState({ parsed: null, saving: false });
    this.props.close();
  }

  selectTeam(newTeamID) {
    const { teams } = this.props;
    const { team } = this.state;

    const newTeam = _.find(teams, { teamID: newTeamID });
    const newState = { team: newTeam, linkCL: false };

    // Only clear the sourceTemplate if we change the team
    if (team.teamID !== newTeamID) {
      newState.sourceTemplate = null;
    }

    this.setState(newState);
  }

  get activeScoringMatrix() {
    const { scoring, team, sourceTemplate } = this.state;

    const activeMatrix = scoring || sourceTemplate?.scoring;

    return activeMatrix ? team.scoringMatrices[activeMatrix] : null;
  }

  renderTabGeneral() {
    const { deal, teams, mode, user } = this.props;
    const {
      autoGuest,
      title,
      creating,
      saving,
      sourceTemplate,
      parsed,
      key,
      guestSigning,
      description,
      commenting,
      readonly,
      linkCL,
      team,
      type,
      exportAttachments,
    } = this.state;

    const attachmentExportConfig = _.find(ATTACHMENT_EXPORT_OPTIONS, (option) => {
      return option.exportAttachments === exportAttachments;
    });

    // Show warning if user is about to upload new content on an existing template!
    const existingKey = this.existingKey;

    const typeTitle = (type && TEMPLATE_TYPES[type]?.name) || 'Default';

    return (
      <div className="fields tab-general">
        {mode == 'upload' && this.isNew && (
          <FormGroup>
            <ControlLabel>Upload a template</ControlLabel>
            <div className="contents">
              <FileUp
                deal={deal}
                acceptedTypes={ATTACHMENT_TEMPLATE_TYPES}
                onParse={this.onParse}
                mergeType={MERGE_TYPE.OVERWRITE}
              />
            </div>
          </FormGroup>
        )}

        {mode === 'existing' && this.isNew && (
          <FormGroup>
            <ControlLabel>Team</ControlLabel>
            <div className="contents existing-templates">
              <TeamSelector
                id="existing-team"
                teamID={team.teamID}
                clearable={false}
                teams={teams}
                onSelect={this.selectTeam}
                placeholder="Select..."
                user={user}
              />
            </div>
          </FormGroup>
        )}

        {mode == 'existing' && this.isNew && (
          <FormGroup>
            <ControlLabel>Existing template</ControlLabel>
            <div className="contents existing-templates">
              <TemplateSelector
                activeOnly={false}
                id="existing-template"
                team={team}
                //whenever we select a tempalate Only link all sections to parent template in CL if the new template is on the same team
                onSelect={(sourceTemplate) => {
                  this.setState({
                    sourceTemplate,
                    linkCL: sourceTemplate ? this.props.team.teamID === sourceTemplate.team : false,
                  });
                }}
                placeholder="Select..."
                selectedTemplateKey={sourceTemplate?.key}
                noTemplate="Choose a Template"
                pullRight={Dealer.mobile || null}
                disabled={creating || parsed != null || !team}
                size={!this.isNew ? 'small' : null}
              />
            </div>
          </FormGroup>
        )}

        {mode == 'existing' && this.isNew && (
          <FormGroup className="toggle-only">
            <ControlLabel></ControlLabel>
            <div className="contents template-linked-clauses">
              <div>
                <Checkbox
                  id="chk-linking-clauses"
                  checked={linkCL}
                  onChange={() => this.setState({ linkCL: !linkCL })}
                  placement="right"
                >
                  Keep clauses linked to exisiting template
                </Checkbox>
              </div>
            </div>
          </FormGroup>
        )}

        <FormGroup data-cy="template-title-section">
          <ControlLabel>Title</ControlLabel>
          <div className="contents">
            <FormControl
              name="title"
              disabled={creating || saving}
              onChange={this.handleTitleChange}
              onBlur={this.handleTitleBur}
              placeholder={`e.g., Vendor ${Dt}`}
              type="text"
              value={title}
              bsSize={!this.isNew ? 'small' : null}
              data-cy="template-title"
            />
          </div>
        </FormGroup>

        <FormGroup validationState={isKeyValid(this.state.key) ? undefined : 'error'} data-cy="template-key-section">
          <ControlLabel>Template Key</ControlLabel>
          <div className="contents">
            <div className="contents dmp-validator-container">
              <FormControl
                name="templateKey"
                readOnly={!this.isNew && !!existingKey}
                type="text"
                value={key}
                placeholder={`e.g., vendor-${dt}`}
                onChange={this.handleKeyChange}
                bsSize={!this.isNew ? 'small' : null}
                data-cy="template-key"
              />
              {(this.isNew || !existingKey) && isKeyValid(this.state.key) && (
                <Validator
                  validate={this.checkKeyAvailability}
                  value={key}
                  onResult={(res) => this.setState({ keyIsAvailable: res })}
                  validTip="Available"
                  invalidTip="A template with this key already exists on this team"
                />
              )}
            </div>
            {(this.isNew || !existingKey) && <small>Only letters, numbers, hyphen and underscore are allowed.</small>}
          </div>
        </FormGroup>

        {mode !== 'existing' && (
          <FormGroup data-cy="template-type-section">
            <ControlLabel>Type</ControlLabel>
            <div className="contents">
              <Dropdown
                id="dd-template-settings-type"
                title={typeTitle}
                onSelect={(type) => this.setState({ type })}
                bsSize={!this.isNew ? 'small' : null}
                block
              >
                <MenuItem active={!type} eventKey={null}>
                  Default
                </MenuItem>
                {Object.values(TEMPLATE_TYPES).map((templateType) => (
                  <MenuItem key={templateType.key} eventKey={templateType.key} active={type === templateType.key}>
                    {templateType.name}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
          </FormGroup>
        )}

        <FormGroup data-cy="template-description-section">
          <ControlLabel>Description</ControlLabel>
          <div className="contents">
            <FormControl
              name="description"
              disabled={creating || saving}
              onChange={(e) => {
                this.setState({ description: e.target.value });
              }}
              placeholder="e.g., Scoped work order agreement for hiring a vendor"
              value={description}
              bsSize={!this.isNew ? 'small' : null}
              data-cy="template-description"
            />
          </div>
        </FormGroup>

        {!this.isNew && (
          <FormGroup data-cy="template-workflow-section">
            <ControlLabel>Workflow</ControlLabel>
            <div className="contents">
              <WorkflowSelector
                id="dd-template-workflow"
                onSelect={this.updateWorkflow}
                workflowKey={deal.template.workflow}
                team={team}
                block
                size="small"
              />
            </div>
          </FormGroup>
        )}

        {!this.isNew && (
          <FormGroup data-cy="template-attachment-section">
            <ControlLabel>File attachments</ControlLabel>
            <div className="contents">
              <Dropdown
                className="file-attachments"
                size="small"
                title={attachmentExportConfig.title}
                block
                id="file-attachments"
                onSelect={(exportAttachments) => this.updateBehavior('exportAttachments', exportAttachments)}
              >
                {_.map(ATTACHMENT_EXPORT_OPTIONS, (option, idx) => (
                  <MenuItem
                    key={idx}
                    eventKey={option.exportAttachments}
                    active={option.exportAttachments === exportAttachments}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
          </FormGroup>
        )}

        {team.isFeatureActive(TEAM_FEATURES.LENS) && (
          <FormGroup>
            <ControlLabel>Scoring Matrix</ControlLabel>
            <div className="contents">
              <Dropdown
                className="scoring-selector"
                size="small"
                title={this.activeScoringMatrix ? this.activeScoringMatrix.displayName : 'Select Scoring Matrix'}
                block
                id="scoring-selector"
                onSelect={(matrixID) => this.setState({ scoring: matrixID })}
                dataCyToggle="scoring-selector"
              >
                {_.map(team.scoringMatrices, (matrix, idx) => (
                  <MenuItem key={idx} eventKey={matrix.id} active={this.activeScoringMatrix?.id === matrix.id}>
                    {matrix.displayName}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
          </FormGroup>
        )}

        {!this.isNew && (
          <div>
            <hr />

            <FormGroup className="guest-signing toggle-only" data-cy="template-guest-section">
              <ControlLabel>Guest Access</ControlLabel>
              <div className="contents">
                <div>
                  <Checkbox
                    checked={guestSigning}
                    id="chk-guest"
                    onChange={() => this.updateBehavior('guestSigning', !guestSigning)}
                    placement="right"
                  >
                    Invited users can access {dt} as a guest
                  </Checkbox>
                  <small>Users do not need to have an Outlaw account to view, edit and sign a {dt}.</small>
                </div>
                <div>
                  <Checkbox
                    disabled={!guestSigning}
                    checked={autoGuest}
                    id="chk-auto-guest"
                    onChange={() => this.updateBehavior('autoGuest', !autoGuest)}
                    placement="right"
                  >
                    Bypass log in page for guests
                  </Checkbox>
                  <small>Guests can save their {dt} to an Outlaw account during their active browser session.</small>
                </div>
              </div>
            </FormGroup>

            <hr />

            <FormGroup className="toggle-only" data-cy="template-sharing-section">
              <ControlLabel>Sharing</ControlLabel>
              <div className="contents">
                <div>
                  <Checkbox
                    id="chk-sharing"
                    checked={readonly}
                    onChange={() => this.setState({ readonly: !readonly })}
                    placement="right"
                  >
                    Allow sharing by URL (read-only)
                  </Checkbox>
                  <small>{Dt} will be visible if accessed by its unique URL.</small>
                  <small className="text-danger">
                    This setting is not recommended for {dt}s containing sensitive information, use with caution.
                  </small>
                </div>
              </div>
            </FormGroup>

            <hr />

            <FormGroup className="toggle-only" data-cy="template-commenting-section">
              <ControlLabel>Commenting</ControlLabel>
              <div className="contents">
                <div>
                  <Checkbox
                    id="chk-commenting"
                    checked={commenting}
                    onChange={() => this.setState({ commenting: !commenting })}
                    placement="right"
                  >
                    Enable Commenting
                  </Checkbox>
                  <small>Users have the ability to comment on sections.</small>
                  <small>Viewers must request access to comment.</small>
                </div>
              </div>
            </FormGroup>
          </div>
        )}
      </div>
    );
  }

  renderTabUsers() {
    const { deal, user, team } = this.props;
    const { templateDealUsers } = this.state;

    if (!deal) return;

    return (
      <div className="fields tab-users">
        <TeammateSelector
          existingUsers={_.map(templateDealUsers, 'uid')}
          onSelect={this.addTeammate}
          ref="tm"
          team={team}
          teams={[team]}
          menuWidth={143}
          user={user}
        />

        <div className="default-roles">
          <div className="col-headers">
            <TooltipButton
              placement="top"
              tip={`Check to automatically add users to all ${dt}s created from this template`}
              tipID="tip-default-users"
            >
              <ControlLabel>Auto-Include</ControlLabel>
            </TooltipButton>
          </div>
          <div className="du-list" data-cy="du-list">
            {templateDealUsers.map((du, i) => (
              <DealUserBlock key={i} deal={deal} user={user} du={du} template />
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderTabAdvanced() {
    const { deal, user, team } = this.props;
    const { inbound, inboundParty, inboundMulti, key, excludeCL, batch, documentAI } = this.state;
    const csvDownload = this.exportTemplateMetadata();
    const downloadFileName = this.exportTemplateMetadataFileName();

    return (
      <div className="fields tab-advanced">
        <FormGroup data-cy="template-clause-library-section">
          <ControlLabel>Clause Library</ControlLabel>
          <div className="contents">
            <Button
              size="small"
              active={!excludeCL}
              onClick={() => this.setState({ excludeCL: !excludeCL })}
              data-cy="btn-cl-toggle"
            >
              {excludeCL ? 'Disabled' : 'Enabled'}
            </Button>
            <small>
              Including this template in your Clause Library will enable all sections to be automatically available for
              reuse and dynamic updating across other templates.
            </small>
          </div>
        </FormGroup>

        <FormGroup data-cy="template-batch-section">
          <ControlLabel>Batch Operations</ControlLabel>
          <div className="contents">
            <Button
              bsSize="small"
              disabled={!user.can(FEATURES.BATCH)}
              active={batch}
              onClick={() => this.setState({ batch: !batch })}
              data-cy="btn-batch-toggle"
            >
              {batch ? 'Enabled' : 'Disabled'}
            </Button>
            <small>
              Enabling Batch Operations for this template will allow it to be used for legacy migration and automatic
              CSV-based generation. Please contact your Account Manager for more information.
            </small>
          </div>
        </FormGroup>

        <FormGroup data-cy="template-inbound-section">
          <ControlLabel>Inbound {Dt}s</ControlLabel>
          <div className="contents">
            <Button
              bsSize="small"
              disabled={!team.isFeatureActive(TEAM_FEATURES.INBOUND)}
              active={this.state.inbound}
              onClick={() => this.setState({ inbound: !this.state.inbound })}
              data-cy="btn-inbound-toggle"
            >
              {this.state.inbound ? 'Enabled' : 'Disabled'}
            </Button>
            <small>
              Enabling Inbound {Dt}s for this template will allow any Internet user to automatically initiate a {dt}{' '}
              with you from this template by accessing a public URL. Please contact your Account Manager for more
              information.
            </small>
          </div>
        </FormGroup>

        {inbound && (
          <>
            <FormGroup data-cy="template-inbound-url-section">
              <ControlLabel>Inbound URL</ControlLabel>
              <div className="contents">
                <span className="url-thing" data-cy="url-thing">
                  {getBaseUrl()}/in/{team.teamID}/{key}
                </span>
                <small>Accessing this URL will initiate a new {dt} from this template.</small>
              </div>
            </FormGroup>

            <FormGroup data-cy="template-inbound-party-section">
              <ControlLabel>Inbound Party</ControlLabel>
              <div className="contents">
                <PartySelector
                  id="dd-inbound-party"
                  size="small"
                  deal={deal}
                  assigned={inboundParty}
                  onSelect={(inboundParty) => this.setState({ inboundParty })}
                />
                <small>Users who initiate inbound {dt}s will be automatically assigned to this party.</small>
              </div>
            </FormGroup>
            <FormGroup data-cy="template-allow-duplicates-section">
              <ControlLabel>Allow Duplicates</ControlLabel>
              <div className="contents">
                <Button
                  size="small"
                  active={this.state.inboundMulti}
                  onClick={() => this.setState({ inboundMulti: !inboundMulti })}
                  data-cy="btn-allow-duplicates-toggle"
                >
                  {this.state.inboundMulti ? 'Enabled' : 'Disabled'}
                </Button>
                <small>
                  If enabled, revisiting the inbound URL will create a new {dt} each time; if disabled it will access
                  and update an existing {dt} for the current user if one has already been initiated.
                </small>
              </div>
            </FormGroup>
          </>
        )}
        <FormGroup data-cy="template-metadata-section">
          <ControlLabel>Metadata</ControlLabel>
          <div className="contents">
            <a href={csvDownload} download={downloadFileName} style={{ textDecoration: 'none' }}>
              <Button bsSize="small" onClick={this.exportTemplateMetadata} data-cy="btn-export-metadata">
                Export
              </Button>
            </a>
            <small>Export the Template Metadata in CSV format.</small>
          </div>
        </FormGroup>
        {this.props.team.isFeatureActive(TEAM_FEATURES.PDF_EXTRACTION) && (
          <FormGroup data-cy="template-document-ai-section">
            <ControlLabel>Document AI</ControlLabel>
            <div className="contents">
              <Button
                size="small"
                active={this.state.documentAI}
                onClick={() => this.setState({ documentAI: !documentAI })}
                data-cy="btn-document-ai"
              >
                {this.state.documentAI ? 'Enabled' : 'Disabled'}
              </Button>
              <small>When enabled, Outlaw will process all PDFs using Document AI's Form Parser.</small>
            </div>
          </FormGroup>
        )}
      </div>
    );
  }

  renderTabConnections() {
    const { deal } = this.props;
    const { team } = this.state;
    const filevineConnection = _.find(deal.connections, { type: FILEVINE_SERVICE.key });

    return (
      <div className="fields tab-connections">
        <FormGroup>
          <ControlLabel>Connection</ControlLabel>
          <div className="contents" data-cy="connection-contents">
            <FilevineTemplateConnection connection={filevineConnection} dealID={deal.dealID} teamID={team?.teamID} />
          </div>
        </FormGroup>
      </div>
    );
  }

  render() {
    const { show, template } = this.props;
    const { creating, saving, tab } = this.state;

    return (
      <Modal
        dialogClassName="template-info"
        show={show}
        onHide={() => this.close()}
        enforceFocus={false}
        data-cy="template-info"
      >
        <Modal.Header closeButton>
          <span className="headline">{template ? 'Template settings' : 'Create a new Template'}</span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">
            {!this.isNew && (
              <ButtonGroup className="tab-selector" data-cy="draft-settings-tab-selector">
                {TABS.map((t) => (
                  <TooltipButton key={t.key} tipID={`template-tab-${t.key}`} tip={t.tip} placement="bottom">
                    <Button active={tab == t} onClick={() => this.setState({ tab: t })} data-cy={`tab-${t.key}`}>
                      {t.title}
                    </Button>
                  </TooltipButton>
                ))}
              </ButtonGroup>
            )}

            {tab.key === 'general' && this.renderTabGeneral()}
            {tab.key === 'users' && this.renderTabUsers()}
            {tab.key == 'advanced' && this.renderTabAdvanced()}
            {tab.key == 'connections' && this.renderTabConnections()}
          </div>
        </Modal.Body>

        {!tab.hideFooter && (
          <Modal.Footer>
            {this.isNew ? (
              <Button
                dmpStyle="primary"
                disabled={this.isCreationDisabled}
                onClick={this.createTemplate}
                data-cy="btn-create-template"
              >
                Create Template
              </Button>
            ) : (
              <Button
                dmpStyle="primary"
                disabled={this.isUpdateDisabled}
                onClick={this.saveTemplate}
                data-cy="btn-update"
              >
                Update
              </Button>
            )}
            {(creating || saving) && <Loader />}
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}
